import { getImageUrl } from 'pages/Utility/functions'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Container } from 'reactstrap'

const ArticlePreview = () => {
    const [data, setData] = useState(null) 
    useEffect(() => {
        let stData = JSON.parse(localStorage.getItem("articleData"))
        setData(stData)
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1 className="mx-9 text-[#252F40] text-lg font-semibold">
                        Preview article
                    </h1>
                    <div className="container mt-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Title : {data?.title}</h5>
                                
                                {data?.image ?
                                    <img style={{ height: "600px", borderRadius: "15px", width: "100%" }} src={getImageUrl(data?.image)}></img>
                                    : null}
                                <p className="my-2 font-semibold fs-5 fw-medium">Description</p>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                                {data?.meta &&
                                    data.meta.map((item, index) => (
                                        <div key={index}>
                                            <h2 style={{marginBottom:"5px"}} className='fs-5'>{item?.subtitle}</h2>
                                            <div style={{marginBottom:"15px"}}>{item?.description}</div>
                                        </div>
                                    ))}
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="card-text">
                                                Type: {data?.type}
                                            </p>
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="card-text">
                                                Hangar Owner: {data?.hangar_owner ? 'Yes' : 'No'}
                                            </p>
                                            <p className="card-text">
                                                Customer: {data?.customer ? 'Yes' : 'No'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default ArticlePreview
