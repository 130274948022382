import HangarMap from "components/Common/HangarMap"
import { supabase } from "pages/Utility/supabase"
import React from "react"
import { Col, Container, Row } from "reactstrap"
import { TABLES } from "pages/Utility/constants"
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import { useState } from "react"
import { useEffect } from "react"
import DefaultLoading from "common/loading"
import { downloadFile, getImageUrl } from "pages/Utility/functions"
import Amenities from "components/hangar-details/Amenities"

const HangarDetails = props => {
  document.title = "Hangar Details"
  const history = useHistory()
  const router = useRouteMatch()

  const [loading, setLoading] = useState(true)
  const [approval, setApproval] = useState(true)
  const [amenity, setAmenity] = useState([])
  const [rules, setRules] = useState([])
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const [hangarData, setHangarData] = useState({})
  const DYNAMIC_CHECK = hangarData?.is_pricing_sq_ft || hangarData?.is_shared_pricing_model;

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params && params.id) {
      getOrderData(params.id)
    }
  }, [history])
  useEffect(() => {
    if (params && params.id) {
      supabase
        .from(TABLES.HANGAR_AMENITIES)
        .select("*, amenity(*)")
        .eq("hangar_id", params.id)
        .then(({ data = [], error }) => {
          if (data) {
            setAmenity(data.map(i => ({
              id: i.id,
              icon: i?.amenity?.icon,
              name: i?.amenity?.name,
              price_per_day: i.price_per_day
            })))
          }
          if (error) toast.error(error.message)
        })
    }
  }, [hangarData, history])
  useEffect(() => {
    check_coonect_account_approval()
  }, [hangarData?.user_id])
  const check_coonect_account_approval = async () => {
    setLoading(true)
    const { data, error } = await supabase
      .from(TABLES.USER_PROFILE)
      .select("connect_account_approval")
      .eq("id", hangarData.user_id)
      .single()
    if (error) {
      setLoading(false)
    }
    if (data) {
      setLoading(false)
      setApproval(data?.connect_account_approval)
      if (!data?.connect_account_approval) {
        setAlert({
          heading: "Restricted",
          message: "This user does not have connect account.",
          type: "danger",
        })
      }
    } else {
      setLoading(false)
      return false
    }
  }
  const getOrderData = async id => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
        .from(TABLES.HANGARS)
        .select("*,profile(*),hangar_rules(*,rules(*)),property_rules(*)")
        .eq("id", id)
        .single()

      if (error) {
        throw error
      } else if (data) {
        setHangarData(data)
        // console.log("data: ", data)
        // const allowedRules = data?.hangar_rules?.reduce((accumulator, item) => {
        //   if (item.is_allow === true || item.is_allow === false) {
        //     accumulator.push(item.rules)
        //   }
        //   return accumulator
        // }, [])
        setRules(data?.hangar_rules)
      }
      // console.log("data: ", data)
    } catch (error) {
      // console.log("error: ", error.message)
    }
    setLoading(false)
  }
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }
  const downloadHandler = async value => {
    setLoading(true)
    downloadFile(getImageUrl(value))
  }
  const checkInDate = new Date(hangarData?.check_in_time_start)
  const formattedDate = checkInDate.toLocaleString("en-US", {
    // year: "numeric",
    // month: "long",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZoneName: "short",
  })
  const checkOutDate = new Date(hangarData?.check_out_time)
  const CheckInDateEnd = checkOutDate.toLocaleString("en-US", {
    // year: "numeric",
    // month: "long",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZoneName: "short",
  })
  const quiet_hour_in = new Date(hangarData?.quiet_hours_in)
  const quiet_hours_in_start = quiet_hour_in.toLocaleString("en-US", {
    // year: "numeric",
    // month: "long",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZoneName: "short",
  })
  const quiet_hour_end = new Date(hangarData?.quiet_hours_out)
  const quiet_hours_in_end = quiet_hour_end.toLocaleString("en-US", {
    // year: "numeric",
    // month: "long",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZoneName: "short",
  })

  const openPreview = () => {
    window.open(`${window.location.origin.includes('localhost') || window.location.origin.includes('test-admin.hangardirect') ? 'https://hangardirect-github-io.vercel.app' : 'https://www.hangardirect.com'}/${hangarData?.slug}/hangar/${hangarData.id}`, "blank")
  }

  const InsuranceButton = ({ link, text }) => (
    !text ? <span className="col-lg-5 d-flex align-items-center pl-2 gap-2">{"Not Provided"}</span>
      :
      <div
        onClick={() =>
          downloadHandler(link, "owner.pdf")
        }
        className="col-lg-5 d-flex align-items-center cursor-pointer pl-2 gap-2 "
      >
        <img src="/images/pdf.png" alt="" />
        <span className="text-primary">{text}</span>
        <img src="/images/download.png" alt="" />
      </div>
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Heading text="Request Details" customClass="px-0" />
        {loading ? (
          <DefaultLoading />
        ) : (
          <Row>
            <Col xl="8">
              <div className="bg-white px-2 rounded">
                <div className="container pt-3">
                  <Heading text="Hangar Info" customClass="m-0 px-0 pt-0" />
                  <div className="border-top border-bottom py-2">
                    <div className="row px-2">
                      {/* ist row */}
                      <div
                        style={{ fontWeight: "600" }}
                        className={`col-lg-2 
                     ${hangarData?.title && ""}
                    `}
                      >
                        Title:
                      </div>
                      <div className="col-lg-5">{hangarData?.title}</div>
                      <div
                        className={`col-lg-2  ${hangarData?.price_per_night && ""
                          }`}
                        style={{ fontWeight: "600" }}
                      >
                        {hangarData?.price_per_sq_ft_night ? "Price per sq_ft_night" : "Per Night Price"}
                      </div>

                      <div className="col-sm">
                        ${hangarData?.price_per_night
                          ? (+hangarData.price_per_night).toFixed(2)
                          : (+hangarData.price_per_sq_ft_night).toFixed(2)}
                      </div>

                    </div>
                  </div>
                </div>
                {/* 2nd row */}
                <div className="container py-3 ">
                  <div className=" border-bottom pb-2">
                    <div className="row px-2">

                      <div
                        className={`col-lg-2 `}
                        style={{ fontWeight: "600" }}
                      >
                        {hangarData?.price_per_sq_ft_month ? "Price per sq_ft_month:" : "Per Month Price:"}
                      </div>
                      <div className="col-sm">
                        ${hangarData?.price_per_month
                          ? (+hangarData.price_per_month).toFixed(2)
                          : (+hangarData.price_per_sq_ft_month).toFixed(2)}
                      </div>
                      <div
                        style={{ fontWeight: "600" }}
                        className={`col-lg-2`}
                      >
                        Length:
                      </div>
                      <div className="col-lg-3">
                        {hangarData?.length_feet +
                          "'" +
                          hangarData?.length_inch +
                          "''"}
                      </div>

                    </div>
                  </div>
                </div>

                {/* 3rd row */}
                <div className="container pb-3 ">
                  <div className=" border-bottom pb-2">
                    <div className="row px-2">
                      <div
                        className={`col-lg-2 `}
                        style={{ fontWeight: "600" }}
                      >
                        Width:
                      </div>
                      <div className="col-sm">
                        {hangarData?.width_feet +
                          "'" +
                          hangarData?.width_inch +
                          "''"}
                      </div>
                      <div
                        className={`col-lg-2`}
                        style={{ fontWeight: "600" }}
                      >
                        Door Height:
                      </div>
                      <div className="col-lg-3">
                        {hangarData?.door_height_feet +
                          "'" +
                          hangarData?.door_height_inch +
                          "''"}
                      </div>

                    </div>
                  </div>
                </div>
                {/* 4th row */}
                <div className="container pb-3 ">
                  <div className=" border-bottom pb-2">
                    <div className="row px-2">
                      <div
                        className={`col-lg-2 `}
                        style={{ fontWeight: "600" }}
                      >
                        Door Width:
                      </div>
                      <div className="col-sm">
                        {hangarData?.door_width_feet +
                          "'" +
                          hangarData?.door_width_inch +
                          "''"}
                      </div>
                      
                        <>
                          <div
                            className={`col-lg-2`}
                            style={{ fontWeight: "600" }}
                          >
                            Pricing Model:
                          </div>
                          <div className="col-lg-3">
                            {DYNAMIC_CHECK ? "Shared" : "Entire"}
                          </div>
                        </>

                      

                    </div>
                  </div>
                </div>

                {/* 5th row */}
                <div className="container pb-3 ">
                  <div className=" border-bottom pb-2">
                    <div className="row px-2">
                      <div
                        className={`col-lg-2`}
                        style={{ fontWeight: "600" }}
                      >
                        Description:
                      </div>
                      <div
                        className="col-lg-10"
                        dangerouslySetInnerHTML={{
                          __html: hangarData.description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                {hangarData?.instructions?.instructions && (
                  <div className="container pb-3 ">
                    <div className=" border-bottom pb-2">
                      <div className="row px-2">
                        <div
                          className={`col-lg-3`}
                          style={{ fontWeight: "600" }}
                        >
                          Special Instructions:
                        </div>
                        <div className="col-lg-9">
                          {hangarData?.instructions?.instructions}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {hangarData?.instructions?.self_check_in && (
                  <div className="container pb-3 ">
                    <div className=" border-bottom pb-2">
                      <div className="row px-2">
                        <div
                          className={`col-lg-3`}
                          style={{ fontWeight: "600" }}
                        >
                          Instructions (Self Check-in):
                        </div>
                        <div className="col-lg-9">
                          {hangarData?.instructions?.self_check_in}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {hangarData?.instructions?.keypad && (
                  <div className="container pb-3 ">
                    <div className=" border-bottom pb-2">
                      <div className="row px-2">
                        <div
                          className={`col-lg-3`}
                          style={{ fontWeight: "600" }}
                        >
                          Instructions (Keypad):
                        </div>
                        <div className="col-lg-9">
                          {hangarData?.instructions?.keypad}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {hangarData?.instructions?.lock_box && (
                  <div className="container pb-3 ">
                    <div className=" border-bottom pb-2">
                      <div className="row px-2">
                        <div
                          className={`col-lg-3`}
                          style={{ fontWeight: "600" }}
                        >
                          Instructions (Lock box):
                        </div>
                        <div className="col-lg-9">
                          {hangarData?.instructions?.lock_box}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {hangarData?.instructions?.staff_on_site && (
                  <div className="container pb-3 ">
                    <div className=" border-bottom pb-2">
                      <div className="row px-2">
                        <div
                          className={`col-lg-3`}
                          style={{ fontWeight: "600" }}
                        >
                          Instructions (Staff on-site):
                        </div>
                        <div className="col-lg-9">
                          {hangarData?.instructions?.staff_on_site}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="container pb-3 ">
                  <div
                    className={`${!hangarData?.owner_insurance === null &&
                      !hangarData?.general_insurance === null &&
                      "border-bottom"
                      }  pb-2 ${hangarData?.owner_insurance && ""}`}
                  >
                    <div className="row  px-2 d-flex ">
                      {!!hangarData.owner_insurance && (
                        <>
                          <div
                            style={{ fontWeight: "600" }}
                            className="col-lg-3"
                          >
                            Owner’s Insurance:
                          </div>
                          <InsuranceButton link={hangarData?.owner_insurance} text={"owner.pdf"} />

                        </>
                      )}
                      {!!hangarData?.general_insurance ? (
                        <>
                          <div
                            className={`col-lg-2 ${hangarData?.general_insurance && ""
                              }`}
                            style={{ fontWeight: "600" }}
                          >
                            General Insurance:
                          </div>
                          <InsuranceButton link={hangarData?.general_insurance} text={"general.pdf"} />

                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {amenity?.length > 0 && (
                <div className="bg-white px-2 my-4 pb-3 rounded">
                  <Heading
                    text="Amenities"
                    danger={amenity}
                    customClass={`m-0 px-1`}
                  />

                  <Amenities amenities={amenity} />

                </div>
              )}
              {/* hangar rules */}
              <div className="bg-white px-2 my-4 pb-3 rounded">
                <Heading text="Hangar Rules" customClass="m-0 px-1" />

                <div className="container py-3 ">
                  <div className=" border-bottom pb-2">
                    {hangarData?.always_on && <div className="row px-2">
                      <div
                        className={`col-lg-4 ${hangarData?.always_on && ""
                          }`}
                        style={{ fontWeight: "600" }}
                      >
                        24/7 check-in-available
                      </div>
                    </div>}
                    {hangarData?.check_in_time_start || hangarData?.check_out_time ? <div className="row px-2">
                      <div
                        className={`col-lg-2 ${hangarData?.check_in_time_start && ""
                          }`}
                        style={{ fontWeight: "600" }}
                      >
                        Check-in
                      </div>
                      <div className="col-lg-5">{formattedDate}</div>
                      <div
                        className={`col-lg-2  ${hangarData?.check_out_time && ""
                          }`}
                        style={{ fontWeight: "600" }}
                      >
                        Check Out:
                      </div>
                      <div className="col-sm">{CheckInDateEnd}</div>
                    </div> : ""}
                  </div>
                </div>
                {quiet_hours_in_start !== "Invalid Date" && (
                  <div className="container py-3 ">
                    <Heading
                      text="Quiet Hours"
                      danger={
                        hangarData?.quiet_hours_in || hangarData?.quiet_hours_out
                      }
                      customClass="mb-2 px-1"
                    />
                    <div className=" border-bottom pb-2">
                      <div className="row px-2">
                        <div className="col-lg-2" style={{ fontWeight: "600" }}>
                          From:
                        </div>
                        <div className="col-lg-5">
                          {quiet_hours_in_start === "Invalid Date"
                            ? "Not Found"
                            : quiet_hours_in_start}
                        </div>
                        <div className="col-lg-2" style={{ fontWeight: "600" }}>
                          To:
                        </div>
                        <div className="col-sm">
                          {quiet_hours_in_end === "Invalid Date"
                            ? "Not Found"
                            : quiet_hours_in_end}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* allowedRules */}
                {hangarData?.property_rules?.length && (
                  <div className="container py-3 ">
                    <Heading
                      text="Property Rules"
                      danger={hangarData?.property_rules}
                      customClass="mb-2 px-1"
                    />
                    <div className="text-black container inline-block">
                      <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
                        {hangarData?.property_rules?.length > 0 ? (
                          hangarData?.property_rules[0].rules?.map(
                            (item, index) => {
                              return (
                                <div
                                  className="d-flex align-items-center gap-2"
                                  key={index}
                                >
                                  <div
                                    style={{
                                      height: "5px",
                                      width: "5px",
                                      backgroundColor: "#D7D7D7",
                                      borderRadius: "100%",
                                    }}
                                  />
                                  <p
                                    style={{
                                      color: "#495057",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                    className="text-sm font-medium m-0  leading-"
                                  >
                                    {item}
                                  </p>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <div className="text-warning">
                            No property rules found!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="bg-white px-2 my-4 pb-3 rounded">
              <Heading text="Hangar Rules" customClass="m-0 px-1" />
              <div className="container py-3 ">
                <div className=" border-bottom pb-2">
                  <div className="row px-2">
                    <div className="col-lg-2" style={{ fontWeight: "600" }}>
                      Quiet hours in
                    </div>
                    <div className="col-lg-5">{quiet_hours_in_start}</div>
                    <div className="col-lg-2" style={{ fontWeight: "600" }}>
                      Quiet hours out:
                    </div>
                    <div className="col-sm">{quiet_hours_in_end}</div>
                  </div>
                </div>
              </div>
            </div> */}

              <div className="bg-white px-2 mt-4 rounded pb-2">
                <div className="container">
                  <Heading
                    text="Hangar Images"
                    danger={hangarData?.images}
                    customClass="m-0 px-0"
                  />
                  <div className="d-flex flex-wrap gap-5">
                    {hangarData?.images?.map((item, index) => (
                      <div className="d-flex pt-4" key={index}>
                        <img
                          height={"170px"}
                          className="rounded"
                          width={"170px"}
                          src={
                            getImageUrl(item)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="4" className="p-0">
              {hangarData?.id && (
                <div className="container px-2">
                  <div className="row mx-0 pb-3">
                    <button
                      onClick={openPreview}
                      type="button"
                      className="btn btn-primary"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              )}
              <div
                className={` ${hangarData?.status === "1" ? "mt-3 mx-2" : "mx-2"
                  } pb-2 bg-white rounded`}
              >
                <Heading text="Hangar Owner Detail" customClass="m-0 " />

                <div className="px-3">
                  <div className="d-flex align-items-center gap-2">
                    <img src="/images/profile.png" alt="" />
                    <span style={{ fontSize: "13px" }}>
                      {hangarData?.profile?.meta?.first_name +
                        " " +
                        hangarData?.profile?.meta?.last_name}
                    </span>
                  </div>
                  <div className=" d-flex align-items-center gap-2 mt-1">
                    <img src="/images/msg.png" alt="" />
                    <span style={{ fontSize: "13px" }}>
                      {hangarData?.profile?.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="m-2 mt-3 pb-2 bg-white rounded">
                <Heading text="Hangar Location" customClass="m-0 " />
                <div className="px-3">
                  <div className="d-flex align-items-start gap-2">
                    <img src="/images/locate.png" alt="" />
                    <span style={{ fontSize: "13px" }}>
                      {hangarData?.address}
                    </span>
                  </div>
                  {window?.google && (
                    <HangarMap
                      latitude={hangarData?.latitude}
                      longitude={hangarData?.longitude}
                      customPadding={"px-0"}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}
export default HangarDetails

