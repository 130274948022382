import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import TableContainer from "../../components/Common/TableContainer"
import { Box } from "@material-ui/core"

const Requests = ({
  columns,
  planeList,
  currentItems, setCurrentItems,
  setLoading,
  setAlert,
  header,
  numSize
}) => {
  return (
    <React.Fragment>
      <div className="px-3">
        {planeList.length ? (
          <TableContainer
            numSize={numSize}
            isPage={false}
            isShowPagination={false}
            columns={columns}
            data={planeList}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            setLoading={setLoading}
            setAlert={setAlert}
            header={header}
            currentItems={currentItems}
            setCurrentItems={setCurrentItems}
          />
        ) : (
          <Box
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
            sx={{
              p: 5,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              color: "red",
            }}
          >
            No Airports Found.
          </Box>
        )}
      </div>

    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)

