import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import {
  OrderId,
  Type,
  DateCol,
  Status,
  AccountNo,
  AccountHolderName,
} from "./LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import profile from "store/auth/profile/reducer"

const PayoutMethods = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowsUserIDs, setSelectedRowsUserIDs] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Connect Account | HangarDirect"

  const handleModelData = row => {
    history.push("/payout-methods-details/" + row.id)
  }
  const handleCheckChange = adiProp => e => {
    if (e.target.checked) {
      setSelectedRows(s => [...s, { id: adiProp.id, userId: adiProp.user_id }])
      setSelectedRowsUserIDs(s => [...s, adiProp.user_id])
    } else {
      setSelectedRows(s => s.filter(row => row.id !== adiProp.id))
      setSelectedRowsUserIDs(s => s.filter(row => row !== adiProp.user_id))
    }
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     // const id = cellProps.row.original.id
      //     // const user_id = cellProps.row.original.user_id
      //     let ids = {
      //       id: cellProps.row.original.id,
      //       user_id: cellProps.row.original.user_id,
      //     }
      //     return (
      //       <input
      //         onChange={handleCheckChange(ids)}
      //         type="checkbox"
      //         className="form-check-input"
      //       />
      //     )
      //   },
      // },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "Type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Card | Account number",
        accessor: "Business type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <AccountNo {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Created date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateCol {...cellProps} />
        },
      },
      {
        Header: "View details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                handleModelData(cellProps.row.original)
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const getPaymentsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.PAYMENT_METHODS)
        .select("*,profile(connect_account_approval)")
        .eq('profile.connect_account_approval', true)
        .eq('status', STATUS.PAYMENT_METHOD_IN_REVIEW)
        .order("id", { ascending: true })
      let newData = data?.filter((row) => row?.profile !== null)
      if (error) {
        throw error
      }
      setHangars(newData);
      setCurrentItems(newData);
      setLoading(false)
    } catch (error) {
      console.log("error: ", error.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    getPaymentsData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Requests
                  columns={columns}
                  hangars={hangars}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  selection={selectedRows}
                  setLoading={setLoading}
                  getHangarsData={getPaymentsData}
                  selectedRowsUserIDs={selectedRowsUserIDs}
                  setAlert={setAlert}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PayoutMethods
