import React from 'react'

const Alerts = ({ heading, message, type, clear }) => {
    return (
        <>
            {type === "danger" && <div className="alert alert-danger d-flex align-items-center" role="alert">
                <strong className="font-weight-bold pe-2"  >{`${heading}!`}</strong>
                <span className="d-block small ml-2">{message}</span>
                {
                    !!clear &&
                    <button onClick={clear} type="button" className="close ml-auto" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                }
            </div>}
            {type === "warn" && <div className="alert alert-warning border-left border-warning p-4" role="alert">
                <p className="font-weight-bold">{heading}</p>
                <p>{message}</p>
            </div>
            }
        </>
    )
}

export default Alerts