import React, { useEffect } from "react"
const DEFAULT_MAP_OPTIONS = {
  zoom: 12,
  scrollwheel: false,
  navigationControl: false,
  mapTypeControl: false,
  scaleControl: false,
  fullscreenControl: true,
  streetViewControl: false,

  styles: [
    {
      featureType: "water",
      elementType: "geometry",

      stylers: [
        {
          color: "#91E0FF",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#F0F2F4",
        },
      ],
    },

    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 20,
        },
      ],
    },
  ],
}

const HangarMap = ({ latitude, longitude }) => {
  useEffect(() => {
    if(!window?.google) {
      return true
    }
    const { maps } = window?.google
    var latLng = new maps.LatLng(0, 0)
    var mapOptions = {
      ...DEFAULT_MAP_OPTIONS,
      center: latLng,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_TOP,
      },
      streetViewControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
      },
    }
    const map = new maps.Map(document.getElementById("hanger-map"), mapOptions)
    const position = new maps.LatLng(latitude, longitude)

    map.setCenter(position)
    const image = "/images/logo_small.png"
    const marker = new maps.Marker({
      map: map,
      position,
      icon: image,
    })
    const circle = new maps.Circle({
      strokeColor: "rgb(37, 150, 190)",
      strokeOpacity: 0.8,
      strokeWeight: 0.5,
      fillColor: "rgb(37, 150, 190)",
      fillOpacity: 0.5,
      center: position,
      radius: 400,
      map: map,
    })
    // map.addListener("mouseover", () => {
    //   infowindow.close()
    // })
    // map.addListener("mouseout", () => {
    //   infowindow.open({
    //     anchor: marker,
    //     circle,
    //     map,
    //   })
    // })
    // infowindow.open({
    //   anchor: marker,
    //   map,
    // })
  }, [latitude, longitude])
  // useEffect(() => {
  //   // Create a new script element
  //   const script = document.createElement("script")
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCTeuajYJW22KJe7Ae-tOq_10n0D52CP_0&libraries=places&callback=initMap`
  //   script.async = true

  //   // Append the script to the document's body
  //   document.body.appendChild(script)

  //   // Clean up function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script)
  //   }
  // }, [history])
  return (
    <div className={`px-0 md:px-0`}>
      <div
        className="rounde my-2"
        id="hanger-map"
        style={{
          height: "230px",
          borderRadius: "20px",
        }}
      />
    </div>
  )
}

export default HangarMap
