import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"

const Requests = ({
  columns,
  orders,
  modelPopData,
  closeModal,
  selection,
  setLoading,
  getOrdersData,
  loading,
  setAlert,
  rowClick,
  currentItems, setCurrentItems,
  onRowClick,
}) => {
  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <div className="mb-4 h4 card-title">RESERVATION CANCEL REQUEST</div>
      <Card>
        <CardBody>
          <div className="mb-4">
            <div
              className="border"
              style={{
                outline: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                color: "#74788D",
                padding: "5px",
                width: "80px",
                borderRadius: "100px",
              }}
            >
              <div>
                <img
                  style={{ height: "9px" }}
                  src="/images/filter.png"
                  alt=""
                />
              </div>
              <div>Filter</div>
            </div>
          </div>
          {!loading ? (
            <TableContainer
              columns={columns}
              data={orders}
              isSelectDropdown={false}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
              selection={selection}
              setLoading={setLoading}
              getOrdersData={getOrdersData}
              setAlert={setAlert}
              onRowClick={onRowClick}
              rowClick={rowClick}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
              sx={{
                p: 5,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "red",
              }}
            >
              Reservation cancel request found.
            </Box>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)

