import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import Requests from "./requests"

import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { TABLES } from "pages/Utility/constants"
import Swal from "sweetalert2"

const DEFAULT_ALERT_VALUES = {
  heading: "",
  message: "",
  type: "",
}

const PlaneList = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [planeList, setPlaneList] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowsUserIDs, setSelectedRowsUserIDs] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Plane Requests | HangarDirect"


  useEffect(() => {
    getPlanesList()
  }, [])

  const handleCheckChange = adiProp => e => {
    if (e.target.checked) {
      setSelectedRows(s => [...s, { id: adiProp.id, userId: adiProp.user_id }])
      setSelectedRowsUserIDs(s => [...s, adiProp.user_id])
    } else {
      setSelectedRows(s => s.filter(row => row.id !== adiProp.id))
      setSelectedRowsUserIDs(s => s.filter(row => row !== adiProp.user_id))
    }
  }

  const columns = useMemo(
    () => [

      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Wingspan",
        accessor: "wingspan",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Length",
        accessor: "length",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Height",
        accessor: "height",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Gravity fueling",
        accessor: "gravity_fueling",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return row?.gravity_fueling ? "Yes" : "No"
        },
      },
      {
        Header: "Pressure fueling",
        accessor: "pressure_fueling",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return row?.pressure_fueling ? "Yes" : "No"
        },
      },
      {
        Header: "Weight empty",
        accessor: "weight_empty",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Weight take off",
        accessor: "weight_take_off",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Weight landing",
        accessor: "weight_landing",
        disableFilters: true,
        filterable: false,
      },


      {
        Header: "Tow disconnect",
        accessor: "tow_disconnect",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return row?.tow_disconnect ? "Yes" : "No"
        },
      },
      {
        Header: "Tow turn limits",
        accessor: "tow_turn_limits",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return row?.tow_turn_limits ? "Yes" : "No"
        },
      },
      {
        Header: "General aviation",
        accessor: "general_aviation",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return row?.general_aviation ? "Yes" : "No"
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return <div className="d-flex gap-1 cursor-pointer">
            <img onClick={() => history.push("/add-new-plane?id=" + row.id)} style={{ height: "30px" }} src="/images/edit.png" alt="" />
            <img onClick={() => deletePopup(row.id)} style={{ height: "30px" }} src="/images/delete.png" alt="" />
          </div>
        },
      },

    ],
    []
  )

  const deletePopup = async (id) => {

    Swal.fire({
      title: "Do you want to delete this aircraft?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        deleteRow(id)
      }
      Swal.close()
    });
  }

  const deleteRow = async (id) => {

    const { data, error } = await supabase.from(TABLES.PLANE_LIST).delete().eq("id", id)
    if (error) console.log(error.message)
    else {
      // setPlaneList(planeList.filter(i => i.id !== id))
      getPlanesList()
    }
  }

  const getPlanesList = async () => {
    setLoading(true)
    try {

      let { data: plane_list, error } = await supabase
        .from(TABLES.PLANE_LIST)
        .select('*')
        .order("id", { ascending: true })

      if (error) {
        throw error
      }
      setPlaneList(plane_list)
      setCurrentItems(plane_list);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (

    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          {loading ? <DefaultLoading /> :
            <Row>
              <Col lg="12">
                <Requests
                  columns={columns}
                  planeList={planeList}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  selection={selectedRows}
                  setLoading={setLoading}
                  getPlanesList={getPlanesList}
                  selectedRowsUserIDs={selectedRowsUserIDs}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                  setAlert={setAlert}
                  header={<div className="d-flex justify-content-end">
                    <Button
                      type="button"
                      color="info"
                      className=" rounded"
                      onClick={() => {
                        // handleModelData(cellProps.row.original)
                        history.push("/add-new-plane")
                      }}
                    >
                      + Add New Aircraft
                    </Button>
                  </div>}
                  className="!mb-0"
                />
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PlaneList
