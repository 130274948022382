import { STATUS } from "pages/Utility/constants"
import React, { useState } from "react"


const HorizontalMenu = ({ menu = [], active, onClick, getHangarsData }) => {

    return (
        <ul className="nav nav-pills gap-2 justify-content-end">
            <li
            >
                <div className={`nav-link `} >
                    <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: 'pointer' }}
                        onClick={getHangarsData}
                    >
                        <path
                            d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 5.26904 1.54367 3.28183 3.1694 2M7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 9.73096 13.4563 11.7182 11.8306 13M11.5 10V13.5H15M0 1.5H3.5V5"
                            stroke="#000000"
                        />
                    </svg>
                </div>
            </li>
            {
                menu.map((item) => {

                    return (
                        <li
                            key={item.value}
                            className="nav-item cursor-pointer ml-auto"
                            onClick={() => onClick(item.value)}
                        >
                            <div
                                className={`nav-link ${active === item.value ? "active" : "bg-light text-black"
                                    } `}
                            >
                                {item.label}
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )

}

export default HorizontalMenu