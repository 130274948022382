import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

const RequestModelInvoice = ({ isOpen, toggle, handleRefund, reasonTxt, setReasonTxt }) => {

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Refund</ModalHeader>

      <div className="modal-content">
        <ModalBody>
          <label style={{ margin: "10px 0px" }} htmlFor="reason_box">Reason why are you refunding <span>{`(Optional)`}</span></label>
          <textarea value={reasonTxt} onChange={(e) => setReasonTxt(e.target.value)} id="reason_box" placeholder="Explain your reason here" rows={3} style={{ width: '100%', padding: '10px' }} type="text" />
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={(e) => handleRefund(e)}>
            Refund
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

RequestModelInvoice.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RequestModelInvoice
