import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import {
  OrderId,
  Name,
  DateCol,
  City,
  Status,
  PaymentMethod,
  Action,
  BusinessType,
  Stripe_Status,
} from "./LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { DATE_FORMATS, STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"

const ConnectAccountRequest = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowsUserIDs, setSelectedRowsUserIDs] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Connect Account | HangarDirect"

  const handleModelData = row => {
    history.push("/connect-account-details/" + row.id)
  }
  const handleCheckChange = adiProp => e => {
    if (e.target.checked) {
      setSelectedRows(s => [...s, { id: adiProp.id, userId: adiProp.user_id }])
      setSelectedRowsUserIDs(s => [...s, adiProp.user_id])
    } else {
      setSelectedRows(s => s.filter(row => row.id !== adiProp.id))
      setSelectedRowsUserIDs(s => s.filter(row => row !== adiProp.user_id))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          // const id = cellProps.row.original.id
          // const user_id = cellProps.row.original.user_id
          let ids = {
            id: cellProps.row.original.id,
            user_id: cellProps.row.original.user_id,
          }
          return (
            <input
              onChange={handleCheckChange(ids)}
              type="checkbox"
              className="form-check-input"
            />
          )
        },
      },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "Name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Business type",
        accessor: "Business type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BusinessType {...cellProps} />
        },
      },

      {
        Header: "City",
        accessor: "City",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <City {...cellProps} />
        },
      },
      {
        Header: "Admin Status",
        accessor: "Admin Status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Stripe Status",
        accessor: "Stripe Status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Stripe_Status {...cellProps} />
        },
      },
      // {
      //   Header: "Payment method",
      //   accessor: "PaymentMethod",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <PaymentMethod {...cellProps} />
      //   },
      // },
      // {
      //   Header: "Created date",
      //   accessor: "orderdate",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <DateCol {...cellProps} />
      //   },
      // },
      {
        Header: "Created Date",
        filterable: true,
        disableFilters: true,
        accessor: "created_at",

        Cell: cellProps => {
          return moment(cellProps.row.original.created_at).format(DATE_FORMATS.CREATED_AT)
        },
      },
      {
        Header: "View details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                handleModelData(cellProps.row.original)
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const getHangarsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.CONNECT_REQUESTS)
        .select("*,profile(*)")
        .eq("status", STATUS.CONNECT_ACCOUNT_APPROVED)
        .eq("admin_approval", STATUS.CONNECT_ADMIN_APPROVAL_IN_REVIEW)
        .order("id", { ascending: true })

      console.log('data: ', data);

      if (error) {
        throw error
      }
      setHangars(data);
      setCurrentItems(data);
      setLoading(false)
    } catch (error) {
      console.log("error: ", error.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    getHangarsData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Requests
                  columns={columns}
                  hangars={hangars}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  selection={selectedRows}
                  setLoading={setLoading}
                  getHangarsData={getHangarsData}
                  selectedRowsUserIDs={selectedRowsUserIDs}
                  setAlert={setAlert}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConnectAccountRequest
