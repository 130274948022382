import React from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const MonthlyEarning = ({ income = [] }) => {
  function getPercent() {
    if (income.length < 2) return 0
    const last = income.length - 1
    const v2 = income[last].Amount
    const v1 = income[last - 1].Amount

    const total = ((v2 - v1) / v2) * 100

    return total.toFixed(2)
  }
  const percent = getPercent()
  const positive = percent > 0
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mt-4">Monthly Earning</CardTitle>
          <Row>
            <Col sm="6" className="my-4">
              <p className="">This month</p>
              <h3>
                {income.length
                  ? "$" +
                    (income[income.length - 1]?.Amount?.toFixed?.(2) >= 0
                      ? income[income.length - 1]?.Amount?.toFixed?.(2)
                      : 0.0)
                  : "$0"}
              </h3>
              <p className="">
                <span
                  className={`text-${positive ? "success" : "danger"} me-2`}
                >
                  {` ${getPercent()}%`}
                  <i
                    className={`mdi mdi-arrow-${positive ? "up" : "down"}`}
                  ></i>
                </span>{" "}
                From previous period
              </p>
              <div className="mt-4"></div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
