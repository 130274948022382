import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { editProfile, resetProfileFlag } from "../../store/actions";
import { getImageUrl } from "pages/Utility/functions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// Copier Component
const Copier = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const copyHandler = () => {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setCopied(true);
    toast.success("Email copied to clipboard!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <span style={{ marginLeft: '5px', cursor: "pointer" }}>
      {copied ? (
        <i className="fa fa-check" aria-hidden="true" />
      ) : (
        <i className="fa fa-copy" onClick={copyHandler} />
      )}
    </span>
  );
};

Copier.propTypes = {
  textToCopy: PropTypes.string.isRequired,
};

const UserProfile = () => {
  // Meta title
  document.title = "Profile - HangarDirect";

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const userMetadata = obj.user_metadata || {};

      setImageUrl(userMetadata.img_url || "");
      setName(`${userMetadata.first_name || ""} ${userMetadata.last_name || ""}`);
      setEmail(obj.email || "");

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Hangar-Direct" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              {error && <Alert color="danger">{error}</Alert>}
              {success && <Alert color="success">{success}</Alert>}
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={getImageUrl(imageUrl)}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="mx-2">
                        <h5>{name}</h5>
                        <p className="mb-1">
                          {email}
                          <Copier textToCopy={email} />
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
