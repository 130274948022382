import React from "react"
import PropTypes from "prop-types"
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { getImageUrl } from "pages/Utility/functions"

const RequestModel = ({
  isOpen,
  toggle,
  modelData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Invited by</ModalHeader>
      <div className="modal-content">
        <ModalBody>
          <div className="d-flex align-items-center gap-3 p-0.5">
            <img
              style={{ border: "1px", borderRadius: "100%" }}
              src={
                modelData ?
                  getImageUrl(modelData?.meta?.img_url)
                  : ""
              }
              alt=""
              className="avatar-md"
            />
            <td>
              <div>
                <h5 className="font-size-14 mb-0">
                  {modelData?.meta?.first_name +
                    " " +
                    modelData?.meta?.last_name}
                </h5>
              </div>
            </td>
          </div>
          <div className="d-flex align-content-center justify-content-between pt-3 px-1">
            <h5 >Email:</h5>
            <div>{modelData?.email}</div>
          </div>
          {/* <div className="d-flex align-content-center justify-content-between pt-2 px-1">
            <h5 className="">Referred By:</h5>
            <div>{modelData?.who_invited}</div>
          </div> */}
          {/* <div className="d-flex align-content-center justify-content-between pt-2 px-1">
            <h5 >Referral Code:</h5>
            <div>{modelData?.referral_code}</div>
          </div> */}
        </ModalBody>

      </div>
    </Modal>
  )
}

RequestModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RequestModel
