import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
//redux
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"
import { DATE_FORMATS, TABLES } from "pages/Utility/constants"
import { copyToClipboard } from "pages/Utility/helper"
import { AVATAR } from "common/svg"


const CopyField = ({ text, style }) => {
  return (
    <div className="max-w-[300px]" style={style} >
      {text}
      <span style={{ marginLeft: '5px', cursor: "pointer" }}  >
        <span onClick={() => copyToClipboard(text)} >
          <i className="fa fa-regular xs fa-copy" />
        </span>
      </span>
    </div>
  )
}

const UnverifiedUsers = props => {


  document.title = "Unverified Users | HangarDirect"
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async () => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase.from(TABLES.UNVERIFIED_USERS).select("*").order("created_at", { ascending: false })
      if (error) {
        throw error
      }
      let newData = data.map(i => ({ ...i, meta: i.raw_user_meta_data }))
      setUsersData(newData);
      setCurrentItems(newData);
    } catch (error) {
      console.log("error: ", error.message)
    }
    setLoading(false)
  }

  const columns = [
    {
      Header: "#",
      Cell: () => <input type="checkbox" className="form-check-input" />,
    },
    {
      Header: "ID",
      disableFilters: false,
      accessor: row => <CopyField text={row.id} />,
    },

    {
      Header: "Name",
      // accessor: "meta",
      disableFilters: true,
      filterable: true,
      accessor: ({ meta }) => {
        return (
          <>
            {/* {!meta?.img_url ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {meta?.first_name?.charAt(0)}
                </span>
              </div>
            ) : ( */}
            <div>
              {meta?.img_url ? <img
                className="rounded-circle avatar-xs"
                src={getImageUrl(meta?.img_url)}
                alt=""
              /> : <AVATAR width={32} height={32} custom={"rounded-circle avatar-xs"} />}
              <span
                style={{ marginLeft: "10px" }}
              >{`${meta?.first_name} ${meta?.last_name}`}</span>
            </div>
            {/* )} */}
          </>
        )
      },
    },
    {
      Header: "Email",
      accessor: row => <CopyField text={row.email} />,
      filterable: false,
      disableFilters: true,

    },
    {
      Header: "Phone Number",
      accessor: "phone",
      filterable: false,
      disableFilters: true,

    },

    {
      Header: "Join Date",
      filterable: true,
      disableFilters: true,
      accessor: "created_at",

      Cell: cellProps => {
        return moment(cellProps.row.original.created_at).format(
          DATE_FORMATS.CREATED_AT
        )
      },
    },

  ]

  return (
    <div className="page-content">
      <Container fluid>
        {loading ? (
          <DefaultLoading />
        ) : (
          <Row>
            <Col lg="12">
              <div className="mb-4 h4 card-title">Users</div>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={usersData}
                    isSortBy={false}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilter={false}
                    customPageSize={10}
                    className="custom-header-css"
                    currentItems={currentItems}
                    setCurrentItems={setCurrentItems}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default withRouter(UnverifiedUsers)
