import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"

const Payouts = ({ columns, payoutsData, modelPopData, closeModal, approvalHandler, currentItems, setCurrentItems }) => {


    return (
        <React.Fragment>
            <RequestModel
                modelData={modelPopData}
                isOpen={!!modelPopData}
                toggle={closeModal}
                approvalHandler={approvalHandler}
            />
            <Card>
                <CardBody>
                    <div className="mb-4 h4 card-title">Payouts</div>
                    {payoutsData.length ? (
                        <TableContainer
                            columns={columns}
                            data={payoutsData}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            customPageSize={6}
                            currentItems={currentItems}
                            setCurrentItems={setCurrentItems}
                        />
                    ) : (
                        <Box
                            alignItems="center"
                            justifyContent="center"
                            style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
                            sx={{
                                p: 5,
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                                color: "red",
                            }}
                        >
                            No Payouts Requests Found.
                        </Box>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

Payouts.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func,
}

export default withRouter(Payouts)
