import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
// import { FaCopy } from "react-icons/fa";
import moment from "moment"
import { toast } from "react-toastify"

const COPIER = ({ row, copyToClipboard }) => {
  const [copy, setCopy] = useState(false)
  const copyHandler = () => {
    setCopy(true)
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  }

  return (
    <span style={{ marginLeft: '5px', cursor: "pointer" }} key={row?.id}>
      {!copy ? <span onClick={copyHandler} >
        <i className="fa fa-regular xs fa-copy" />
      </span> :
        <span>
          <i className="fa fa-check" aria-hidden='true' />
        </span>
      }
    </span>
  )
}

const FeedbackModel = ({ isOpen, toggle, modelData }) => {
  const description = modelData?.message
  const slimDis = description?.message?.substring(0, 400)

  // Function to copy the email to the clipboard
  const copyToClipboard = email => {
    const textArea = document.createElement("textarea")
    textArea.value = email
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)

    toast.success("Email copied to clipboard!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {modelData?.name + "'s" + " " + "Feedback"}
      </ModalHeader>

      <div className="modal-content">
        <Table className="table align-middle table-nowrap">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <p className="mb-2">
                    id: <span className="text-primary">{modelData?.id}</span>
                  </p>
                  <p className="mb-2">
                    CreatedAt:{" "}
                    <span className="text-primary">
                      {moment(modelData?.created_at).format("LL")}
                    </span>
                  </p>
                  <p className="mb-2">
                    Customer Name:{" "}
                    <span className="text-primary">{modelData?.name}</span>
                  </p>
                  <p className="mb-2">
                    Customer Email:{" "}
                    <span
                      className="text-primary"
                      style={{
                        textDecoration: "none",
                        transition: "text-decoration 0.3s",
                      }}

                    >
                      {modelData?.email}
                      <COPIER row={modelData} copyToClipboard={copyToClipboard} />

                    </span>
                  </p>
                  <p className="mb-2">
                    Feedback image:{" "}
                    <a
                      href={modelData?.img_url}
                      download
                      className="text-primary"
                      style={{
                        textDecoration: "none",
                        transition: "text-decoration 0.3s",
                      }}
                    >
                      Download
                      <svg
                        fill="#000000"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        id="download"
                        data-name="Line Color"
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon line-color"
                      >
                        <path
                          d="M20,17v3a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V17"
                          fill="none"
                          stroke="rgb(44, 169, 188)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          d="M5.65,10.56,7,9a1,1,0,0,1,1.41-.11L10,10.34V3h4v7.34l1.64-1.41A1,1,0,0,1,17.05,9l1.3,1.52A1,1,0,0,1,18.24,12l-5.59,4.79a1,1,0,0,1-1.3,0L5.76,12A1,1,0,0,1,5.65,10.56Z"
                          fill="none"
                          stroke="rgb(0, 0, 0)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </svg>

                    </a>
                  </p>

                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <ModalBody>
          <p className="mb-2">
            Message: <span className="text-primary">{modelData?.message}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

FeedbackModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FeedbackModel
