import { toast } from "react-toastify"


export function copyToClipboard(text) {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            // toast("Tariff code copied")
            toast.success("copied")
            document.execCommand("copy")
        })
        .catch(error => {
            toast.error("Not copied")
        })
}

export function convertToInches(feetInches) {
    const [feet, inches] = feetInches.replace("''", "").split("'").map(Number);
    return feet * 12 + inches;
}