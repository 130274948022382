import {
  GET_HANGAR_STATUS, GET_HANGAR_STATUS_FAIL, GET_HANGAR_STATUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  status: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_HANGAR_STATUS_SUCCESS:
      return {
        ...state,
        ...action,
      }

    case GET_HANGAR_STATUS_FAIL:
      return {
        ...state,
        ...action,
      }


    default:
      return state
  }
} 
