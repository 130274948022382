import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "../pages/Authentication/user-profile"
import InvoiceDetail from "../pages/Invoices/invoices-detail"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-sales/index"
import Payouts from "../pages/Dashboard-payouts"
import Pages404 from "../pages/Utility/pages-404"
import HangarFeedback from "../pages/Dashboard-feedback"
import contactsList from "../pages/Users/users-list"
import UnverifiedUsers from "../pages/unverified-users"
import HangarPayouts from "../pages/Dashboard-Referalls-payouts"
import ConnectAccountRequest from "../pages/Dashboard-Connect"
import HangarDetails from "../pages/approved-hangars/hangarDetails"
import ConnectDetails from "../pages/Dashboard-Connect/connect-details"
import DashboardReferals from "../pages/Dashboard-referals/ContactList/referals"
import HangarRequests from "../pages/hangar-requests"
import HangarUpdateDetails from "../pages/hangar-requests/hangarDetails"
import DeleteHangars from "../pages/Dashboard-Delete-Hangar"
import PlaneInsurance from "../pages/plane-insurance"
import PlaneList from "../pages/plane-list"
import HangarSubscribers from "../pages/Dashboard-Subscriber"
import ArticlePreview from "../pages/help-desk/preview"
import HangarPosts from "../pages/help-desk"
import HelpDesk from "../pages/help-desk/create-article"
import Reviews from "../pages/Dashboard-Reviews"
import ReviewDetails from "../pages/Dashboard-Reviews/reviewDetails"
import Orders from "../pages/Dashboard-Orders"
import PayoutMethods from "../pages/Dashboard-Payout-Methods"
import PaymentMethodDetails from "../pages/Dashboard-Payout-Methods/payment-method-details"
import AddPlane from "../pages/plane-list/add-new-plane"
import HangarsApproved from "../pages/approved-hangars"
import CancelRequest from "../pages/cancel-request"
import CancelDetail from "../pages/cancel-request/cancelDetail"
import AirportList from "pages/airport-list"
import AddAirport from "pages/airport-list/add-new-airport"
import PremRequests from "pages/Dashboard-prem-requests/PremRequests"
import PremRequestsPage from "pages/Dashboard-prem-requests"
// import CancelDetails from "pages/Dashboard-cancel-request/hangarDetails"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/sales-analytics", component: DashboardSaas },
  { path: "/approved-hangars", component: HangarsApproved },
  { path: "/hangar-requests", component: HangarRequests },
  { path: "/plane-insurance", component: PlaneInsurance },
  { path: "/plane-list", component: PlaneList },
  { path: "/users", component: contactsList },
  { path: "/unverified-users", component: UnverifiedUsers },
  { path: "/orders", component: Orders },
  { path: "/payouts", component: Payouts },
  { path: "/referrals-payouts", component: HangarPayouts },
  { path: "/referals", component: DashboardReferals },
  { path: "/connect-request", component: ConnectAccountRequest },
  { path: "/reviews", component: Reviews },
  { path: "/help-desk", component: HangarPosts },
  { path: "/feedbacks", component: HangarFeedback },
  { path: "/hangar-subscribers", component: HangarSubscribers },
  { path: "/profile", component: UserProfile },
  { path: "/help-desk/preview/:id?", component: ArticlePreview },
  { path: "/hangar-request-details/:id?", component: HangarUpdateDetails },
  { path: "/hangar-details/:id?", component: HangarDetails },
  { path: "/delete-hangar", component: DeleteHangars },
  { path: "/invoices-detail/:id?", component: InvoiceDetail },
  { path: "/connect-account-details/:id?", component: ConnectDetails },
  { path: "/create-article", component: HelpDesk },
  { path: "/reviews/review-details/:id?", component: ReviewDetails },
  { path: "/payout-methods", component: PayoutMethods },
  { path: "/payout-methods-details/:id?", component: PaymentMethodDetails },
  { path: "/add-new-plane", component: AddPlane },
  { path: "/cancel-request", component: CancelRequest },
  { path: "/premium-requests", component: PremRequestsPage },
  { path: "/cancel-request-details/:id?", component: CancelDetail },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  // airport routes
  { path: "/airport-list", component: AirportList },
  { path: "/add-new-airport", component: AddAirport },
  { path: "/edit-airport/:id?", component: AddAirport },
]
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/pages-404", component: Pages404 },
]
export { authProtectedRoutes, publicRoutes }
