import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import { BULK_BUTTONS, PAGE_SIZE } from "pages/Utility/constants"
import { Box } from "@material-ui/core"

const calculatePageOptions = (filtered, pageSize) => {
  const totalPages = Math.ceil(filtered.length / pageSize);
  return Array.from({ length: totalPages }, (_, i) => i);
};

const CustomGlobalFilter = ({ filtered, handleFilter, filterText }) => {
  const count = filtered?.length;
  return (
    <React.Fragment>
      <Col>
        <div className="search-box my-xxl-0 w-100 d-inline-block">
          <div style={{ borderRadius: "10px" }} className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={(e) => handleFilter(e.target.value)}
                id="search-bar-0"
                type="text"
                className="form-control"
                style={{ borderRadius: "5px", width: "300px" }}
                placeholder={`Search from ${count} records...`}
                value={filterText || ""}
              />
            </label>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  currentItems = [],
  setCurrentItems,
  data,
  setData,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  className,
  selection,
  onRowClick,
  rowClick,
  isSelectDropdown = true,
  isFilter = true,
  header,
  isSortBy = true,
  isPage = true,
  handleFirstPage,
  handleBack,
  handleNext,
  handleLastPage,
  bulkButtons = [],
  isShowPagination = true,
  numSize,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: currentItems,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: numSize ? numSize : PAGE_SIZE,
        sortBy: isSortBy
          ? [
            {
              desc: true,
            },
          ]
          : [],
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  //custom global filter
  const [filterText, setFilterText] = useState('');
  const [itemOffset, setItemOffset] = useState(0);
  const [pageSizeCurrent, setPageSizeCurrent] = useState(numSize || PAGE_SIZE);
  const [pageOptions, setPageOptions] = useState(calculatePageOptions(currentItems, pageSizeCurrent));
  const [filtered, setFiltered] = useState([...data]);


  useEffect(() => {
    setPageOptions(calculatePageOptions(filtered, pageSizeCurrent));
  }, [filtered, pageSizeCurrent]);

  const handleResetPagination = (dataToSet) => {
    setItemOffset(0);
    const endOffset = 0 + pageSizeCurrent;
    setPageOptions(calculatePageOptions(dataToSet, pageSizeCurrent));
  };

  const handleFilter = (val) => {
    setFilterText(val);
    if (val === "") {
      handleResetPagination(data);
      setCurrentItems(data);
      setFiltered(data);
    } else {
      const filter = data.filter(item => {
        for (const key in item) {
          if (item[key] && item[key].toString().toLowerCase().includes(val.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
      setCurrentItems(filter);
      setFiltered(filter);
      handleResetPagination(filter);
    }
  }
  //custom global filter


  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔼" : " 🔽") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
    setPageSizeCurrent(Number(event?.target?.value || event))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleBulk = status => () => {
    props.handleBulk && props.handleBulk(status)
  }

  const showPagination = pageCount > 1

  return (
    <Fragment>
      <div className="row">
        {isSelectDropdown && (
          <div className="row justify-content-between p-0 mb-3">
            <div style={{ marginLeft: "12px" }} className="col-3">
              {isPage && (
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              )}
            </div>
            {isGlobalFilter && <div className="col-2 p-0 ml-auto">
              <CustomGlobalFilter filtered={filtered} filterText={filterText} handleFilter={handleFilter} />
            </div>}
            {header ? <div style={{ width: "60%" }} className="col-4 p-0">{header}</div> : null}
          </div>
        )}

        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {isFilter && <Filter column={column} />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            style={{ backgroundColor: "#000", verticalAlign: "middle" }}
            {...getTableBodyProps()}
          >
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    className="cursor-pointer"
                    onClick={() => {
                      if (!rowClick) return
                      onRowClick(row)
                    }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          style={{
                            maxWidth: "200px",
                            // overflow: "hidden",
                            textOverflow: "ellipsis",
                            // whiteSpace: "nowrap",
                          }}
                          key={cell.id}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {page?.length === 0 && (
          <Box
            alignItems="center"
            justifyContent="center"
            style={{
              minHeight: "50vh",
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
            sx={{
              p: 5,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              color: "red",
            }}
          >
            No records found.
          </Box>
        )}
      </div>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        {/* selected rows */}
        {!!selection?.length && (
          <Col className="col-md-auth">
            {bulkButtons.map(btn => (
              <Button
                key={btn}
                onClick={handleBulk(btn)}
                color={
                  btn === BULK_BUTTONS.APPROVE
                    ? "success"
                    : btn === BULK_BUTTONS.REJECT
                      ? "warning"
                      : "danger"
                }
                className="me-2"
              >
                {btn}
              </Button>
            ))}
          </Col>
        )}
        {/* pagination controls */}
        {showPagination && isShowPagination && (
          <>
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => (isPage ? gotoPage(0) : handleFirstPage())}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={isPage ? previousPage : handleBack}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>

            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Col>
            {isPage && (
              <Col className="col-md-auto">
                <Input
                  type="number"
                  min={1}
                  style={{ width: 70 }}
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={onChangeInInput}
                />
              </Col>
            )}

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={isPage ? nextPage : handleNext}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    isPage ? gotoPage(pageCount - 1) : handleLastPage()
                  }
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
