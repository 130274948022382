import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import Hangar from "./hangar/reducer"
import Dashboard from "./dashboard/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  Profile,
  Dashboard,
  Hangar,
})

export default rootReducer
