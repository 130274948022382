import React from 'react';

const FieldError = ({ text, customClass }) => {
    const errorStyle = {
        fontSize: '0.75rem', // equivalent to Tailwind's text-xs
        color: '#dc3545',   // equivalent to Tailwind's text-red-500
        transition: 'all 1s', // equivalent to Tailwind's transition-all and delay-1000
    };

    return (
        <div className={`error-text ${customClass}`} style={errorStyle}>
            {text}
        </div>
    );
};

export default FieldError;