import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const StackedColumnChart = ({ dataColors, labels = [], data = [] }) => {

  const stackedColumnChartColors = getChartColorsArray(dataColors);
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: labels, labels: {
        show: true
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value?.toFixed(2);
        }
      },
      forceNiceScale: true
    },
    
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[
          {
            "name": "Total",
            "data": data
          },

        ]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

export default StackedColumnChart;
