import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { STATUS } from "pages/Utility/constants"
const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.transaction_id.id || ""}
    </Link>
  )
}

const Title = cell =>
  cell.row.original?.transaction_id.user_id?.meta?.first_name
    ? cell.row.original?.transaction_id.user_id?.meta?.first_name +
    " " +
    cell.row.original?.transaction_id.user_id?.meta?.last_name
    : ""
const Total = cell => {
  return (
    <div>
      <span style={{ fontWeight: "500" }}>$</span>
      {cell.row.original.transaction_id.total_Amount
        ? (+cell.row.original.transaction_id.total_Amount + (+cell?.row.original.transaction_id?.credits || 0)).toFixed(2)
        : ""}
    </div>
  )
}

const Date = cell => {
  const date = moment(cell.row.original.transaction_id.created_at).format("DD-MM-YYYY")
  return date ? date : ""
}
const StartDate = cell => {
  const date = moment(cell.row.original.transaction_id.checkIn).format("DD-MM-YYYY")
  return date ? date : ""
}

const Owner = cell => {
  return (
    <div className="d-flex justify-content-between  px-2">
      <div
        className={`bg-danger bg-opacity-25 px-1 rounded-2`}
        style={{
          color: `#F46A6A`,
          fontWeight: "500",
        }}
      >

        Cancelled

      </div>
    </div>
  )
}
const EndDate = cell => {
  const date = moment(cell.row.original.transaction_id.checkOut).format("DD-MM-YYYY")
  return date ? date : ""
}
const OwnerName = cell => {
  return cell.row.original?.transaction_id.hangar_owner?.meta?.first_name
    ? <div>
      <div className="" style={{ fontWeight: "600" }}>Hangout</div>
      <div>{cell.row.original?.transaction_id.hangar_owner?.meta?.first_name +
        " " +
        cell.row.original?.transaction_id.hangar_owner?.meta?.last_name}</div>
    </div>
    : ""
}
const RentalType = cell => {
  return (
    <div style={{ color: "#495057" }}>
      {cell.row.original?.transaction_id.rentalType
        ? cell.row.original?.transaction_id.rentalType
        : ""}

    </div>
  )
}
const Status = cell => {
  return (
    <Badge
      style={{
        textTransform: "capitalize",
        fontSize: "14px",
        padding: "5px 10px",
      }}
      className={
        "badge-soft-" +
        (cell.tabIndex === 1
          ? "warning"
          : cell.tabIndex === 2
            ? "success"
            : cell.tabIndex === 3
              ? "danger"
              : "info")
      }
    >
      {"Cancelled"}
    </Badge>
  )
}

export {
  OwnerName,
  CheckBox,
  OrderId,
  Title,
  Owner,
  Status,
  Date,
  StartDate,
  EndDate,
  Total,
  RentalType
}
