import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const [search, setsearch] = useState(false)
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 566 128"
                    className="w-20 lg:block hidden"
                    style={{ fill: "#00AEEF", width: "28px" }}
                  >
                    <g id="Layer_1"></g>
                    <g className="" id="Layer_2">
                      <g>
                        <path d="M69.87,70.1c-2.3-1.77-5.65-2.88-9.38-2.88s-7.08,1.11-9.38,2.88c-2.87,2.21-.58,5.7,3.74,5.7h11.28c4.32,0,6.61-3.49,3.74-5.7Z" />
                        <path d="M108.54,42.89h0c-28.75-20.74-67.34-20.72-96.07,.05h0c-3.07,2.22-4.89,5.8-4.89,9.61v43.27c0,4.09,3.28,7.41,7.32,7.41H106.16c4.06,0,7.35-3.33,7.35-7.44V52.66c0-3.88-1.85-7.52-4.97-9.77Zm-3.17,34.03c0,2.14-1.74,3.88-3.88,3.88h-8.24c.04,.22,.06,.45,.06,.68,0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91c0-.23,.02-.46,.06-.68h-7.02c-1.91,8.19-9.26,14.3-18.04,14.3s-16.13-6.11-18.04-14.3h-6.99c.04,.22,.06,.45,.06,.68,0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91c0-.23,.02-.46,.06-.68h-8.14c-2.14,0-3.88-1.74-3.88-3.88v-.06c0-2.14,1.74-3.88,3.88-3.88h22.7c1.45-7.35,7.25-13.13,14.61-14.56v-7.3c0-2.16,1.75-3.91,3.91-3.91s3.91,1.75,3.91,3.91v7.45c7.02,1.65,12.51,7.3,13.91,14.41h22.82c2.14,0,3.88,1.74,3.88,3.88v.06Z" />
                      </g>
                    </g>
                  </svg>
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 566 128"
                    className="w-20 lg:block hidden"
                    style={{ fill: "#00AEEF", width: "28px" }}
                  >
                    <g id="Layer_1"></g>
                    <g className="" id="Layer_2">
                      <g>
                        <path d="M69.87,70.1c-2.3-1.77-5.65-2.88-9.38-2.88s-7.08,1.11-9.38,2.88c-2.87,2.21-.58,5.7,3.74,5.7h11.28c4.32,0,6.61-3.49,3.74-5.7Z" />
                        <path d="M108.54,42.89h0c-28.75-20.74-67.34-20.72-96.07,.05h0c-3.07,2.22-4.89,5.8-4.89,9.61v43.27c0,4.09,3.28,7.41,7.32,7.41H106.16c4.06,0,7.35-3.33,7.35-7.44V52.66c0-3.88-1.85-7.52-4.97-9.77Zm-3.17,34.03c0,2.14-1.74,3.88-3.88,3.88h-8.24c.04,.22,.06,.45,.06,.68,0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91c0-.23,.02-.46,.06-.68h-7.02c-1.91,8.19-9.26,14.3-18.04,14.3s-16.13-6.11-18.04-14.3h-6.99c.04,.22,.06,.45,.06,.68,0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91c0-.23,.02-.46,.06-.68h-8.14c-2.14,0-3.88-1.74-3.88-3.88v-.06c0-2.14,1.74-3.88,3.88-3.88h22.7c1.45-7.35,7.25-13.13,14.61-14.56v-7.3c0-2.16,1.75-3.91,3.91-3.91s3.91,1.75,3.91,3.91v7.45c7.02,1.65,12.51,7.3,13.91,14.41h22.82c2.14,0,3.88,1.74,3.88,3.88v.06Z" />
                      </g>
                    </g>
                  </svg>
                </span>
              </Link>
            </div> */}

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Search" + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}

            {/* <div
              style={{ paddingTop: "1px" }}
              className="dropdown d-none d-lg-inline-block ms-1"
            >
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div> */}

            {/* <NotificationDropdown /> */}
            <ProfileMenu />

            <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header)
