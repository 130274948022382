import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"

const RequestModel = ({ isOpen, toggle, modelData }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Connect Account Request Details</ModalHeader>
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap">
          <tbody>
            <tr>
              <td>
                <div>
                  <p
                    style={{ fontWeight: "bold" }}
                    className="text-muted text-center mb-0"
                  >
                    {/* {modelData?.email} */}
                    {modelData?.firstName + " " + modelData?.lastName}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="modal-content">
        <ModalBody>
          <p className="mb-2">
            id: <span className="text-primary">{modelData?.id}</span>
          </p>
          <p className="mb-2">
            CreatedAt:{" "}
            <span className="text-primary">
              {moment(modelData?.created_at).format("LL")}
            </span>
          </p>
          <p className="mb-2">
            Email: <span className="text-primary">{modelData?.email}</span>
          </p>
          <p className="mb-2">
            Business Name:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.businessName}
            </span>
          </p>
          <p className="mb-2">
            Date of Birth{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.dob}
            </span>
          </p>
          <p className="mb-2">
            Address:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.addressLine1}
            </span>
          </p>
          <p className="mb-2">
            Business City:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.businessCity}
            </span>
          </p>
          <p className="mb-2">
            Business State:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.businessState}
            </span>
          </p>
          <p className="mb-2">
            Business Zip:{" "}
            <span className="text-primary">{modelData?.businessZip}</span>
          </p>
          <p className="mb-2">
            Phone: <span className="text-primary">{modelData?.phone}</span>
          </p>
          <p className="mb-4">
            Card Number:{" "}
            <span className="text-primary">{modelData?.cardNumber === ""
              ? modelData?.bankAccount
              : modelData?.cardNumber}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

RequestModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RequestModel
