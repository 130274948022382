import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { STATUS } from "pages/Utility/constants"
const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Title = cell =>
  cell.row.original?.full_name ? cell.row.original?.full_name : ""
const Total = cell => {
  return (
    <div>
      <span style={{ fontWeight: "500" }}>$</span>
      {cell.cell.row.original.total_Amount
        ? (+cell.cell.row.original.total_Amount).toFixed(2)
        : ""}
    </div>
  )
}

const Date = cell => {
  const date = moment(cell.row.original.created_at).format("DD-MM-YYYY")
  return date ? date : ""
}
const StartDate = cell => {
  const date = moment(cell.row.original.checkIn).format("DD-MM-YYYY")
  return date ? date : ""
}
const EndDate = cell => {
  const date = moment(cell.row.original.checkOut).format("DD-MM-YYYY")
  return date ? date : ""
}
const RantelType = cell => <span style={{ textTransform: 'capitalize' }}>{cell?.row?.original?.rentalType}</span>

const Owner = cell => {
  return (
    <div className="d-flex justify-content-between  px-2">
      <div
        className={`${cell.row.original.orderStatus === String(STATUS.ORDER_APPROVED)
          ? "bg-info"
          : cell.row.original.orderStatus === String(STATUS.ORDER_INPROCESS)
            ? "bg-warning"
            : cell.row.original.orderStatus === String(STATUS.ORDER_REFUNDED)
              ? "bg-danger"
              : "bg-danger"
          } bg-opacity-25 px-1 rounded-2`}
        style={{
          color: `${cell.row.original.orderStatus === String(STATUS.ORDER_APPROVED)
            ? "#34C38F"
            : cell.row.original.orderStatus === String(STATUS.ORDER_INPROCESS)
              ? "#E58037"
              : cell.row.original.orderStatus === String(STATUS.ORDER_REFUNDED)
                ? "#F46A6A"
                : "#F46A6A"
            }`,
          fontWeight: "500",
        }}
      >
        {
          cell.status?.find(sta => sta.id === +cell.row.original.orderStatus)
            ?.host_name
        }
      </div>
    </div>
  )
}

const Financial = cell => {
  return (
    <div style={{ color: "#34C38F" }}>
      <span
        style={{ fontWeight: "500" }}
        className="bg-info bg-opacity-25 px-2 rounded-2"
      >
        {cell.row.original?.paymentStatus
          ? cell.row.original?.paymentStatus
          : ""}
      </span>
    </div>
  )
}

const Status = cell => {
  return (
    <Badge
      style={{
        textTransform: "capitalize",
        fontSize: "14px",
        padding: "5px 10px",
      }}
      className={
        "badge-soft-" +
        (cell.tabIndex === 1
          ? "warning"
          : cell.tabIndex === 2
            ? "success"
            : cell.tabIndex === 3
              ? "danger"
              : "info")
      }
    >
      {cell.tabIndex === 1
        ? "In Review"
        : cell.tabIndex === 2
          ? "Approved"
          : cell.tabIndex === 3
            ? "Rejected"
            : "Deleted!"}
    </Badge>
  )
}

export {
  CheckBox,
  RantelType,
  OrderId,
  Title,
  Owner,
  Financial,
  Status,
  Date,
  StartDate,
  EndDate,
  Total,
}
