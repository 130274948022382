import { supabase } from "pages/Utility/supabase"
import React from "react"
import PropTypes from "prop-types"
import { Col, Container, Row } from "reactstrap"
import { ACCOUNT_TYPES, STATUS, TABLES } from "pages/Utility/constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useState } from "react"
import { useEffect } from "react"
import DefaultLoading, { Spinner } from "common/loading"
import Alerts from "common/Alerts/Alert"
import FieldsInput from "common/Fields_Input"
import { toast } from "react-toastify"


const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
const stripe = require("stripe")(KEY)

const PaymentMethodDetails = props => {
  document.title = "Payout Method Detail | Hangar Direct"
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false)
  const [data, setData] = useState(null)
  console.log('data: ', data);
  const [paymentData, setPaymentData] = useState(null)

  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params && params.id) {
      fetchPaymentData(params.id)
    }
  }, [history])

  const fetchPaymentData = async id => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
        .from(TABLES.PAYMENT_METHODS)
        .select("*,profile(*)")
        .eq("id", id)

      if (error) {
        throw error
      } else if (data) {
        setData(data[0])
        setLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      console.log(error.message)
      setLoading(false)
    }
  }
  const updatePaymentDB = async (p_id) => {
    setLoading(true)
    try {
      const { data = [], error } = await supabase
        .from(TABLES.PAYMENT_METHODS)
        .update({ 'stripe_id': p_id, 'status': STATUS.PAYMENT_METHOD_APPROVED })
        .eq("id", params.id)
        .select()
      if (data?.length) {
        toast.success("Account approve success")
      }

      if (error) {
        throw error
      } else if (data?.length) {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('error: ', error);

    }
  }
  // const statusHandler = async (value, token) => {
  const statusHandler = async (value) => {
    try {
      if (value === STATUS.CONNECT_ACCOUNT_APPROVED) {
        try {
          if (data?.stripe_id) {
            const { data = [], error } = await supabase
              .from(TABLES.PAYMENT_METHODS)
              .update({ 'status': STATUS.PAYMENT_METHOD_APPROVED })
              .eq("id", params.id)
              .select()
            if (data?.length) {
              setLoading(false);
              history.goBack();
              toast.success("Approve success")
            }
          } else {
            if (data?.type === ACCOUNT_TYPES.CARD) {
              const card = await stripe.accounts.createExternalAccount(
                data?.profile?.stripeAccount,
                { external_account: data?.data?.tokenId }
              );
              if (card) updatePaymentDB(card.id)
            } else {
              const bankAccount = await stripe.accounts.createExternalAccount(
                data?.profile?.stripeAccount,
                {
                  external_account: data?.data,
                }

              );
              if (bankAccount) updatePaymentDB(bankAccount?.id)
            }
          }

        } catch (error) {
          toast.error(error.message)
          console.error('Error adding payment method:', error.message);

        }

      } else if (value === STATUS.CONNECT_ACCOUNT_REJECTED) {
        const { data, error } = await supabase
          .from(TABLES.PAYMENT_METHODS)
          .update({ status: STATUS.CONNECT_ACCOUNT_REJECTED })
          .eq("id", params.id)
          .select()
        if (data?.length) {
          toast.success("Account rejected success");
          history.goBack();
        }
        if (error) {
          throw error
        }
      }
    } catch (error) {
      console.log("error: ", error.message);
      toast.error(error.message)
      setAlert({
        ...alert,
        heading: "Invalid!",
        message: error.message,
        type: "warn",
      })
    }
    setBtnLoading(false)
    setRejectBtnLoading(false)
  }
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }

  return (
    <div className="page-content">
      {loading ? (
        <DefaultLoading />
      ) : (
        <Container fluid>
          {alert?.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          <Heading text={`${data?.type === 'card' ? 'Card ' : "Bank Account "}Details`} customClass="px-0" />

          <Row>
            <Col xl="9">
              <div className="bg-white px-2 my-3 pb-3 rounded">
                <Heading text="Payout Info" customClass="m-0 px-1" />

                <div className="text-black container inline-block">
                  <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
                    {data?.type === ACCOUNT_TYPES.BANK ? (
                      <>
                        {/* 1th row */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"Account Holder Name:"}
                                val={data?.data?.account_holder_name}
                                name_field={"accountHolderName"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Routing Number:"}
                                val={data?.data?.routing_number}
                                name_field={"routingNumber"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* 2th row */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"Account Number:"}
                                val={data?.data?.account_number || data?.data?.last4}
                                name_field={"bankAccount"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Payout Type:"}
                                val={data?.type}
                                name_field={"paymentMethod"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* 1th row card */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"Card Number:"}
                                val={data?.data?.number || data?.data?.last4}
                                name_field={"cardNumber"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Expiry:"}
                                val={data?.data?.exp_month + '/' + data?.data?.exp_year}
                                name_field={"expiry"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* 2th row */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              {/* <FieldsInput
                                label={"CVC:"}
                                val={data?.data?.cvc}
                                name_field={"cvc"}
                              // onchange={onConnectFieldsChange}
                              /> */}
                              <FieldsInput
                                label={"Payout Type:"}
                                val={data?.type}
                                name_field={"paymentMethod"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="3" className="p-0">
              <div className="container px-2">
                <div className="row mx-0">
                  <button
                    onClick={() => {
                      setBtnLoading(true)
                      statusHandler(STATUS.CONNECT_ACCOUNT_APPROVED)
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    {"Approve  "}
                    {
                      btnLoading &&
                      <Spinner wT={"18px"} hT={"18px"} />
                    }
                  </button>
                </div>
                <div className="row pt-3 mx-0">
                  <button
                    onClick={() => {
                      setRejectBtnLoading(true)
                      statusHandler(STATUS.CONNECT_ACCOUNT_REJECTED)
                    }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    {"Reject  "}
                    {
                      rejectBtnLoading &&
                      <Spinner wT={"18px"} hT={"18px"} />
                    }
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default PaymentMethodDetails

