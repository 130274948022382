import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import { supabase } from "pages/Utility/supabase"
import { TABLES } from "pages/Utility/constants"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"
import DefaultLoading from "common/loading"

const Requests = ({ columns, hangars, modelPopData, closeModal, selection, setLoading, getHangarsData, selectedRowsUserIDs, setAlert, currentItems, setCurrentItems }) => {


  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Connect Requests</div>
          {hangars.length ? (
            <TableContainer
              columns={columns}
              data={hangars}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
              selection={selection}
              setLoading={setLoading}
              getHangarsData={getHangarsData}
              selectedRowsUserIDs={selectedRowsUserIDs}
              setAlert={setAlert}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
              sx={{
                p: 5,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "red",
              }}
            >
              No Connect Requests Found.
            </Box>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)
