import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"
const FeedbackModel = ({ isOpen, toggle, modelData }) => {
  const description = modelData?.message
  const slimDis = description?.message?.substring(0, 400)
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {modelData?.name + "'s" + " " + "Feedback"}
      </ModalHeader>

      <div className="modal-content">
        <Table className="table align-middle table-nowrap">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <p className="mb-2">
                    id: <span className="text-primary">{modelData?.id}</span>
                  </p>
                  <p className="mb-2">
                    CreatedAt:{" "}
                    <span className="text-primary">
                      {moment(modelData?.created_at).format("LL")}
                    </span>
                  </p>
                  <p className="mb-2">
                    Customer Name:{" "}
                    <span className="text-primary">{modelData?.name}</span>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <ModalBody>
          <p className="mb-2">
            Message: <span className="text-primary">{modelData?.message}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

FeedbackModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FeedbackModel
