import React from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import { Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import {
  OrderId,
  Title,
  Action,
  Owner,
  Rating,
} from "../Dashboard-Reviews/LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { DATE_FORMATS, TABLES } from "pages/Utility/constants"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { toast } from "react-toastify"

const Reviews = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowsUserIDs, setSelectedRowsUserIDs] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Reviews | HangarDirect"

  // const handleModelData = row => {
  //   history.push("/hangar-details/" + row.id)
  // }

  const handleCheckChange = adiProp => e => {
    if (e.target.checked) {
      setSelectedRows(s => [...s, { id: adiProp.id, userId: adiProp.user_id }])
      setSelectedRowsUserIDs(s => [...s, adiProp.user_id])
    } else {
      setSelectedRows(s => s.filter(row => row.id !== adiProp.id))
      setSelectedRowsUserIDs(s => s.filter(row => row !== adiProp.user_id))
    }
  }
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      console.log("Attempting to delete review with ID:", id); // Debug log

      const { error } = await supabase
        .from(TABLES.REVIEWS)
        .delete()
        .eq("id", id);

      if (error) throw error;

      console.log("Review deleted successfully, updating state"); // Debug log
      setHangars((prevHangars) => prevHangars.filter((hangar) => hangar.id !== id));
      setCurrentItems((prevItems) => prevItems.filter((item) => item.id !== id));
      toast.success("Review deleted successfully");
    } catch (error) {
      toast.error(`Error deleting review: ${error.message}`);
      console.error("Error deleting review:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getHangarsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.REVIEWS)
        .select("*,profile(*)") // inner join and join multiple relation
        // .join("profile", "transactions.user_id,profile.id")
        .order("id", { ascending: true })

      if (error) {
        throw error
      }
      setHangars(data);
      setCurrentItems(data);
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      console.log("error: ", error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    getHangarsData()
  }, [])

  const columns = useMemo(() => [
    // {
    //   Header: "#",
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     let ids = {
    //       id: cellProps.row.original.id,
    //       user_id: cellProps.row.original.user_id,
    //     }
    //     return (
    //       <input
    //         onChange={handleCheckChange(ids)}
    //         type="checkbox"
    //         className="form-check-input"
    //       />
    //     )
    //   },
    // },
    {
      Header: "Id",
      accessor: "Id",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />
      },
    },
    {
      Header: "Hangar owner",
      accessor: "Customer",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Title {...cellProps} />
      },
    },
    {
      Header: "Comment",
      accessor: "message",
      disableFilters: true,
    },
    // {
    //   Header: "Hanger Owner",
    //   disableFilters: true,
    //   filterable: false,
    //   Cell: cellProps => {
    //     return <Owner {...cellProps} />
    //   },
    // },
    // {
    //   Header: "Status",
    //   accessor: "Status",
    //   disableFilters: true,
    //   filterable: false,
    //   Cell: cellProps => {
    //     return <City {...cellProps} />
    //   },
    // },
    {
      Header: "Created date",
      accessor: ({ created_at }) =>
        moment(created_at).format(DATE_FORMATS.CREATED_AT),
      disableFilters: true,
      filterable: false,
    },
    {
      Header: "Rating",
      accessor: "rating",
      disableFilters: true,
    },

    // {
    //   Header: "Action",
    //   accessor: "Action",
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     const { id } = cellProps.row.original;

    //     const onDelete = () => {
    //       if (window.confirm("Are you sure you want to delete this review?")) {
    //         handleDelete(id);
    //       }
    //     };

    //     return (
    //       <div className="d-flex gap-1 cursor-pointer">
    //         <img
    //           style={{ height: "30px" }}
    //           src="/images/delete.png"
    //           alt="Delete"
    //           onClick={onDelete}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div
          style={{ color: "#495057", fontSize: "15px", fontWeight: "600" }}
          className="text-secondary mb-3"
        >
          Review Requests
        </div> */}
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Requests
                  columns={columns}
                  hangars={hangars}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  selection={selectedRows}
                  setLoading={setLoading}
                  getHangarsData={getHangarsData}
                  selectedRowsUserIDs={selectedRowsUserIDs}
                  setAlert={setAlert}
                  className="!mb-0"
                  rowClick={true}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                  onRowClick={r =>
                    history.push(
                      `/reviews/review-details/${r.original.transaction_id}`
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reviews

