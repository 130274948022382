import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes"
import AuthMiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import { getStatus } from "./store/hangar/actions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { supabase } from "pages/Utility/supabase"
const App = props => {
  const dispatch = useDispatch()
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const fetchSession = async () => {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession()
    if (!session) localStorage.removeItem("authUser")
  }
  useEffect(() => {
    fetchSession()
    const session = localStorage.getItem("session")
    if (!session) {
      localStorage.removeItem("authUser")
    }
    dispatch(getStatus())
  }, [])
  useEffect(() => {
    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places&callback=Function.prototype`
    script.async = true
    document.body.appendChild(script)
  }, [])
  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
      <ToastContainer />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
