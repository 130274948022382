import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"

const RequestModel = ({ isOpen, toggle, modelData }) => {

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Hangar Details</ModalHeader>
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap">
          <thead></thead>
          <tbody>
            <tr>
              <th scope="row">
                <img
                  style={{ border: "1px", borderRadius: "5px" }}
                  src={
                    modelData?.images?.length
                      ? getImageUrl(modelData?.images[0])
                      : ""
                  }
                  alt="img"
                  className="avatar-sm"
                />
              </th>
              <td>
                <div>
                  <h5 className="text-truncat font-size-14">
                    {modelData?.title}
                  </h5>
                  <p style={{ fontWeight: "bold" }} className="text-muted mb-0">
                    ${modelData?.price_per_night}
                    <span className="">{" per day"}</span>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="modal-content">
        <ModalBody>
          <p className="mb-2">
            id: <span className="text-primary">{modelData?.id}</span>
          </p>
          <p className="mb-2">
            CreatedAt:{" "}
            <span className="text-primary">
              {moment(modelData?.created_at).format("LL")}
            </span>
          </p>
          <p className="mb-2">
            Near Airport:{" "}
            <span className="text-primary">{modelData?.near_airport}</span>
          </p>
          <p className="mb-2">
            Address:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.address}
            </span>
          </p>
          <p className="mb-2">
            City:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.city}
            </span>
          </p>
          <p className="mb-2">
            State:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.state}
            </span>
          </p>
          <p className="mb-4">
            Zip: <span className="text-primary">{modelData?.zip}</span>
          </p>
          <h5>Dimensions:</h5>
          <div>
            Width :
            {`${modelData?.width_feet + "'"} 
												${modelData?.width_inch + "'' "} `}
            <span className="text-primary">{`(${Math.round(modelData?.width_feet * 30.48) +
              Math.round(modelData?.width_inch * 2.54)
              }cm)`}</span>
          </div>
          <div>
            Length :
            {`${modelData?.length_feet + "'"} 
												${modelData?.length_inch + "'' "}`}
            <span className="text-primary">{`(${Math.round(modelData?.length_feet * 30.48) +
              Math.round(modelData?.length_inch * 2.54)
              }cm)`}</span>
          </div>
          <div>
            Height :
            {`${modelData?.height_feet + "'"} 
												${modelData?.height_inch + "'' "}`}
            <span className="text-primary">{`(${Math.round(modelData?.height_feet * 30.48) +
              Math.round(modelData?.height_inch * 2.54)
              }cm)`}</span>
          </div>

          <div>
            Door Height :
            {`${modelData?.door_height_feet + "'"} 
												${modelData?.door_height_inch + "'' "}`}
            <span className="text-primary">{`(${Math.round(modelData?.door_height_feet * 30.48) +
              Math.round(modelData?.door_height_inch * 2.54)
              }cm)`}</span>
          </div>
          <p className="mb-2">
            Door Width :
            {`${modelData?.door_width_feet + "'"} 
												${modelData?.door_width_inch + "'' "}`}
            <span className="text-primary">{`(${Math.round(modelData?.door_width_feet * 30.48) +
              Math.round(modelData?.door_width_inch * 2.54)
              }cm)`}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

RequestModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RequestModel
