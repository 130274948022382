import React from "react"
import { Button, Card, Col, Container, Input, Row } from "reactstrap"
import Requests from "./requests"

import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { TABLES } from "pages/Utility/constants"
import Swal from "sweetalert2"

const DEFAULT_ALERT_VALUES = {
  heading: "",
  message: "",
  type: "",
}

const AirportList = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [planeList, setPlaneList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState("")
  const totalPages = Math.ceil(totalCount / pageSize)
  const isLastPage = pageNumber === totalPages;
  const [currentItems, setCurrentItems] = useState([]);

  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Plane Requests | HangarDirect"

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Type",
        accessor: "type",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "ICAO",
        accessor: "ICAO",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Elevation feet",
        accessor: "elevation_ft",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "ISO Country",
        accessor: "iso_country",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Municipality",
        accessor: "municipality",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "GPS Code",
        accessor: "gps_code",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "IATA Code",
        accessor: "iata_code",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Local Code",
        accessor: "local_code",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Coordinates",
        accessor: "coordinates",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Action",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return (
            <div className="d-flex gap-1 cursor-pointer">
              <img
                onClick={() => history.push("/edit-airport?id=" + row.id)}
                style={{ height: "30px" }}
                src="/images/edit.png"
                alt=""
              />
              <img
                onClick={() => deletePopup(row.id)}
                style={{ height: "30px" }}
                src="/images/delete.png"
                alt=""
              />
            </div>
          )
        },
      },
    ],
    []
  )
  const deletePopup = async id => {
    Swal.fire({
      title: "Do you want to delete this aircraft?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then(result => {
      if (result.isDenied) {
        deleteRow(id)
      }
      Swal.close()
    })
  }

  const deleteRow = async id => {
    const { data, error } = await supabase
      .from(TABLES.AIRPORTS)
      .delete()
      .eq("id", id)
      .order("name", { ascending: false })
    if (error) console.log(error.message)
    else {
      getAirportsList()
    }
  }
  const filterVal = async () => {
    setLoading(true)
    const { data: totalCountData, error: countError } = await supabase
      .from(TABLES.AIRPORTS)
      .select("count", { count: "exact" })
      .or(`ICAO.ilike.${searchText},name.ilike.${searchText},local_code.ilike.${searchText}`)
    if (countError) {
      throw countError
    }
    setTotalCount(totalCountData[0]?.count)
    let { data: plane_list, error } = await supabase
      .from(TABLES.AIRPORTS)
      .select("*")
      .or(`ICAO.ilike.${searchText},name.ilike.${searchText},local_code.ilike.${searchText}`)
      .order("id", { ascending: true })

    if (plane_list) {
      setPlaneList(plane_list)
      setCurrentItems(plane_list)
      setLoading(false)
    }
    if (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAirportsList(pageNumber, pageSize)
  }, [pageNumber, pageSize])
  const getAirportsList = async (pgNumber, pgSize) => {
    setLoading(true)
    try {
      // Fetch total count
      const { data: totalCountData, error: countError } = await supabase
        .from(TABLES.AIRPORTS)
        .select("count", { count: "exact" })

      if (countError) {
        throw countError
      }
      setTotalCount(totalCountData[0]?.count)

      const offset = (pgNumber - 1) * pgSize

      let { data: plane_list, error } = await supabase
        .from(TABLES.AIRPORTS)
        .select("*")
        .order("id", { ascending: true })
        .range(offset, offset + pgSize - 1)

      if (error) {
        throw error
      }
      setPlaneList(plane_list)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <DefaultLoading />
        ) : (
          <Container fluid>
            {alert.type && (
              <Alerts
                type={alert.type}
                heading={alert.heading}
                message={alert.message}
                clear={() => setAlert(DEFAULT_ALERT_VALUES)}
              />
            )}
            <div className="d-flex justify-content-between  mb-2">
              <div className=" h4 card-title">Airports</div>
            </div>
            <Row>
              <Col lg="12">
                <Card className="mb-2">
                  <div className="rw d-flex justify-content-between p-0 pt-3 !mx- mb-3">
                    <div style={{ marginLeft: "12px" }} className="col-2">
                      <select
                        className="form-select"
                        value={pageSize}
                        onChange={e => setPageSize(e.target.value)}
                      >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex gap-2 align-items-center">

                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSearchText('')
                          getAirportsList(pageNumber, pageSize)
                        }}
                      >
                        <path
                          d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 5.26904 1.54367 3.28183 3.1694 2M7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 9.73096 13.4563 11.7182 11.8306 13M11.5 10V13.5H15M0 1.5H3.5V5"
                          stroke="#000000"
                        />
                      </svg>
                      <div className="position-relative">
                        <svg
                          style={{ top: 8, right: 5, background: 'white', cursor: 'pointer' }}
                          className="position-absolute"
                          stroke="currentColor"
                          fill="currentColor"
                          onClick={() => {
                            if (searchText === "") {
                              getAirportsList(pageNumber, pageSize)
                            } else {
                              filterVal(searchText)
                            }
                          }}
                          strokeWidth="0"
                          version="1.1"
                          id="search"
                          x="0px"
                          y="0px"
                          viewBox="0 0 24 24"
                          height="1.5em"
                          width="1.5em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M20.031,20.79c0.46,0.46,1.17-0.25,0.71-0.7l-3.75-3.76c1.27-1.41,2.04-3.27,2.04-5.31
		c0-4.39-3.57-7.96-7.96-7.96s-7.96,3.57-7.96,7.96c0,4.39,3.57,7.96,7.96,7.96c1.98,0,3.81-0.73,5.21-1.94L20.031,20.79z
		 M4.11,11.02c0-3.84,3.13-6.96,6.96-6.96c3.84,0,6.96,3.12,6.96,6.96c0,3.84-3.12,6.96-6.96,6.96C7.24,17.98,4.11,14.86,4.11,11.02
		z"
                            ></path>
                          </g>
                        </svg>
                        <input
                          onChange={e => setSearchText(e.target.value)}
                          onKeyDown={() => {
                            if (event.key === "Enter") {
                              if (searchText === "") {
                                getAirportsList(pageNumber, pageSize)
                              } else {
                                filterVal(searchText)
                              }
                            }
                          }}
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          style={{ borderRadius: "5px", width: "300px" }}
                          placeholder={`Search with full Name , ICAO or Local codes...`}
                          value={searchText || ""}
                        />
                      </div>
                      <Button
                        type="button"
                        color="info"
                        style={{ width: "150px", marginRight: "1rem" }}
                        className=" rounded col-2 mr-2"
                        onClick={() => {
                          history.push("/add-new-airport")
                        }}
                      >
                        + Add New Airport
                      </Button>
                    </div>
                  </div>
                  <Requests
                    columns={columns}
                    planeList={planeList}
                    setLoading={setLoading}
                    setAlert={setAlert}
                    numSize={pageSize}
                    currentItems={planeList}
                    setCurrentItems={setCurrentItems}
                    className="!mb-0"
                  />
                </Card>
              </Col>
            </Row>
            {!loading && (
              <Row className="justify-content-md-end justify-content-center align-items-center mb-2">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={() => setPageNumber(1)}
                      disabled={pageNumber === 1}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setPageNumber(pageNumber - 1)}
                      disabled={pageNumber === 1}
                    >
                      {"<"}
                    </Button>
                  </div>
                </Col>

                <Col className="col-md-auto d-none d-md-block">
                  Page{" "}
                  <strong>
                    {pageNumber} of {totalPages}
                  </strong>
                </Col>
                {/* <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={totalPages.length}
                    value={pageNumber}
                    onChange={e => setPageNumber(e.target.value)}
                  />
                </Col> */}

                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={() => setPageNumber(pageNumber + 1)}
                      disabled={isLastPage}
                    >
                      {">"}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setPageNumber(totalPages)}
                      disabled={isLastPage}
                    >
                      {">>"}
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default AirportList
