import React, { useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import FeedbackModel from "./FeedbackModel"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import HorizontalMenu from "common/HorizontalMenu"

const Feedback = ({
  columns,
  data,
  tabFilter,
  setTabFilter,
  fetchData,
  status,
  filterData,
  currentItems,
  setCurrentItems
}) => {
  const [modelPopData, setModelPopData] = useState(null);

  const MENUS = [
    { label: "General", value: status.GENERAL },
    { label: "Hangar owner", value: status.HANGAR_OWNER },
    { label: "Customer", value: status.CUSTOMER },
  ]

  const handleFilterClick = (val) => {
    filterData(val);
    setTabFilter(val);
    fetchData(val);
  };

  return (
    <React.Fragment>
      {modelPopData && (
        <FeedbackModel
          modelData={modelPopData}
          isOpen={!!modelPopData}
          onclick={() => setModelPopData(false)}
          toggle={modelPopData}
        />
      )}
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="card-title">Help Desk</div>
            <div>
              <Link to="/create-article">
                <button className="btn btn-info btn-sm px-3 rounded py-2 text-lg mb-2" style={{ fontSize: "16px" }}>
                  + Add Article
                </button>
              </Link>
            </div>
          </div>

          <TableContainer
            columns={columns}
            data={data}
            header={<HorizontalMenu
              getHangarsData={() => {
                fetchData(tabFilter)
              }}
              menu={MENUS}
              active={tabFilter}
              onClick={handleFilterClick}
            />}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
            currentItems={currentItems}
            setCurrentItems={setCurrentItems}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Feedback.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Feedback)