import { SupabaseClient } from '@supabase/supabase-js';
import { Spinner } from 'common/loading';
import FieldError from 'components/Common/FieldError';
import { TABLES } from 'pages/Utility/constants';
import { convertToInches } from 'pages/Utility/helper';
import { supabase } from 'pages/Utility/supabase';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Container, Button } from 'reactstrap'


const CustomInput = ({ label, value, name, id, placeholder, onChange, steric }) => {
    return (
        <div className="mb-">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{steric}</span>
            </label>
            <input
                style={{
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8"
                }}
                type="text"
                value={value}
                className="form-control"
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
            />

        </div>
    );
};
const Select = ({ heading, name, value, onChange, error, steric }) => {
    return (
        <div className="d-flex flex-column">
            <label className='font-weight-bold mb-0 form-label fs-5' htmlFor="mySelect">{heading} <span className="text-info">{steric}</span></label>

            <select style={{
                border: "none", width: "100%", padding: "13px",
                borderRadius: "8px",
                border: "1px solid #D7D7D7",
                backgroundColor: "#F8F8F8"
            }} onChange={onChange} value={value} name={name} id="mySelect">

                {/* <option value={"Select"}>Select</option> */}
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
            <FieldError text={error} />
        </div>
    )
}



const DEFAULT_FIELDS = {
    name: "",
    wingspan_feet: "", wingspan_inch: "",
    length_feet: "", length_inch: "",
    height_feet: "", height_inch: "",
    weight_empty: "",
    weight_take_off: "",
    weight_landing: "",
    gravity_fueling: false,
    pressure_fueling: false,
    tow_disconnect: false,
    tow_turn_limits: false,
    general_aviation: false
}
const AddPlanePage = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState(DEFAULT_FIELDS);
    const [oldData, setOldData] = useState(null);
    const id = history?.location?.search?.replace("?id=", "")
    const [errors, setErrors] = useState({
        name: "",
        wingspan_feet: "",
        length_feet: "",
        height_feet: "",
    })
    useEffect(() => {
        if (history.location.search) {
            fetchPlane()
        }
    }, [])
    const fetchPlane = async () => {
        try {
            let { data = [], error } = await supabase
                .from(TABLES.PLANE_LIST)
                .select("*")
                .eq("id", id)
                .single()
            if (error) {
                throw error
            } else {
                const [wingspan_feet, wingspan_inch] = data.wingspan.split("'");
                const [length_feet, length_inch] = data.length.split("'");
                const [height_feet, height_inch] = data.height.split("'");
                const old = {
                    ...data,
                    wingspan_feet: wingspan_feet.replace(/\D/g, ""),
                    wingspan_inch: wingspan_inch.replace(/\D/g, ""),
                    length_feet: length_feet.replace(/\D/g, ""),
                    length_inch: length_inch.replace(/\D/g, ""),
                    height_feet: height_feet.replace(/\D/g, ""),
                    height_inch: height_inch.replace(/\D/g, ""),
                }
                setFields(old);
                setOldData(old);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        if (name === "wingspan_feet" || name === "length_feet" || name === "height_feet") {
            setFields({ ...fields, [name]: value.replace(/\D/g, "") })

        }
        else if (name === "wingspan_inch" || name === "length_inch" || name === "height_inch") {
            const num = value.replace(/\D/g, "")
            if (+num <= 11)
                setFields({ ...fields, [name]: num })
        }
        else {
            setFields({ ...fields, [name]: value })
        }

        setErrors({ ...errors, [name]: "" })
    }
    const validation = () => {
        let err = {}

        if (!fields?.name) err.name = "Please enter airplane name"
        if (!fields?.wingspan_feet) err.wingspan_feet = "Please enter wingspan feet"
        if (!fields?.length_feet) err.length_feet = "Please enter length feet"
        if (!fields?.height_feet) err.height_feet = "Please enter height feet"

        if (Object.keys(err).length) {
            setErrors(err)
            return false
        }
        return true
    }

    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;

        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2.includes(key)) {
                return false;
            }

            if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
                if (!deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            } else {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
        }

        return true;
    };

    const handleSubmit = async e => {
        e.preventDefault()

        try {

            if (oldData?.id) {
                if (!deepEqual(oldData, fields)) {
                    // data changed
                } else {
                    // not changed
                    toast.warn("No changes to save");
                    return history.push("/plane-list");
                }
            }

            if (!validation()) return
            setLoading(true)

            const wingspanInInches = convertToInches(`${fields.wingspan_feet}' ${fields.wingspan_inch || 0}''`);
            const lengthInInches = convertToInches(`${fields.length_feet}' ${fields.length_inch || 0}''`);

            // Calculate the area in square inches
            const inchesArea = wingspanInInches * lengthInInches;
            
            const body = {
                ...fields,
                wingspan: `${fields.wingspan_feet}' ${fields.wingspan_inch || 0}''`,
                length: `${fields.length_feet}' ${fields.length_inch || 0}''`,
                height: `${fields.height_feet}' ${fields.height_inch || 0}''`,
                wingspan_feet: undefined, wingspan_inch: undefined,
                length_feet: undefined, length_inch: undefined,
                height_feet: undefined, height_inch: undefined,
                inches_area: inchesArea
            }


            const { error } = await supabase.from(TABLES.PLANE_LIST).upsert({ ...body, id: id || undefined })

            setLoading(false)
            if (error) {
                throw error
            }
            setFields(DEFAULT_FIELDS)
            toast.success(id ? "Update successfully" : "Submitted successfully")
            history.goBack()


        } catch (error) {
            toast.error(error?.message)
            console.log('error: ', error.message);
        }
    }

    return (
        <React.Fragment>
            <div id="titleinput" />
            <div className="page-content">
                <Container fluid>
                    <div className="mx- pb-4 shadow-md">
                        <div className="d-flex justify-content-between ">
                            <h4 className="mx-9 mb-3 text-[#252F40]  text-lg font-semibold">
                                {id ? "Edit Aircraft" : "Add New Aircraft"}
                            </h4>
                        </div>
                        <div style={{ background: "white" }} className="p-4 rounded ">
                            <div className="row">
                                <div className="col-lg-6">
                                    <CustomInput label={"Name"} placeholder={"Enter Airplane Name"} name={"name"} onChange={handleChange} steric={"*"} value={fields?.name} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.name} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex gap-1 align-items-center ">
                                        <div className='col-lg-6'>
                                            <CustomInput label={"Wingspan"} placeholder={"Enter wingspan in feet"} name={"wingspan_feet"} steric={"*"} onChange={handleChange} value={fields?.wingspan_feet} />
                                        </div>
                                        <div className='col-lg-6 mt-1'>
                                            <CustomInput placeholder={"Enter wingspan in inches"} name={"wingspan_inch"} onChange={handleChange} value={fields?.wingspan_inch} />
                                        </div>
                                    </div>
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.wingspan_feet} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <div className="d-flex gap-1">
                                        <div className='col-lg-6'>
                                            <CustomInput label={"Length"} placeholder={"Enter Length in feet"} name={"length_feet"} steric={"*"} onChange={handleChange} value={fields?.length_feet} />

                                        </div>
                                        <div className='col-lg-6 mt-1'>
                                            <CustomInput placeholder={"Enter Length in inches"} name={"length_inch"} onChange={handleChange} value={fields?.length_inch} />

                                        </div>
                                    </div>
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.length_feet} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex gap-1">
                                        <div className='col-lg-6'>
                                            <CustomInput label={"Height"} placeholder={"Enter Height in feet"} name={"height_feet"} steric={"*"} value={fields?.height_feet} onChange={handleChange} />
                                        </div>
                                        <div className='col-lg-6 mt-1'>
                                            <CustomInput placeholder={"Enter Height in inches"} name={"height_inch"} value={fields?.height_inch} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.height_feet} />
                                    </div>

                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <CustomInput label={"Weight empty"} placeholder={"Enter weight empty"} name={"weight_empty"} value={fields?.weight_empty} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CustomInput label={"Weight take off"} placeholder={"Weight take off"} name={"weight_take_off"} value={fields?.weight_take_off} onChange={handleChange} />

                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">
                                    <CustomInput label={"Weight landing"} placeholder={"Enter weight landing"} name={"weight_landing"} value={fields?.weight_landing} onChange={handleChange} />


                                </div>
                                <div className='col-lg-6'>
                                    <Select heading={"Gravity fueling"} name="gravity_fueling"
                                        value={fields.gravity_fueling} onChange={handleChange} />
                                </div>

                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">

                                    <Select heading={"Pressure fueling"} name="pressure_fueling"
                                        value={fields.pressure_fueling} onChange={handleChange} />

                                </div>
                                <div className='col-lg-6'>
                                    <Select heading={"Tow disconnect"} name="tow_disconnect"
                                        value={fields.tow_disconnect} onChange={handleChange} />
                                </div>

                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">

                                    <Select steric={"*"} heading={"Tow turn limits"} name="tow_turn_limits"
                                        value={fields.tow_turn_limits} onChange={handleChange} />

                                </div>
                                <div className='col-lg-6'>
                                    <Select heading={"General aviation"} name="general_aviation"
                                        value={fields.general_aviation} onChange={handleChange} />
                                </div>

                            </div>
                            <div className="d-flex justify-content-end my-2 gap-2 mt-5">
                                <Button
                                    type="button"

                                    className="bg-secondary btn-rounded-3 px-4 py-2"
                                    onClick={() => history.goBack()}

                                >
                                    {"Cancel"}
                                </Button>
                                <Button
                                    type="button"
                                    color="info"
                                    className="btn-md btn-rounded-3 px-4 py-2"
                                    onClick={handleSubmit}

                                >
                                    {loading ? <Spinner /> : !id ? "Add" : "Update"}
                                </Button>
                            </div>

                        </div>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddPlanePage

