import { toast } from "react-toastify";
import { ACCOUNT_TYPES, IMAGE_BASE_URL, STATUS, TABLES } from "./constants";
import { supabase } from "./supabase";

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
const stripe = require("stripe")(KEY)

export const createPayoutMethod = async (account_id, obj, type) => {
    try {

        if (type !== ACCOUNT_TYPES.BANK) {

            const token = await stripe.tokens.create({
                card: obj,
            });
            console.log('token: ', token);
            const card = await stripe.accounts.createExternalAccount(
                account_id,
                { external_account: token.id }
            );
            console.log('card: ', card);
            return { success: true, data: card }

        } else {

            const bankAccount = await stripe.accounts.createExternalAccount(
                account_id,
                {
                    external_account: obj,
                }
            );
            console.log('bankAccount: ', bankAccount);
            return { success: true, data: bankAccount }

        }
    } catch (error) {
        console.error('Error adding payment method:', error);
        return { error: `Error adding payment method error : ${error.message}` }
    }
}

export const deleteStripeAccount = async (account_id) => {
    if (!account_id) return
    try {
        const deleted = await stripe.accounts.del(
            account_id
        );
        console.log('deleted: ', deleted);
    } catch (error) {
        console.log(error, "Error while deleting account")
        return ({ success: false, message: error.message })
    }
}


export const createStripePerson = async (account_id, person_data, rowID) => {


    try {
        const person = await stripe.accounts.createPerson(
            account_id,
            { ...person_data }
            // { first_name: 'Jane', last_name: 'Diaz' }
        );
        console.log('person: ', person);
        if (person) {
            const { data, error } = await supabase
                .from(TABLES.CONNECT_REQUESTS)
                .update({ "personId": person.id })
                .eq("id", rowID)
            return { success: true, person: person.id }
        }

    } catch (err) {
        console.log('err: ', err);
        return { success: false, error: err.message }
    }
}
export function capitalize(str = "") {
    return str.charAt(0).toUpperCase() + str.slice(1, str.length);
}



export function getImageUrl(url = "") {

    try {

        return url?.includes("https://") ? url : IMAGE_BASE_URL + url

    } catch (error) {
        console.log('error: ', error.message);
        return ""
    }
}


export async function downloadFile(file_link, name) {
    try {

        let res = await fetch(file_link)
        if (res.ok) {
            const blob = await res.blob()
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            link.download = name || file_link.split("/").reverse()[0]
            link.click()
        }
        else
            throw { message: `Operation end with status: ${res.status} - ${res.statusText}` }

    } catch (error) {
        toast.dismiss()
        toast.error(error.message);

    }
}