import React from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import { Button, Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import {
  OrderId,
  Title,
  Total,
  Date,
  StartDate,
  RantelType,
  EndDate,
  Owner,
  Financial,
} from "../Dashboard-Orders/LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { TABLES } from "pages/Utility/constants"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

const Orders = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [status, setStatus] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [currentItems, setCurrentItems] = useState([]);
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Orders | HangarDirect"
  const columns = [
    {
      Header: "#",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        let ids = {
          id: cellProps.row.original.id,
          user_id: cellProps.row.original.user_id,
        }
        return (
          <input
            // onChange={handleCheckChange(ids)}
            type="checkbox"
            className="form-check-input"
          />
        )
      },
    },

    {
      Header: "Id",
      accessor: "Id",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />
      },
    },
    {
      Header: "Customer",
      accessor: "full_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Title {...cellProps} />
      },
    },
    {
      Header: "Order Date",
      accessor: "created_at",
      disableFilters: true,
      Cell: cellProps => {
        return <Date {...cellProps} />
      },
    },
    {
      Header: "Rental Type",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <RantelType {...cellProps} />
      },
    },
    {
      Header: "Status",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Owner orders={orders} status={status} {...cellProps} />
      },
    },

    {
      Header: "Start Date",
      accessor: "checkIn",
      Cell: cellProps => {
        return <StartDate {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "End Date",
      accessor: "checkOut",
      Cell: cellProps => {
        return <EndDate {...cellProps} />
      },
      disableFilters: true,
    },

    {
      Header: "Financial Status",
      accessor: "Financial Status",
      Cell: cellProps => {
        return <Financial {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "Total",
      accessor: "total_Amount",
      Cell: cellProps => {
        return <Total {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "View Details",
      disableFilters: true,
      accessor: row => {
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              history.push(`/invoices-detail/${row.id}`)
            }}
          >
            View Details
          </Button>
        )
      },
    },
  ]
  const getOrdersData = async () => {
    try {
      let { data, error } = await supabase
        .from(TABLES.TRANSACTIONS)
        .select("*,user_id(*)")
        .order("id", { ascending: true })

      if (error) {
        setLoading(false)
        throw error
      }
      if (data?.length) {
        const updatedData = data.map(item => {
          const user_data = { ...item?.user_id, ...item?.user_id?.meta }
          return {
            ...item,
            user_id: user_data,
            full_name: user_data?.first_name + " " + user_data?.last_name,
          }
        })
        setOrders(updatedData);
        setCurrentItems(updatedData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
      console.log("error: ", error.message)
    }
  }
  const fetchStatuses = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase.from(TABLES.STATUS).select("*")

      if (error) {
        setLoading(false)
        throw error
      } else if (data) {
        setStatus(data)
      }
    } catch (error) {
      console.log("error: ", error.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    getOrdersData()
    fetchStatuses()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          {!!loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Requests
                  columns={columns}
                  orders={orders}
                  modelPopData={modelPopData}
                  selection={selectedRows}
                  getOrdersData={getOrdersData}
                  setAlert={setAlert}
                  className="!mb-0"
                  loading={loading}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Orders
