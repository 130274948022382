import React from 'react'
import { useState } from 'react'

const FieldsInput = ({ val, label, onchange, name_field, type }) => {
    const [isUpdate, setIsUpdate] = useState(false)
    return (
        <>
            <div className="col-lg-2" style={{ fontWeight: "600" }}>
                {label}
            </div>
            {!isUpdate ?
                <div
                    // onClick={() => setIsUpdate(true)}
                    className="col-lg-4">
                    {val || "Not entered"}
                </div >
                :
                <div className='col-lg-4 d-flex gap-2'>
                    <input name={name_field} className='form-control w-75' onChange={onchange} type={type || "text"} value={val} />
                    <svg
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: "20px" }}
                        onClick={() => setIsUpdate(false)}
                    >
                        <g clipPath="url(#clip0)">
                            <path
                                d="M42 20V39C42 40.6569 40.6569 42 39 42H9C7.34315 42 6 40.6569 6 39V9C6 7.34315 7.34315 6 9 6H30"
                                stroke="#000000"
                                strokeWidth={4}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16 20L26 28L41 7"
                                stroke="#000000"
                                strokeWidth={4}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width={48} height={48} fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            }
        </>
    )
}

export default FieldsInput
