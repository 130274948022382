import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import { connect, useDispatch } from "react-redux"
import { getImageUrl } from "pages/Utility/functions"

const WelcomeComp = ({ sum, ProfileStore }) => {
  const dispatch = useDispatch()
  const [profileData, setProfileData] = useState({})

  const { error, success } = ProfileStore

  useEffect(() => {
    const authUser = localStorage.getItem("authUser")
    if (authUser) {
      const parsedUser = JSON.parse(authUser)
      setProfileData(parsedUser)
    } else {
      setProfileData(null)
    }
  }, [dispatch, success])

  const firstName = profileData?.user_metadata?.first_name || "Guest"
  const lastName = profileData?.user_metadata?.last_name || ""
  const profileImage = getImageUrl(profileData?.user_metadata?.img_url) || avatar1
  const userAudience = profileData?.aud || "User"

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Hangar Direct Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="5">
              <div className="avatar-md h-5 profile-user-wid">
                <img
                  src={profileImage}
                  alt="Profile"
                  style={{ height: "70px", width: "100px", objectFit: "cover" }}
                  className="img-thumbnail circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {firstName} {lastName}
              </h5>
              <p className="mb-0">{userAudience}</p>
            </Col>
            <Col sm="6">
              <div className="pt-4">
                <Row>
                  <Col>
                    <h5 className="font-size-15">{"$" + sum.toFixed(2)}</h5>
                    <p className="mb-0">Revenue</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(state => ({ ProfileStore: state.Profile }))(WelcomeComp)
