import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { downloadFile, getImageUrl } from "pages/Utility/functions"
import { getReadFileAccessS3 } from "helpers/file-uploader-s3"
import SelectTag from "components/Common/SelectTag"
import Swal from "sweetalert2"

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const TailNumber = cell => {
  return cell.row.original?.tail_number || ""
}
const Name = cell => {
  return cell.row.original?.name || ""
}
const Date = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const Status = cell => {
  return (
    <Badge
      style={{
        textTransform: "capitalize",
        fontSize: "12px",
        padding: "5px 10px",
      }}
      className={"badge-soft-warning "}
    >
      In Review
    </Badge>
  )
}
const Insurance = ({ row, }) => {

  const actionHandler = async (preview) => {
    let fetchPath = getImageUrl(row?.insurance)
    if (fetchPath.includes("amazonaws.com/")) {
      fetchPath = await getReadFileAccessS3(fetchPath)
    }
    preview ? window.open(fetchPath) : downloadFile(fetchPath, "insurance.pdf")
  }


  return (
    <div style={{ marginLeft: "8px" }} className="d-flex gap-2">
      <svg
        onClick={() => actionHandler(false)}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-cloud-download"
        viewBox="0 0 16 16"
      >
        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
      </svg>
      <svg
        onClick={() => actionHandler(true)}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi  bi-eye-fill"
        viewBox="0 0 16 16"
      >
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
      </svg>
    </div>
  )
}

const Action = (cell) => {
  const [loading, setLoading] = useState(false);
  const id = cell.row?.original.id;

  const statusHandler = async (value) => {
    setLoading(true);
    try {
      const { data: update, error } = await supabase
        .from(TABLES.USER_PLANES)
        .update({ is_approved: value })
        .eq("id", id);
      if (error) {
        setLoading(false);
        throw error;
      }
      cell?.refresh && cell.refresh();
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleApprove = () => {
    Swal.fire({
      title: "Action",
      text: "Are you sure you want to approve this insurance",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "Approve",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        statusHandler(STATUS.PLANE_INSURANCE_APPROVED);
      }
    });
  };

  const handleReject = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to reject this insurance",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: "Reject",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        statusHandler(STATUS.PLANE_INSURANCE_REJECTED);
      }
    });
  };

  return (
    <div className="d-flex gap-1 marginButton">
      <div className="d-flex cursor-pointer" onClick={handleApprove}>
        <img style={{ height: "30px" }} src="/images/approve.png" alt="Approve" />
      </div>
      <div className="d-flex cursor-pointer" onClick={handleReject}>
        <img style={{ height: "30px", filter: "hue-rotate(150deg)" }} src="/images/notapprove.png" alt="Reject" />
      </div>
    </div>
  );
};

export { CheckBox, OrderId, Name, TailNumber, Date, Status, Insurance, Action }
