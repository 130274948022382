import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { STATUS } from "pages/Utility/constants"
const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY

const stripe = require("stripe")(KEY)

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Name = cell => {
  return cell?.row?.original?.account_data?.representative?.first_name ? cell?.row?.original?.account_data?.representative?.first_name + " " + cell?.row?.original?.account_data?.representative?.last_name : cell?.row?.original?.account_data?.individual?.first_name + " " + cell?.row?.original?.account_data?.individual?.last_name
}
const DateCol = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const City = cell => {
  return cell?.row?.original?.account_data?.representative?.address?.city || cell?.row?.original?.account_data?.individual?.address?.city
}
const BusinessType = cell => {
  return <span style={{ textTransform: "capitalize" }}>{cell?.row?.original?.account_type || ''}</span>
}

const Status = cell => {
  return (
    <Badge
      style={{ textTransform: "capitalize" }}
      className={
        "font-size-11 badge-soft-" +
        (cell?.row?.original.approved === "inReview" ? "warning" : "warning")
      }
    >
      {"in Review"}
    </Badge>
  )
}
const Stripe_Status = cell => {
  return (
    <Badge
      color={`${+cell?.row?.original.status === STATUS.CONNECT_ACCOUNT_APPROVED ? "success" : +cell?.row?.original.status === STATUS.CONNECT_ACCOUNT_REJECTED ? "danger" : "warning"}`}
      style={{ textTransform: "capitalize" }}
      className={"font-size-11"}
    >
      {+cell?.row?.original.status === STATUS.CONNECT_ACCOUNT_APPROVED ? "Approved" : +cell?.row?.original.status === STATUS.CONNECT_ACCOUNT_REJECTED ? "Rejected" : "In Review"}
    </Badge>
  )
}
const PaymentMethod = cell => {
  return (
    <div style={{ textTransform: "capitalize" }}>
      {cell?.row?.original.paymentMethod}
    </div>
  )
}
export { CheckBox, OrderId, Name, DateCol, Stripe_Status, City, Status, PaymentMethod, BusinessType }
