import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Type = cell => {
  return <span style={{ textTransform: "capitalize" }}>{cell.row.original?.type || ''}</span>
}
const DateCol = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const AccountNo = cell => {
  let row = cell?.row?.original
  return <span style={{ textTransform: "capitalize" }}>{row?.data?.number || row?.data?.account_number || row?.data?.last4}</span>
}


const Status = cell => {
  return (
    <Badge
      style={{ textTransform: "capitalize" }}
      className={
        "font-size-11 badge-soft-" +
        (cell?.row?.original.approved === "inReview" ? "warning" : "warning")
      }
    >
      {"in Review"}
    </Badge>
  )
}

export { CheckBox, OrderId, Type, DateCol, Status, AccountNo }
