import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import { fundsTransfer } from "pages/Utility/stripe_functions"
import SelectTag from "components/Common/SelectTag"
import { toast } from "react-toastify"

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

// const UserId = cell => {
//   return cell.cell.row.original.user_id.id ? cell.cell.row.original.user_id.id : ""
// }
const Name = cell => {
  return cell.cell.row.original.user_id.first_name + " " + cell.cell.row.original.user_id.last_name
    ? cell.cell.row.original.user_id.first_name + " " + cell.cell.row.original.user_id.last_name
    : ""
}
const Email = cell => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(cell.cell.row.original.user_id.email);
      toast.success('Email copied to clipboard!');
    } catch (err) {
      toast.error('Failed to copy email!');
    }
  };

  return (
    <div
      className="cursor-pointer"
      
    >
      {cell.cell.row.original.user_id.email ? cell.cell.row.original.user_id.email : ""}
      <i style={{ marginLeft: "10px" }} onClick={copyToClipboard} className="fa fa-regular xs fa-copy" />
    </div>
  )
}



export { CheckBox, OrderId, Name, Email }
