import moment from "moment"
import { STATUS } from "pages/Utility/constants"
import React from "react"

const Detail = ({ invoiceDetail, refundedData, subscriptionData }) => {
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }
  return (
    <div>
      <div className="d-flex  gap-3">
        <div className="bg-white px-1 rounded mt-4 w-100 ">
          <div className="container pt-3">
            <Heading text="Order Details" customClass="m-0 px-0 pt-0" />

            <div className="border-bottom py-2">
              <div className="d-flex justify-content-between  px-2">
                {/* ist row */}
                <div style={{ fontWeight: "600" }} className="">
                  Customer email:
                </div>
                <div style={{ fontWeight: "500" }} className="">
                  {invoiceDetail?.email}
                </div>
              </div>
            </div>
          </div>
          {/* 2nd row */}
          <div className="container py-2 ">
            <div className="border-bottom py-2">
              <div className="d-flex justify-content-between  px-2">
                {/* ist row */}
                <div style={{ fontWeight: "600" }} className="">
                  Order by:
                </div>
                <div
                  className="bg-success  bg-opacity-10 px-1 rounded-2"
                  style={{
                    color: "#24902F",
                    fontWeight: "500",
                  }}
                >
                  Customer
                </div>
              </div>
            </div>
          </div>
          {/* 3rd row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Platform:
                </div>
                <div style={{ fontWeight: "500" }}>web</div>
              </div>
            </div>
          </div>
          {/* 4rth row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                {/* ist row */}
                <div style={{ fontWeight: "600" }} className="">
                  Payment method:
                </div>
                <div style={{ fontWeight: "500" }}>
                  {invoiceDetail?.paymentMethod}
                </div>
              </div>
            </div>
          </div>
          {/* 5th row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Financial status:
                </div>
                <div
                  className="bg-success text-capitalize bg-opacity-10 px-1 rounded-2"
                  style={{
                    color: "#24902F",
                    fontWeight: "500",

                  }}
                >
                  {invoiceDetail?.paymentStatus}
                </div>
              </div>
            </div>
          </div>



          {/* 6th row */}
          <div className="container pb-1">
            <div className="">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Order date:
                </div>
                <div
                  style={{
                    fontWeight: "500",
                  }}
                >
                  {moment(invoiceDetail?.created_at).format("DD MMM YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white px-1 rounded mt-4 w-100 ">
          <div className="container pt-3">
            <Heading text="Payment details" customClass="m-0 px-0 pt-0" />

            <div className="border-bottom py-2">
              <div className="d-flex justify-content-between  px-2">
                {/* ist row */}
                <div style={{ fontWeight: "600" }} className="">
                  Total item price:
                </div>
                <div style={{ fontWeight: "500" }} className="">
                  $
                  {invoiceDetail?.rentalType === 'monthly' ? parseFloat(invoiceDetail?.hangarData?.price_per_month || 0).toFixed(2) : (invoiceDetail?.hangarData?.price_per_night && invoiceDetail?.days) && parseFloat(+invoiceDetail?.hangarData?.price_per_night * +invoiceDetail?.days).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* 2nd row */}
          <div className="container py-2 ">
            <div className="border-bottom py-2">
              <div className="d-flex justify-content-between  px-2">
                {/* ist row */}
                <div style={{ fontWeight: "600" }} className="">
                  Discount:
                </div>
                <div style={{ fontWeight: "500" }}>{`$${0?.toFixed(2)}`}</div>
              </div>
            </div>
          </div>
          {/* 3rd row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Promotion:
                </div>
                <div style={{ fontWeight: "500" }}>{`$${0?.toFixed(2)}`}</div>
              </div>
            </div>
          </div>
          {/* 4rth row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                {/* ist row */}
                <div style={{ fontWeight: "600" }} className="">
                  Subtotal:
                </div>
                <div style={{ fontWeight: "500" }}>
                  $
                  {invoiceDetail?.rentalType === 'monthly' ? parseFloat(invoiceDetail?.hangarData?.price_per_month || 0).toFixed(2) : (invoiceDetail?.hangarData?.price_per_night &&
                    invoiceDetail?.days) && parseFloat(+invoiceDetail?.hangarData?.price_per_night * +invoiceDetail?.days).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* 5th row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Total tax:
                </div>
                <div style={{ fontWeight: "500" }}>${invoiceDetail?.tax && parseFloat(invoiceDetail?.tax).toFixed(2)}</div>
              </div>
            </div>
          </div>
          {/* 6th row */}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Service fee:
                </div>
                <div style={{ fontWeight: "500" }}>
                  ${invoiceDetail?.serviceFee && parseFloat(invoiceDetail?.serviceFee).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* 7th row */}
          {refundedData && <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Refunded Amount:
                </div>
                <div
                  style={{
                    fontWeight: "500",
                  }}
                >
                  ${invoiceDetail?.total_Amount && parseFloat(+refundedData?.refund_amount).toFixed(2)}
                </div>
              </div>
            </div>
          </div>}
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Grand total:
                </div>
                <div
                  style={{
                    fontWeight: "500",
                  }}
                >
                  ${invoiceDetail?.total_Amount && parseFloat(+invoiceDetail?.total_Amount + (+invoiceDetail?.credits || 0)).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className="container pb-3 ">
            <div className=" border-bottom pb-2">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Credits:
                </div>
                <div
                  style={{
                    fontWeight: "500",
                    color: 'red'
                  }}
                >
                  ${invoiceDetail?.credits ? parseFloat(+invoiceDetail?.credits).toFixed(2) : 0}
                </div>
              </div>
            </div>
          </div>
          {/* 8th row */}
          <div className="container pb-3">
            <div className="">
              <div className="d-flex justify-content-between  px-2">
                <div style={{ fontWeight: "600" }} className="">
                  Paid by customer:
                </div>
                <div
                  style={{
                    fontWeight: "500",
                  }}
                >
                  ${invoiceDetail?.total_Amount && parseFloat(invoiceDetail?.total_Amount).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {subscriptionData.length > 0 && (
        <div className="d-flex  gap-3">
          <div className="bg-white px-1 rounded mt-4 w-100 ">
            <div className="container pt-3">
              <Heading text="Subscription Timeline" customClass="m-0 px-0 pt-0" />
              <div className={` pb-2 bg-white rounded`}>
                {subscriptionData.map((subscription, index) => (
                  <div key={index} className="d-flex border-bottom py-2 flex-column ">
                    <div className="d-flex justify-content-between">
                      <div><strong>Start Date:</strong> {moment(subscription.start_date).format('MMM DD, YYYY')}</div>
                      <div><strong>End Date:</strong> {moment(subscription.end_date).format('MMM DD, YYYY')}</div>
                      <div><strong>Amount:</strong> ${subscription.amount}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Detail
