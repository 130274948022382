import {
  GET_HANGAR_STATUS,
  GET_HANGAR_STATUS_FAIL,
  GET_HANGAR_STATUS_SUCCESS,
} from "./actionTypes"



export const getStatus = () => ({
  type: GET_HANGAR_STATUS,

})

export const getStatusSuccess = payload => ({
  type: GET_HANGAR_STATUS_SUCCESS,
  status: payload,
})

export const getStatusFail = error => ({
  type: GET_HANGAR_STATUS_FAIL,
  statusMessage: error,
})
