import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"
import DefaultLoading from "common/loading"

const Requests = ({
  columns,
  hangars,
  modelPopData,
  closeModal,
  selection,
  setLoading,
  getHangarsData,
  selectedRowsUserIDs,
  setAlert,
  setTabIndex,
  currentItems, setCurrentItems,
  tabIndex,
  ...props
}) => {
  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <Card>


        <CardBody>

          <div className="mb-4 h4 card-title">Hangar requests</div>

          <TableContainer
            columns={columns}
            data={hangars}
            isGlobalFilter={false}
            isAddOptions={false}
            selection={selection}
            setLoading={setLoading}
            getHangarsData={getHangarsData}
            selectedRowsUserIDs={selectedRowsUserIDs}
            setAlert={setAlert}
            currentItems={currentItems}
            setCurrentItems={setCurrentItems}
            {...props}
          />

        </CardBody>

      </Card>
    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)

