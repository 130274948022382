import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Id, Date, } from "./feedbackCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Feedback from "./feedback"
import { toast } from "react-toastify"

const COPIER = ({ row, copyToClipboard }) => {
  const [copy, setCopy] = useState(false)
  const copyHandler = () => {
    setCopy(true)
    toast.success("Email copied to clipboard!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  }

  return (
    <div key={row.id}>
      {!copy ? <div onClick={copyHandler} >
        <i className="fa fa-regular xs fa-copy" />
      </div> :
        <div>
          <i className="fa fa-check" aria-hidden='true' />
        </div>
      }
    </div>
  )
}

const HangarSubscribers = () => {
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);


  document.title = "Subscribers | HangarDirect"

  const handleModelData = data => {
    setModelPopData(data)
  }
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <input type="checkbox" className="form-check-input" />
      //   },
      // },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      // {
      //   Header: "Name",
      //   accessor: "Name",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <Name {...cellProps} />
      //   },
      // },

      {
        Header: "Email",
        disableFilters: true,
        filterable: false,
        accessor: row => {

          return (
            <div className="d-flex align-items-center gap-2">
              <div>{row?.email}</div>
              {/* {!copy ? <div onClick={copyHandler} >
                <i className="fa fa-regular xs fa-copy" />
              </div> :
                <div>
                  <i className="fa fa-check" aria-hidden='true' />
                </div>
              } */}
              <COPIER row={row} copyToClipboard={copyToClipboard} />
            </div>
          )
        },
      },
      {
        Header: "Created date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
    ],
    []
  )
  const copyToClipboard = email => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        // toast("Tariff code copied")
        console.log("copied")
        document.execCommand("copy")
      })
      .catch(error => {
        console.log("Not copied")
      })
  }

  const getHangarsData = async () => {
    setLoading(true)
    // let { data: subscribers, error } = await supabase
    //   .from("subscribers")
    //   .select("*")
    try {
      let { data: subscribers, error } = await supabase
        .from(TABLES.SUBSCRIBERS)
        .select("*")
        .order("id", { ascending: true })
      if (error) {
        throw error
      }

      setHangars(subscribers);
      setCurrentItems(subscribers);
    } catch (error) { }
    setLoading(false)
  }
  useEffect(() => {
    getHangarsData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Feedback
                  columns={columns}
                  hangars={hangars}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}

                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HangarSubscribers
