import { supabase } from "pages/Utility/supabase"
import React from "react"
import { Col, Container, Row } from "reactstrap"
import { ACCOUNT_TYPES, STATUS, TABLES } from "pages/Utility/constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useState } from "react"
import { useEffect } from "react"
import DefaultLoading, { Spinner } from "common/loading"
import Alerts from "common/Alerts/Alert"
import FieldsInput from "common/Fields_Input"
import { toast } from "react-toastify"


const ConnectAccountDetails = props => {
  document.title = "Connect Request Detail | Hangar Direct"
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false)

  const [data, setData] = useState(null)
  const [paymentData, setPaymentData] = useState(null)

  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params?.id) {
      fetchConnectData(params.id)
    }
  }, [history])

  const fetchConnectData = async id => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
        .from(TABLES.CONNECT_REQUESTS)
        .select("*,profile(*)")
        .eq("id", id)

      const { data: payment_Method, error: errorPaymentMethod } = await supabase
        .from(TABLES.PAYMENT_METHODS)
        .select("*")
        .eq("user_id", data[0]?.profile_id)
      setPaymentData(payment_Method[0])

      let newData = { ...data[0] }
      if (payment_Method?.length) {
        newData.payment_data = payment_Method[0]?.data
        newData.paymentMethod = payment_Method[0]?.type
      }

      if (error || errorPaymentMethod) {
        throw error || errorPaymentMethod
      } else if (data) {
        setData(data[0])
        // handleUpdateData(newData)
        // setAccountData(data)
        // setOldData(data[0])
        setLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      console.log(error.message)
      setLoading(false)
    }
  }
  const statusHandler = async value => {

    try {
      setAlert(DEFAULT_ALERT_VALUES)
      if (value === STATUS.CONNECT_ACCOUNT_APPROVED) {

        const { data, error } = await supabase.from(TABLES.CONNECT_REQUESTS).update({ admin_approval: STATUS.CONNECT_ADMIN_APPROVAL_APPROVED }).eq("id", params.id).select();
        if (error) throw error;
        if (data?.length) {
          history.push("/connect-request")
        }
      } else if (value === STATUS.CONNECT_ACCOUNT_REJECTED) {

        const { data, error } = await supabase
          .from(TABLES.CONNECT_REQUESTS)
          .update({ admin_approval: STATUS.CONNECT_ADMIN_APPROVAL_REJECTED })
          .eq("id", params.id)
          .select()
        if (error) {
          throw error
        } else {
          history.push("/connect-request")
        }
      }
    } catch (error) {
      console.log("error: ", error.message);
      setAlert({
        ...alert,
        heading: "Invalid!",
        message: error.message,
        type: "warn",
      })
    }
    setBtnLoading(false)
    setRejectBtnLoading(false)
  }
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }

  return (
    <div className="page-content">
      {loading ? (
        <DefaultLoading />
      ) : (
        <Container fluid>
          {alert?.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          <Heading text="Request Details" customClass="px-0" />
          <Row>
            <Col xl="9">
              <>
                {data?.account_type === "individual" && (
                  <div className="bg-white px-2 rounded">
                    <div className="container pt-3">
                      <Heading
                        text="Account Info"
                        customClass="m-0 px-0 pt-0"
                      />
                      <div className="border-top border-bottom py-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"First Name:"}
                            val={data?.account_data?.individual?.first_name}
                            name_field={"data.account_data.individual.first_name"}
                          // onchange={handleChange}
                          />
                          <FieldsInput
                            label={"Last Name:"}
                            val={data?.account_data?.individual?.last_name}
                            name_field={"data.account_data.individual.last_name"}
                          // onchange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 2nd row */}
                    <div className="container py-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"Email:"}
                            val={data?.account_data?.individual?.email}
                            name_field={"data.account_data.individual.email"}
                          // onchange={handleChange}
                          />
                          <FieldsInput
                            label={"Phone:"}
                            val={data?.account_data?.individual?.phone}
                            name_field={"data.account_data.individual.phone"}
                          // onchange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 3rd row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"Business Name:"}
                            val={data?.account_data?.company?.name}
                            name_field={"data.account_data.company.name"}
                          // onchange={handleChange}
                          />
                          <FieldsInput
                            label={"Business URL:"}
                            val={data?.account_data?.business_profile?.url}
                            name_field={"data.account_data.business_profile.url"}
                          // onchange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 4rth row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"SSN Last 4:"}
                            val={data?.account_data?.individual?.ssn_last_4}
                            name_field={"data.account_data.individual.ssn_last_4"}
                          // onchange={handleChange}
                          />
                          <FieldsInput
                            label={"Address:"}
                            val={data?.account_data?.individual?.address?.line1}
                            name_field={"data.account_data.individual.address.line1"}
                          // onchange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 5th row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"Date of birth:"}
                            val={data?.account_data?.individual?.dob?.day + '-' + data?.account_data?.individual?.dob?.month + '-' + data?.account_data?.individual?.dob?.year}
                            name_field={"dob"}
                          // onchange={handleChange}
                          />
                          <FieldsInput
                            label={"Business City:"}
                            val={data?.account_data?.individual?.address?.city}
                            name_field={"data.account_data.individual.address.city"}
                          // onchange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 6th row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"Country:"}
                            val={"United states"}
                            name_field={"country"}

                          //  onchange={inputFieldsChange}
                          />
                          <FieldsInput
                            label={"Business State:"}
                            val={data?.account_data?.individual?.address?.state}
                            name_field={"data.account_data.individual.address.state"}
                          // onchange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 7th row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          <FieldsInput
                            label={"Business Zip:"}
                            val={
                              data?.account_data?.individual?.address?.postal_code
                            }
                            name_field={"data.account_data.individual.address.postal_code"}
                          // onchange={handleChange}
                          />
                          <FieldsInput
                            label={"Tax TIN:"}
                            val={
                              data?.account_data?.company?.tax_id
                            }
                            name_field={"data.account_data.company.tax_id"}
                          // onchange={handleChange}
                          />
                          {/* <FieldsInput label={"Status:"} val={accountData?.status} name_field={"status"}
                      // onchange={inputFieldsChange}
                      /> */}
                        </div>
                      </div>
                    </div>
                    {/* 8th row */}
                    <div className="container pb-3 ">
                      <div className=" border-bottom pb-2">
                        <div className="row px-2">
                          {/* <FieldsInput label={"Ip Address:"} val={accountData?.account_data?.tos_acceptance?.ip} name_field={"ip_address"}
                      // onchange={inputFieldsChange}
                      /> */}
                          {/* <FieldsInput label={"Status:"} val={accountData?.status} name_field={"status"} onchange={inputFieldsChange} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
              {data?.account_type !== "individual" &&
                (
                  <div className="bg-white px-2 my-4 pb-3 rounded">
                    <Heading text="Company Info" customClass="m-0 px-1" />
                    <div className="text-black container inline-block">
                      <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
                        <>
                          {/* 1th row */}

                          {(data?.account_data?.company?.name ||
                            data?.account_data?.company?.tax_id) && (
                              <div className="container pb-3 ">
                                <div className=" border-bottom pb-2">
                                  <div className="row px-2">
                                    {data?.account_data?.company?.name && (
                                      <FieldsInput
                                        label={"Name:"}
                                        val={data?.account_data?.company?.name}
                                        name_field={"data.account_data.company.name"}
                                      // onchange={handleChange}
                                      />
                                    )}
                                    {data?.account_data?.company?.tax_id && (
                                      <FieldsInput
                                        label={"Tax Id:"}
                                        val={data?.account_data?.company?.tax_id}
                                        name_field={"data.account_data.company.tax_id"}
                                      // onchange={handleChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* 2th row */}

                          {(data?.account_data?.company?.phone ||
                            data?.account_data?.company?.address?.line1) && (
                              <div className="container pb-3 ">
                                <div className=" border-bottom pb-2">
                                  <div className="row px-2">
                                    {data?.account_data?.company?.phone && (
                                      <FieldsInput
                                        label={"Phone:"}
                                        val={data?.account_data?.company?.phone}
                                        name_field={"data.account_data.company.phone"}
                                      // onchange={handleChange}
                                      />
                                    )}
                                    {data?.account_data?.company?.address?.line1 && (
                                      <FieldsInput
                                        label={"Address:"}
                                        val={data?.account_data?.company?.address?.line1}
                                        name_field={"data.account_data.company.address.line1"}
                                      // onchange={handleChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* 3rd row */}

                          {(data?.account_data?.company?.address?.city ||
                            data?.account_data?.company?.address?.state) && (
                              <div className="container pb-3 ">
                                <div className=" border-bottom pb-2">
                                  <div className="row px-2">
                                    <FieldsInput
                                      label={"City:"}
                                      val={data?.account_data?.company?.address?.city}
                                      name_field={"data.account_data.company.address.city"}
                                    // onchange={handleChange}
                                    />

                                    <FieldsInput
                                      label={"State:"}
                                      val={data?.account_data?.company?.address?.state}
                                      name_field={"data.account_data.company.address.state"}
                                    // onchange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* 4th row */}

                          {data?.account_data?.company?.address?.postal_code && (
                            <div className="container pb-3 ">
                              <div className=" border-bottom pb-2">
                                <div className="row px-2">
                                  {data?.account_data?.company?.address?.postal_code && (
                                    <FieldsInput
                                      label={"Postal Code:"}
                                      val={data?.account_data?.company?.address?.postal_code}
                                      name_field={"data.account_data.company.address.postal_code"}
                                    // onchange={handleChange}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                )}
              {data?.account_data?.business_type !== "individual" &&
                (data?.account_data?.individual?.first_name ||
                  data?.account_data?.individual?.last_name) && (
                  <div className="bg-white px-2 my-4 pb-3 rounded">
                    <Heading
                      text="Representative Info"
                      customClass="m-0 px-1"
                    />

                    <div className="text-black container inline-block">
                      <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
                        <>
                          {/* 1th row representative */}

                          {(data?.account_data?.individual?.first_name ||
                            data?.account_data?.individual?.last_name) && (
                              <div className="container pb-3 ">
                                <div className=" border-bottom pb-2">
                                  <div className="row px-2">
                                    {data?.account_data?.individual?.first_name && (
                                      <FieldsInput
                                        label={"First Name:"}
                                        val={
                                          data?.account_data?.individual?.first_name
                                        }
                                        name_field={"data.account_data.individual.first_name"}
                                      // onchange={handleChange}
                                      />
                                    )}

                                    {data?.account_data?.individual?.last_name && (
                                      <FieldsInput
                                        label={"Last Name:"}
                                        val={
                                          data?.account_data?.individual?.last_name
                                        }
                                        name_field={"data.account_data.individual.last_name"}
                                      // onchange={handleChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* 2th row representative */}

                          {(data?.account_data?.individual?.phone ||
                            data?.account_data?.individual?.dob) && (
                              <div className="container pb-3 ">
                                <div className=" border-bottom pb-2">
                                  <div className="row px-2">
                                    {data?.account_data?.individual?.phone && (
                                      <FieldsInput
                                        label={"Phone:"}
                                        val={data?.account_data?.individual?.phone}
                                        name_field={"data.account_data.individual.phone"}
                                      // onchange={handleChange}
                                      />
                                    )}

                                    {data?.account_data?.individual?.dob && (
                                      <FieldsInput
                                        label={"Date of birth:"}
                                        type={"date"}
                                        val={data?.account_data?.individual?.dob.day + '-' + data?.account_data?.individual?.dob.month + '-' + data?.account_data?.individual?.dob.year}
                                        name_field={"representativeDob"}
                                      // onchange={onCompanyFieldsChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* 3rd row representative */}

                          {(data?.account_data?.individual?.email ||
                            data?.account_data?.individual?.ssn_last_4) && (
                              <div className="container pb-3 ">
                                <div className=" border-bottom pb-2">
                                  <div className="row px-2">
                                    {data?.account_data?.individual?.email && (
                                      <FieldsInput
                                        label={"Email:"}
                                        val={data?.account_data?.individual?.email}
                                        name_field={"data.account_data.individual.email"}
                                      // onchange={handleChange}
                                      />
                                    )}

                                    {data?.account_data?.individual?.ssn_last_4 && (
                                      <FieldsInput
                                        label={"SSN Last 4:"}
                                        val={data?.account_data?.individual?.ssn_last_4}
                                        name_field={"data.account_data.individual.ssn_last_4"}
                                      // onchange={handleChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* 4th row representative */}

                          {data?.account_data?.individual?.relationship?.title && (
                            <div className="container pb-3 ">
                              <div className=" border-bottom pb-2">
                                <div className="row px-2">
                                  {data?.account_data?.individual?.relationship?.title && (
                                    <FieldsInput
                                      label={"Relation with company:"}
                                      val={data?.account_data?.individual?.relationship?.title}
                                      name_field={"data.account_data.individual.relationship.title"}
                                    // onchange={handleChange}
                                    />
                                  )}

                                  {/* {accountData?.account_data?.individual?.ssn_last_4 && <FieldsInput label={"State:"} val={accountData?.account_data?.individual?.ssn_last_4} name_field={"representativeSSN4"} onchange={inputFieldsChange} />} */}
                                </div>
                              </div>
                            </div>
                          )}

                          {/* 5th row representative */}

                          <div className="container pb-3 ">
                            <div className=" border-bottom pb-2">
                              <div className="row px-2">
                                {data?.account_data?.individual?.address?.line1 && (
                                  <FieldsInput
                                    label={"Address:"}
                                    val={data?.account_data?.individual?.address?.line1}
                                    name_field={"data.account_data.individual.address.line1"}
                                  // onchange={handleChange}
                                  />
                                )}

                                {data?.account_data?.individual?.address?.city && (
                                  <FieldsInput
                                    label={"City:"}
                                    val={data?.account_data?.individual?.address?.city}
                                    name_field={"data.account_data.individual.address.city"}
                                  // onchange={handleChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          {/* 6th row representative */}

                          <div className="container pb-3 ">
                            <div className=" border-bottom pb-2">
                              <div className="row px-2">
                                {data?.account_data?.individual?.address?.state && (
                                  <FieldsInput
                                    label={"State:"}
                                    val={data?.account_data?.individual?.address?.state}
                                    name_field={"data.account_data.individual.address.state"}
                                  // onchange={handleChange}
                                  />
                                )}

                                {data?.account_data?.individual?.address?.postal_code && (
                                  <FieldsInput
                                    label={"Zip Code:"}
                                    val={data?.account_data?.individual?.address?.postal_code}
                                    name_field={"data.account_data.individual.address.postal_code"}
                                  // onchange={handleChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                )}
              <div className="bg-white px-2 my-4 pb-3 rounded">
                <Heading text="Payout Info" customClass="m-0 px-1" />

                <div className="text-black container inline-block">
                  <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
                    {paymentData?.type === ACCOUNT_TYPES.BANK ? (
                      <>
                        {/* 1th row */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"Account Holder Name:"}
                                val={paymentData?.data?.account_holder_name}
                                name_field={"accountHolderName"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Routing Number:"}
                                val={paymentData?.data?.routing_number}
                                name_field={"routingNumber"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* 2th row */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"Account Number:"}
                                val={paymentData?.data?.account_number}
                                name_field={"bankAccount"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Payout Type:"}
                                val={paymentData?.type}
                                name_field={"paymentMethod"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* 1th row card */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"Card Number:"}
                                val={paymentData?.data?.number}
                                name_field={"cardNumber"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Expiry:"}
                                val={paymentData?.data?.exp_month + '/' + paymentData?.data?.exp_year}
                                name_field={"expiry"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* 2th row */}
                        <div className="container pb-3 ">
                          <div className=" border-bottom pb-2">
                            <div className="row px-2">
                              <FieldsInput
                                label={"CVC:"}
                                val={paymentData?.data?.cvc}
                                name_field={"cvc"}
                              // onchange={onConnectFieldsChange}
                              />
                              <FieldsInput
                                label={"Payout Type:"}
                                val={paymentData?.type}
                                name_field={"paymentMethod"}
                              // onchange={onConnectFieldsChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="3" className="p-0">
              <div className="container px-2">
                <div className="row mx-0">
                  <button
                    onClick={() => {
                      setBtnLoading(true)
                      statusHandler(STATUS.CONNECT_ACCOUNT_APPROVED)
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    {"Approve  "}
                    {
                      btnLoading &&
                      <Spinner wT={"18px"} hT={"18px"} />
                    }
                  </button>
                </div>
                <div className="row pt-3 mx-0">
                  <button
                    onClick={() => {
                      setRejectBtnLoading(true)
                      statusHandler(STATUS.CONNECT_ACCOUNT_REJECTED)
                    }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    {"Reject  "}
                    {
                      rejectBtnLoading &&
                      <Spinner wT={"18px"} hT={"18px"} />
                    }
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default ConnectAccountDetails

