import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import {
  OrderId,
  // UserId,
  Name,
  Email
} from "./LatestPayoutsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { TABLES } from "pages/Utility/constants"
import DefaultLoading, { Spinner } from "common/loading"
import PremRequest from "./PremRequests"
import { toast } from "react-toastify"

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY

const stripe = require("stripe")(KEY)

const PremRequestsPage = () => {
  document.title = "Premium Request | HangarDirect"
  const [loading, setLoading] = useState(false)
  const [dataPrem, setDataPrem] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [rowLoading, setRowLoading] = useState(-1);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    getPremiumRequestsData()
  }, [])

  const getPremiumRequestsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.REQUEST_FOR_PREMIUM)
        .select("*,user_id(*)")
        .order("id", { ascending: false })
      if (error) {
        throw error
      }
      setDataPrem(data);
      setCurrentItems(data);
    } catch (error) { }
    setLoading(false)
  }



  const getAccountData = async row => {
    try {
      setRowLoading(row.id)
      const { user_stripe_account, user_id } = row
      const { data, error } = await supabase
        .from(TABLES.USER_PROFILE)
        .select("connect_account_approval")
        .eq("id", user_id)
        .single()
      const { connect_account_approval } = data
      const account = await stripe.accounts.retrieve(user_stripe_account)
      account.userID = user_id
      account.connectAccountApproval = connect_account_approval

      setModelPopData(account)
      setRowLoading(-1)
    } catch (error) {
      console.log('error: ', error.message);
      toast.dismiss()
      toast.error(error.message);
      setRowLoading(-1)

    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      // {
      //   Header: "User Id",
      //   accessor: "User Id",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <UserId {...cellProps} />
      //   },
      // },
      {
        Header: "Name",
        accessor: "Name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "Email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      // {
      //   Header: "View Account",
      //   disableFilters: true,
      //   accessor: row => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //       // onClick={() => {
      //       //   getAccountData(row)
      //       // }}
      //       >

      //         {rowLoading === row.id ? <Spinner wT={70} hT={10} r={10} /> : "View Details"}
      //       </Button>
      //     )
      //   },
      // },
    ],
    [rowLoading]
  )


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <PremRequest
                  columns={columns}
                  data={dataPrem}
                  modelPopData={modelPopData}
                  // approvalHandler={handleStripeAccountApproval}
                  closeModal={() => setModelPopData(null)}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PremRequestsPage

