import React from "react"
import { Badge } from "reactstrap"


const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <div className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </div>
  )
}

const Title = cell =>
  cell.row.original
    ? cell.row.original.profile.meta.first_name +
    " " +
    cell.row.original.profile.meta.last_name
    : ""

const Owner = cell => {
  return (
    cell.row.original
      ? cell.row.original.hangar_owner.meta.first_name +
      " " +
      cell.row.original.hangar_owner.meta.last_name
      : ""
  )
}

const City = cell => {
  return cell.cell.row.original.city ? cell.cell.row.original.city : ""
}

const Status = cell => {
  return (
    <Badge
      style={{
        textTransform: "capitalize",
        fontSize: "14px",
        padding: "5px 10px",
      }}
      className={
        "badge-soft-" +
        (cell.tabIndex === 1
          ? "warning"
          : cell.tabIndex === 2
            ? "success"
            : cell.tabIndex === 3
              ? "danger"
              : "info")
      }
    >
      {cell.tabIndex === 1
        ? "In Review"
        : cell.tabIndex === 2
          ? "Approved"
          : cell.tabIndex === 3
            ? "Rejected"
            : "Deleted!"}
    </Badge>
  )
}
const Rating = cell => {
  return (
    <span style={{ textTransform: "capitalize" }}>
      {cell.row?.original?.rating}
    </span>
  )
}
const Action = ({ row, handleDelete }) => {
  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete this review?")) {
      handleDelete(row.original.id);
    }
  };

  return (
    <div className="d-flex gap-1 cursor-pointer">
      {/* Other actions can be added here */}
      <img
        style={{ height: "30px" }}
        src="/images/delete.png"
        alt="Delete"
        onClick={onDelete}
      />
    </div>
  );
};


export { CheckBox, OrderId, Title, Owner, City, Status, Rating, Action }
