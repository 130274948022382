import PropTypes from "prop-types"
import React, { useEffect, } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, } from "reactstrap"
import { useDispatch, connect } from "react-redux"
import { withRouter, } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { loginUser, } from "../../store/actions"
import { Spinner } from "common/loading"

const Login = props => {

  document.title = "Hangar Direct - Admin"
  const dispatch = useDispatch()
  const { loading, error } = props.LoginStore

  useEffect(() => {
    const data = localStorage.getItem("authUser")
    if (data) props.history.push("/dashboard")
  }, []);


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages pt-sm-">
        <Container>
          <Row
            style={{
              display: "flex",
              justifyContent: "center !important",
              alignItems: "center",
              height: "100vh",
            }}
            className="justify-content-center"
          >
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Hangar Direct - Admin.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      {/* <img src={profile} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div style={{ display: "flex" }} className="justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 566 128"
                      fill="#00aeef"
                      style={{ margin: "auto" }}
                      width={200}
                    >
                      <g id="Layer_1">
                        <path d="M144.9,63.73c.81-.88,1.24-1.91,1.92-2.81,2.72-3.62,6.51-4.35,10.64-3.7,4.94,.78,7.91,4.21,8.74,9.64,.21,1.38,.3,2.76,.3,4.15,0,7.21-.02,14.42,.02,21.63,0,1.05-.27,1.38-1.33,1.35-2.53-.08-5.06-.07-7.59,0-.99,.03-1.28-.25-1.28-1.26,.04-6.87,.02-13.75,.03-20.62,0-1.31-.13-2.61-.54-3.86-.61-1.86-1.78-2.72-3.74-2.84-1.71-.1-3.38,.04-4.85,1.04-1.49,1.01-2.31,2.36-2.31,4.23,.03,7.29-.01,14.59,.04,21.88,0,1.11-.28,1.47-1.41,1.43-2.57-.08-5.15-.05-7.72-.01-.85,.01-1.19-.22-1.19-1.13,.02-16.19,.02-32.38,0-48.58,0-.92,.33-1.12,1.17-1.1,2.66,.05,5.32,.06,7.97,0,.99-.03,1.18,.32,1.17,1.23-.04,5.99-.02,11.98-.02,17.96,0,.46,0,.92,0,1.39Z"></path>
                        <path d="M225.02,63.89c.62-1.32,1.29-2.62,2.28-3.71,3.08-3.38,7-3.64,11.07-2.75,4.05,.88,6.2,3.83,7.27,7.64,.54,1.91,.78,3.87,.78,5.86,0,7.21-.03,14.42,.02,21.62,0,1.11-.28,1.47-1.41,1.43-2.49-.08-4.98-.09-7.46,0-1.24,.05-1.45-.42-1.45-1.53,.03-7.29,0-14.58-.03-21.88,0-1.07-.27-2.07-.71-3.05-.47-1.04-1.26-1.72-2.33-1.96-1.94-.43-3.87-.33-5.65,.64-1.88,1.03-2.7,2.68-2.7,4.79,0,7.17-.02,14.33,.03,21.5,0,1.16-.29,1.51-1.47,1.47-2.57-.09-5.14-.05-7.72-.01-.76,.01-1.02-.21-1.01-1,.02-11.38,.02-22.76,0-34.14,0-.78,.22-1.01,1-1,2.7,.04,5.4,.06,8.1,0,.94-.02,1.12,.34,1.09,1.18-.05,1.59-.02,3.18-.02,4.77,.1,.04,.21,.07,.31,.11Z"></path>
                        <path d="M511.54,76.28c.09,1.37-.1,3.01,.16,4.65,.48,3.14,1.99,5.05,4.53,5.54,3.29,.63,5.84-.49,7.4-3.24q1.05-1.86,3.02-1c1.58,.69,3.16,1.4,4.76,2.04,.8,.32,.97,.69,.64,1.51-2.01,5.03-5.75,7.87-11.03,8.73-3.69,.6-7.32,.29-10.76-1.24-5.11-2.27-7.7-6.48-8.77-11.75-.98-4.83-.79-9.61,.86-14.31,2.07-5.9,7.14-9.74,13.38-10.1,4.16-.24,8.18,.13,11.67,2.73,2.04,1.52,3.53,3.46,4.51,5.81,.28,.67,.13,.96-.5,1.22-2.18,.9-4.35,1.82-6.49,2.8-.76,.35-1.03,.24-1.34-.57-.88-2.3-2.5-3.75-5.07-3.93-2.44-.17-4.51,.44-5.83,2.69-.84,1.43-1.06,3-1.12,4.61-.05,1.18,0,2.36,0,3.8Z"></path>
                        <path d="M550.78,76.07c0,2.87,.04,5.73-.02,8.6-.02,.95,.18,1.36,1.24,1.29,1.47-.1,2.95,0,4.43-.04,.7-.02,.97,.19,.95,.92-.04,2.06-.02,4.13-.01,6.2,0,.5-.01,.94-.7,.92-2.9-.07-5.82,.24-8.69-.39-4.6-1.01-7.25-4.12-7.33-8.84-.09-5.77-.07-11.55-.02-17.32,.01-1.18-.24-1.66-1.51-1.57-1.12,.08-2.58,.5-3.31-.18-.8-.74-.2-2.23-.27-3.39-.06-1.09,.06-2.2-.03-3.29-.08-1.01,.34-1.24,1.23-1.16,.63,.06,1.27,.04,1.9,0,1.61-.1,2.44-.81,2.79-2.41,.44-2.04,.18-4.12,.18-6.17,0-.93,.22-1.25,1.19-1.22,2.23,.07,4.47,.07,6.7,0,.97-.03,1.33,.21,1.3,1.25-.08,2.48,.01,4.97-.04,7.46-.02,.9,.27,1.16,1.15,1.13,1.64-.06,3.29,.03,4.93-.04,.86-.03,1.07,.29,1.05,1.09-.05,1.98-.04,3.96,0,5.94,.02,.76-.22,1.05-1,1.02-1.6-.05-3.21,.05-4.81-.04-1.09-.06-1.34,.34-1.32,1.37,.06,2.95,.02,5.9,.02,8.85Z"></path>
                        <path d="M342.57,65.49c.2-.57,.38-1.15,.59-1.72,1.68-4.49,5.43-6.23,9.93-5.93,.52,.03,.63,.29,.63,.73,0,2.66-.02,5.31,0,7.97,0,.8-.45,.86-1.07,.86-1.94,0-3.87-.04-5.8,.31-3.12,.56-4.48,2.08-4.49,5.26-.02,6.53-.03,13.07,.02,19.6,0,1.11-.3,1.46-1.42,1.42-2.57-.08-5.14-.05-7.72,0-.86,.01-1.19-.22-1.19-1.14,.03-11.34,.02-22.68,0-34.01,0-.79,.27-1,1.02-.99,2.78,.04,5.57,.04,8.35,0,.73,0,.94,.24,.93,.95-.04,2.22-.01,4.44-.01,6.66l.2,.04Z"></path>
                        <path d="M447.49,65.46c.75-1.46,1.24-2.95,2.23-4.23,2.19-2.83,5.18-3.56,8.54-3.38,.44,.02,.59,.21,.59,.62,0,2.74-.01,5.48,0,8.22,0,.82-.54,.69-1.04,.7-1.89,.04-3.79-.06-5.67,.3-3.31,.63-4.64,2.15-4.65,5.55-.02,6.45-.04,12.9,.02,19.36,.01,1.14-.32,1.43-1.42,1.39-2.57-.08-5.15-.05-7.72,0-.84,.01-1.06-.26-1.06-1.08,.03-11.34,.02-22.69,0-34.03,0-.73,.15-1.04,.97-1.03,2.74,.05,5.49,.04,8.23,0,.8-.01,1,.27,.99,1.02-.04,2.2-.01,4.4-.01,6.6Z"></path>
                        <path d="M417.66,75.95c0-5.61,.02-11.21-.02-16.82,0-.95,.18-1.33,1.24-1.3,2.65,.08,5.31,.05,7.97,.01,.79-.01,1.11,.16,1.11,1.03-.03,11.34-.03,22.68,0,34.02,0,.84-.29,1.09-1.1,1.08-2.7-.03-5.4-.05-8.1,0-.95,.02-1.11-.4-1.11-1.22,.02-5.61,.01-11.21,.01-16.82Z"></path>
                        <path d="M428.73,48.34c.35,3.78-2.19,5.81-5.95,5.74-4.13-.08-5.92-1.81-5.92-5.94,0-3.8,1.94-5.68,5.99-5.63,3.17,.05,6.11,1.08,5.88,5.83Z"></path>
                        <path d="M288.7,94.78c-.32-4.07-2.9-7.06-6.86-8.1-1.8-.48-3.64-.7-5.5-.7-3.54-.01-7.09,0-10.63,0-.8,0-1.6-.04-2.37-.29-1.5-.49-2.15-1.81-1.67-3.45,.4-1.37,1.19-1.73,2.81-1.47,4.25,.67,8.49,.74,12.62-.65,4.43-1.49,7.15-4.55,7.69-9.25,.48-4.19-.48-7.97-4.01-10.71-.61-.47-1.27-.87-1.96-1.33,.51-.37,.94-.23,1.34-.23,1.86-.02,3.71-.02,5.57,0,.57,0,.97-.04,.96-.79-.03-2.02-.02-4.05,0-6.07,0-.54-.22-.75-.75-.74-2.07,.03-4.14-.07-6.2,.07-2.51,.17-4.05,1.89-4.17,4.4-.02,.38-.05,.76-.02,1.14,.07,.84-.16,1.05-1.08,.89-3.98-.69-7.98-.71-11.9,.4-4.3,1.21-7.32,3.87-8.24,8.35-.91,4.4-.26,8.48,3.38,11.63,.79,.68,1.74,1.12,2.8,1.82-1.48,.45-2.78,.78-3.91,1.51-2.27,1.45-3.19,3.52-2.79,6.16,.37,2.41,1.69,4.08,4.04,4.88,.24,.08,.48,.15,.72,.24,.16,.06,.28,.17,.28,.37,0,.26-.14,.37-.38,.41-.29,.05-.58,.1-.87,.17-3.26,.87-5.1,2.76-5.33,5.49-.32,3.74,1.04,6.13,4.36,7.57,.88,.38,1.81,.7,2.74,.95,4.41,1.18,8.93,1.14,13.43,.96,3.11-.13,6.21-.55,9.15-1.71,3.28-1.3,5.66-3.43,6.51-7,.39-1.62,.37-3.27,.24-4.9Zm-25.25-26.01c.22-3.16,1.79-4.7,4.98-4.88,.34-.02,.67-.03,.85-.04,1.17,.02,2.18,.02,3.14,.39,1.77,.7,2.79,1.97,2.9,3.9,.03,.5,.03,1.01,.02,1.51-.05,2.46-1.32,4.07-3.72,4.55-1.45,.29-2.94,.3-4.39-.02-2.66-.59-3.98-2.53-3.78-5.42Zm13.32,32.31c-.72,.22-1.47,.45-2.21,.47-3.66,.1-7.33,.41-10.97-.2-3.15-.53-4.32-2.82-3.01-5.54,.37-.77,.85-1.13,1.75-1.11,2.15,.06,4.3,.02,6.45,.02,0,.02,0,.04,0,.06,2.27,0,4.55-.08,6.82,.02,2.5,.12,3.67,1.36,3.54,3.48-.09,1.31-.92,2.34-2.37,2.79Z"></path>
                        <path d="M407.16,43.16c-2.62,.04-5.23,.07-7.85-.01-1.03-.03-1.25,.31-1.24,1.28,.04,6.03,.02,12.06,.01,18.09,0,.39,.13,.82-.11,1.21-.45-.12-.38-.54-.48-.82-.61-1.59-1.63-2.84-3-3.82-6.19-4.45-15.42-1.7-18.35,5.58-2.46,6.1-2.34,12.43-1.15,18.77,.58,3.08,1.85,5.88,4.13,8.1,4.61,4.48,15.35,5.2,18.98-3.41,0,1.62,.04,3.24-.02,4.86-.03,.79,.26,1.01,1.02,1,2.7-.04,5.4-.04,8.1,0,.83,.01,1.07-.25,1.07-1.09-.02-16.23-.02-32.47,0-48.7,0-.88-.33-1.04-1.11-1.03Zm-14.65,43.23c-4.22,.53-7.54-2.42-7.6-6.66-.04-2.57-.07-5.14,0-7.71,.08-2.72,1.19-4.96,3.79-6.07,2.62-1.11,5.21-.82,7.55,.85,1.28,.92,1.82,2.28,1.83,3.85,.01,1.77,0,3.54,0,5.31,0,1.43-.07,2.87,.01,4.3,.23,4.13-2.39,5.74-5.6,6.14Z"></path>
                        <path d="M324.67,85.94c-.38,.02-.77-.04-1.14,.01-1.13,.16-1.49-.23-1.49-1.42-.02-5.47,.3-10.96-.16-16.42-.39-4.63-2.7-7.97-7.09-9.74-3.54-1.43-7.25-1.47-10.96-1.21-4.56,.32-8.31,2.24-11.14,5.91-.49,.64-.53,1.02,.1,1.56,1.54,1.31,3.07,2.64,4.53,4.04,.69,.66,1.01,.53,1.54-.17,2.29-3.02,5.44-3.81,9.01-3.23,1.64,.26,2.81,1.22,3.46,2.81,.59,1.43,.42,2.9,.44,4.36,0,.66-.49,.57-.9,.57-1.77,0-3.54,0-5.31,0-3.11-.01-6.13,.44-8.97,1.78-5.96,2.8-7.79,10.62-3.97,16.01,3.33,4.7,12.27,5.03,16.58,1.9,1.71-1.25,2.7-2.95,3.31-5.02,.22,.13,.28,.15,.29,.18,.11,.31,.2,.63,.31,.95,.84,2.58,2.38,4.46,5.17,4.98,2.09,.39,4.21,.13,6.31,.19,.59,.02,.82-.25,.82-.84-.02-2.15-.02-4.3,0-6.45,0-.54-.2-.77-.74-.74Zm-15.04,.75c-2.04,1.07-4.21,1.13-6.37,.5-1.37-.4-2.12-1.4-2.2-2.9-.2-3.85,1.11-5.31,4.98-5.45,.71-.02,1.43,0,2.2,0,1.13,.29,2.98-.81,3.44,.54,1.08,3.13,.02,6.22-2.05,7.31Z"></path>
                        <path d="M207.75,87.91c0-1.96,0-2.04-1.9-1.94-1.18,.06-1.46-.32-1.45-1.46,.06-5.1,.06-10.2,.02-15.3-.04-5.52-3.04-9.59-8.3-11.22-4.03-1.24-8.13-1.21-12.25-.48-3.68,.65-6.46,2.74-8.77,5.55-.39,.47-.73,.87-.03,1.45,1.59,1.32,3.11,2.71,4.64,4.1,.6,.55,.98,.64,1.56-.1,1.92-2.45,4.43-3.61,7.61-3.42,3.42,.21,5.25,1.96,5.4,5.39,.02,.38-.05,.77,.01,1.14,.18,1.12-.27,1.41-1.38,1.4-3.41-.02-6.82-.21-10.2,.5-5.75,1.21-8.88,4.16-9.5,9.46-.51,4.35,1.15,8.52,5.15,10.52,6.49,3.24,13.71,.74,16.78-6.02,.2,.62,.33,1.01,.45,1.39,.97,2.97,2.94,4.79,6.12,5.03,1.76,.13,3.54,.02,5.31,.05,.65,0,.75-.34,.75-.88-.02-1.73,0-3.46,0-5.18Zm-19.57-.39c-.67,.01-1.36,0-2.01-.16-1.74-.41-2.67-1.57-2.75-3.32-.12-2.68,.52-3.96,2.33-4.68,1.82-.72,3.74-.51,5.63-.54,.08,0,.17,0,.25,0q2.65,0,2.65,2.63c.35,4.12-1.49,5.99-6.1,6.06Z"></path>
                        <path d="M495.82,71.61c-.82-5.44-3.05-9.96-8.09-12.7-3.36-1.83-7.01-2.14-10.7-1.76-6.16,.64-10.7,3.72-13,9.58-2.2,5.61-2.22,11.36-.42,17.08,1.66,5.26,5.24,8.67,10.52,10.18,4.37,1.25,8.75,.98,13.07-.36,2.66-.82,5.01-2.18,6.99-4.16,.48-.48,.66-.82,.12-1.45-1.33-1.53-2.59-3.11-3.79-4.73-.59-.8-.97-.86-1.69-.11-2.4,2.49-5.31,3.75-8.85,3.52-4.23-.28-6.95-3.01-7-7.12-.01-.84,.25-1.08,1.07-1.08,3.46,.04,6.92,.02,10.38,.02,3.5,0,7-.03,10.5,.02,.88,.01,1.17-.24,1.14-1.13-.07-1.93,.02-3.87-.27-5.8Zm-12.14,.89c-1.51,0-3.03,0-4.54,0-1.68,0-3.37-.05-5.05,.02-.92,.04-1.12-.32-1.1-1.17,.1-4.56,3.47-7.4,7.97-6.65,2.42,.4,4.19,2.66,4.47,5.71,0,.08,0,.17,.01,.25q.11,1.84-1.76,1.84Z"></path>
                      </g>
                      <g className="" id="Layer_2">
                        <g>
                          <path d="M69.87,70.1c-2.3-1.77-5.65-2.88-9.38-2.88s-7.08,1.11-9.38,2.88c-2.87,2.21-.58,5.7,3.74,5.7h11.28c4.32,0,6.61-3.49,3.74-5.7Z"></path>
                          <path d="M108.54,42.89h0c-28.75-20.74-67.34-20.72-96.07,.05h0c-3.07,2.22-4.89,5.8-4.89,9.61v43.27c0,4.09,3.28,7.41,7.32,7.41H106.16c4.06,0,7.35-3.33,7.35-7.44V52.66c0-3.88-1.85-7.52-4.97-9.77Zm-3.17,34.03c0,2.14-1.74,3.88-3.88,3.88h-8.24c.04,.22,.06,.45,.06,.68,0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91c0-.23,.02-.46,.06-.68h-7.02c-1.91,8.19-9.26,14.3-18.04,14.3s-16.13-6.11-18.04-14.3h-6.99c.04,.22,.06,.45,.06,.68,0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91c0-.23,.02-.46,.06-.68h-8.14c-2.14,0-3.88-1.74-3.88-3.88v-.06c0-2.14,1.74-3.88,3.88-3.88h22.7c1.45-7.35,7.25-13.13,14.61-14.56v-7.3c0-2.16,1.75-3.91,3.91-3.91s3.91,1.75,3.91,3.91v7.45c7.02,1.65,12.51,7.3,13.91,14.41h22.82c2.14,0,3.88,1.74,3.88,3.88v.06Z"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>



                      <div className="mt-4 d-grid">
                        <button
                          className={`btn btn-primary btn-block ${loading && "!cursor-disable"
                            }`}
                          type="submit"
                        >
                          {loading ? (
                            <Spinner hT={"20px"} wT={"20px"} />
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} HangarDirect.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}



export default connect(s => ({ LoginStore: s.Login }))(withRouter(Login))

Login.propTypes = {
  history: PropTypes.object,
}
