import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
// import {
//   postFakeLogin,
//   postJwtLogin,
//   postSocialLogin,
// } from "../../../helpers/fakebackend_helper";
import { supabase } from "pages/Utility/supabase"

function* loginUser({ payload: { user, history } }) {
  try {
    const ADMIN_ERROR = "Only for Admins"
    const { error: error1, data: res } = yield call(() =>
      supabase
        .from("profile")
        .select("*")
        .eq("email", user.email)
        .eq("user_type", "ADMIN")
    )
    if (error1) throw error1?.message
    if (!res?.length) throw ADMIN_ERROR
    const { error, data } = yield call(() =>
      supabase.auth.signInWithPassword(user)
    )
    if (error) throw error?.message
    localStorage.setItem("authUser", JSON.stringify(data.user))
    localStorage.setItem("session", JSON.stringify(data.session))
    yield put(loginSuccess(data.user))
    history.push("/dashboard")
    // }
  } catch (error) {
    yield put(apiError(error?.message || error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("session")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
