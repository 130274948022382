import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import { Box } from "@material-ui/core"
import FeedbackModel from "./FeedbackModel"

const Feedback = ({ columns, hangars, modelPopData, closeModal, currentItems, setCurrentItems }) => {
  return (
    <React.Fragment>
      <FeedbackModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Customers Feedback</div>
          {hangars.length ? (
            <TableContainer
              columns={columns}
              data={hangars}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
              sx={{
                p: 5,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "red",
              }}
            >
              No Customers Feedbacks Found.
            </Box>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Feedback.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Feedback)
