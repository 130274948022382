import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, Col, Row } from "reactstrap"

//actions

//redux

import moment from "moment"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const DUMMY_ARRAY = [0, 1, 2, 3]
const PREV_MONTHS = DUMMY_ARRAY.map(i => moment().subtract(i, "month").format("MMM"))
const PREV_MONTHS_ISO = DUMMY_ARRAY.map(i => moment().subtract(i, "month").format("YYYY-MM"))

const Earning = ({ dataColors, data }) => {
  const apexearningChartColors = getChartColorsArray(dataColors)
  const [selectedMonth, setSelectedMonth] = useState(PREV_MONTHS_ISO[0])
  const [chartData, setChartData] = useState([])

  const options = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: apexearningChartColors,
    stroke: {
      curve: "smooth",
      width: 3,
    },
  }
  useEffect(() => {
    onChangeMonth(moment().format("YYYY-MM"))
  }, [data])

  const series = [
    {
      name: moment(selectedMonth).format("MMM"),
      data: chartData,
    },
  ]

  const onChangeMonth = value => {
    const numDays = moment(value).daysInMonth()
    let cData = data.filter(i => i.date.includes(value))
    cData = Array(numDays)
      .fill()
      .map((i, index) => {
        let day = moment(value)
          .endOf("month")
          .subtract(index, "day")
          .format("YYYY-MM-DD")
        const found = cData.find(i => i.date === day)
        return found ? +found?.Amount?.toFixed(0) : 0
      })
    setChartData(cData)

    setSelectedMonth(value)
  }

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={selectedMonth}
                    onChange={e => {
                      onChangeMonth(e.target.value)
                    }}
                  >
                    {PREV_MONTHS.map((m, i) => (
                      <option value={PREV_MONTHS_ISO[i]} key={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                  <label className="input-group-text">Month</label>
                  {/* </div> */}
                </div>
              </div>
              <h4 className="card-title mb-4">Earnings</h4>
            </div>

            <Row>
              {/* <Col lg="4">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>This month</p>
                    <h4>$2453.35</h4>
                    <div>
                      <span className="badge badge-soft-success font-size-12 me-1">
                        {" "}
                        + 0.2%{" "}
                      </span>{" "}
                      From previous period
                    </div>
                  </div>

                  <div>
                    <Link to="#" className="btn btn-primary  btn-sm">
                      View Details{" "}
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">Last month</p>
                    <h5>$2281.04</h5>
                  </div>
                </div>
              </Col> */}

              <Col lg="12">
                <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Earning
