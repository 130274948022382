import React from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import { Button, Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import {
  OrderId,
  Title,
  Total,
  Date,
  StartDate,
  EndDate,
  Owner,
  OwnerName,
  RentalType
} from "./LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { STATUS, TABLES } from "pages/Utility/constants"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { toast } from "react-toastify"
// import {  } from "./LatestRequestsCol"

const CancelRequest = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);


  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Orders | HangarDirect"

  const columns = useMemo(() => [
    {
      Header: "#",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        let ids = {
          id: cellProps.row.original.id,
          user_id: cellProps.row.original.user_id,
        }
        return (
          <input
            // onChange={handleCheckChange(ids)}
            type="checkbox"
            className="form-check-input"
          />
        )
      },
    },
    {
      Header: "Id",
      accessor: "Id",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />
      },
    },
    {
      Header: "Customer",
      accessor: "Customer",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Title {...cellProps} />
      },
    },
    {
      Header: "Hangar Owner",
      accessor: "Order",
      disableFilters: true,
      Cell: cellProps => {
        return <OwnerName {...cellProps} />
      },
    },
    {
      Header: "Reservation From",
      accessor: "Start Date",
      Cell: cellProps => {
        return <StartDate {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "Reservation To",
      accessor: "End Date",
      Cell: cellProps => {
        return <EndDate {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "Rental Type",
      accessor: "rentalType",
      Cell: cellProps => {
        return <RentalType {...cellProps} />
      },
      disableFilters: true,
    },

    {
      Header: "Status",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Owner orders={orders} status={status} {...cellProps} />
      },
    },
    {
      Header: "Total",
      accessor: "Total",
      Cell: cellProps => {
        return <Total {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "View Details",
      disableFilters: true,
      accessor: row => {
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              history.push("/cancel-request-details/" + row.id)
            }}
          >
            View Details
          </Button>
        )
      },
    },
  ])
  const getOrdersData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.TRANSACTIONS_CANCEL)
        .select("*,transaction_id(*, user_id(*),hangar_owner(*))")
        .eq('status', STATUS.CANCEL_ORDER_IN_REVIEW)
        .order("id", { ascending: true })

      if (error) {
        setLoading(false)
        throw error
      }
      if (data?.length) {
        setOrders(data);
        setCurrentItems(data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message)
      console.log("error: ", error.message)
    }
  }
  const fetchStatuses = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase.from(TABLES.STATUS).select("*")

      if (error) {
        setLoading(false)
        throw error
      } else if (data) {
        setStatus(data)
        setLoading(false)
      }
    } catch (error) {
      console.log("error: ", error.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    getOrdersData()
    fetchStatuses()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Requests
                  loading={loading}
                  columns={columns}
                  orders={orders}
                  setLoading={setLoading}
                  getOrdersData={getOrdersData}
                  setAlert={setAlert}
                  className="!mb-0"
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                  onRowClick={r =>
                    history.push(`/cancel-request-details/${r.original.id}`)
                  }
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CancelRequest

