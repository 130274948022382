import { takeEvery, put, call } from "redux-saga/effects";


import {
  getStatusSuccess
} from "./actions";

//Include Both Helper File with needed methods

import { supabase } from "pages/Utility/supabase";
import { TABLES } from "pages/Utility/constants";
import { GET_HANGAR_STATUS } from "./actionTypes";

function* getAllOrderStatus() {
  try {
    const { data = [], error } = yield call(() => supabase.from(TABLES.STATUS).select("*"));
    if (error) throw error
    if (data?.length)
      yield put(getStatusSuccess(data));
  } catch (error) {
    console.log('error: ', error.message);

  }
}

function* hangarSaga() {
  yield takeEvery(GET_HANGAR_STATUS, getAllOrderStatus)
}


export default hangarSaga;
