import React, { useRef, useState } from 'react'
import Swal from 'sweetalert2'

const SelectTag = ({ handleClick, data = [] }) => {
    const clickHandler = (val) => {
        Swal.fire({
            title: "Action",
            html:
                `Are you sure ${val} this ?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "red",
            cancelButtonText: "No",
            showCloseButton: true,
            buttonsStyling: "h-5",
        }).then(async result => {
            if (result.isConfirmed) {
                handleClick(val)
            } else {
                document.getElementById('frm-whatever').selectedIndex = 0;
            }
        })
    }
    return (
        <form className="">
            <fieldset>
                <div className="input-group">
                    <select style={{ cursor: 'pointer' }} onClick={(e) => {
                        if (!e.target.value) return
                        clickHandler(e.target.value)
                    }} className="form-select" name="whatever" id="frm-whatever">
                        <option value="">Select</option>
                        {data.map((item) => {
                            return <option style={{ cursor: 'pointer' }} key={item.id} value={item?.value}>{item?.label}</option>
                        })}
                    </select>
                </div>
            </fieldset>
        </form>
    )
}

export default SelectTag
