import React from "react"

const Amenities = ({ amenities }) => {

    return <div className="text-black container inline-block">
        <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
            {amenities.length > 0 ? (
                amenities.map(item => {

                    let name = item?.name;
                    if (item.price_per_day && item.price_per_month) {
                        name += " ($" + item.price_per_day + ") ($" + item.price_per_month + ")";
                    } else if (item.price_per_month) {
                        name += " ($" + item.price_per_month + ")";
                    } else if (item.price_per_day) {
                        name += " ($" + item.price_per_day + ")";
                    }
                    return (
                        <div className="d-flex align-items-center gap-2" key={item.id}>
                            <div
                                style={{
                                    height: "5px",
                                    width: "5px",
                                    backgroundColor: "#D7D7D7",
                                    borderRadius: "100%",
                                }}
                            />
                            <div
                                className="text-black mr-3"
                                dangerouslySetInnerHTML={{
                                    __html: item?.icon,
                                }}
                            ></div>
                            <p
                                style={{
                                    color: "#495057",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                }}
                                className="text-sm font-medium m-0  leading-"
                            >
                                {name}
                            </p>
                        </div>
                    )
                })
            ) : (
                <div className="text-warning">No amenities found!</div>
            )}
        </div>
    </div>
}


export default Amenities;