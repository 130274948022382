import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import { Email, Phone } from "./contactlistCol"
//redux
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import Swal from "sweetalert2"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"
import { DATE_FORMATS, STATUS, TABLES } from "pages/Utility/constants"
import { AVATAR } from "common/svg"
import { toast } from "react-toastify"

const ContactsList = props => {
  document.title = "Users List | HangarDirect"
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async () => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase.from("profile").select("*").order("created_at", { ascending: false })
      if (error) {
        throw error
      }
      setUsersData(data)
      setCurrentItems(data)
    } catch (error) {
      console.log("error: ", error.message)
    }
    setLoading(false)
  }

  const handleSellingFree = async (id, check) => {
    Swal.fire({
      title: "Action",
      html:
        check
          ? "Give access for selling free"
          : "Remove access for selling free",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "red",
      cancelButtonText: "No",
      showCloseButton: true,
      buttonsStyling: "h-5",
    }).then(async result => {
      if (result.isConfirmed) {
        const { data, error } = await supabase.from(TABLES.USER_PROFILE).update({ "selling_free": check }).eq("id", id).select();
        if (!check) {
          const { data: listings, error: errListings } = await supabase.from(TABLES.HANGARS).select("id").eq("user_id", id).eq("selling_status", STATUS.SELLING_STATUS_LISTED)
          listings?.map(async (item) => {
            await supabase.from(TABLES.HANGARS).update({ "selling_status": STATUS.SELLING_STATUS_UNLISTED, selling_price_active: false }).eq("id", item.id)
          })
        }
        getUserData()
      } else {
        const input = document.getElementById("sellingCheckInput");
        input.checked = !check;
        Swal.fire("Changes are not saved", "", "info")
      }
    })
  }
  const updateUser = async (id, body, check) => {
    if (check === "user_change") {
      Swal.fire({
        title: "Action",
        html:
          body?.user_type === "USER"
            ? "Delete this user from admin post"
            : "Make this user admin",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "red",
        cancelButtonText: "No",
        showCloseButton: true,
        buttonsStyling: "h-5",
      }).then(async result => {
        if (result.isConfirmed) {
          const { data, error } = await supabase
            .from("profile")
            .update(body)
            .eq("id", id)
          getUserData()
        } else {
          const input = document.getElementById("checkAdminInput");
          input.checked = !check;
          Swal.fire("Changes are not saved", "", "info")
        }
      })
    } else {
      const { data, error } = await supabase
        .from("profile")
        .update(body)
        .eq("id", id)
    }
  }
  const columns = [
    {
      Header: "#",
      Cell: () => {
        return <input type="checkbox" className="form-check-input" />
      },
    },
    {
      Header: "ID",
      disableFilters: false,
      accessor: (row, index) => {
        const ind = index + 1
        return "#" + ind
      },
    },
    {
      Header: "Name",
      // accessor: "meta",
      disableFilters: true,
      filterable: true,
      accessor: ({ meta }) => {
        return (
          <>
            {/* {!meta?.img_url ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {meta?.first_name?.charAt(0)}
                </span>
              </div>
            ) : ( */}
            <div>
              {meta?.img_url ? <img
                className="rounded-circle avatar-xs"
                src={getImageUrl(meta?.img_url)}
                alt=""
              /> : <AVATAR width={32} height={32} custom={"rounded-circle avatar-xs"} />}
              <span
                style={{ marginLeft: "10px" }}
              >{`${meta?.first_name} ${meta?.last_name}`}</span>
            </div>
            {/* )} */}
          </>
        )
      },
    },

    {
      Header: "Email",
      filterable: true,
      accessor: row => {
        const copyToClipboard = async (email) => {
          try {
            await navigator.clipboard.writeText(email);
            toast.success('Email copied to clipboard!');
          } catch (err) {
            toast.error('Failed to copy email!');
          }
        };

        return (
          <div
            className="cursor-pointer"

          >
            {row?.email}
            <i onClick={() => copyToClipboard(row?.email)} style={{ marginLeft: "10px" }} className="fa fa-regular xs fa-copy" />
          </div>
        )
      },
    },

    {
      Header: "Phone Number",
      accessor: "phone",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <Phone {...cellProps} />
      },
    },
    // {
    //   Header: "Role",
    //   accessor: "role",
    //   disableFilters: true,
    //   filterable: true,
    //   Cell: cellProps => {
    //     return <Email {...cellProps} />
    //   },
    // },
    {
      Header: "Join Date",
      filterable: true,
      disableFilters: true,
      accessor: "created_at",

      Cell: cellProps => {
        return moment(cellProps.row.original.created_at).format(
          DATE_FORMATS.CREATED_AT
        )
      },
    },
    {
      Header: "Status",
      filterable: true,
      disableFilters: true,
      accessor: row => {
        return (
          <div>
            <span
              className={`${row.is_active ? "bg-success" : "bg-danger"
                } bg-opacity-25 px-1 rounded-1`}
              style={{ color: row.is_active ? "#34C38F" : "#F46A6A" }}
            >
              {row.is_active ? "Active" : "Inactive"}
            </span>
          </div>
        )
      },
    },
    {
      Header: "Make admin",
      filterable: true,
      accessor: row => {
        return (
          <FormGroup switch>
            <Input
              type="switch"
              fill="red"
              id="checkAdminInput"
              // className="bg-primary"
              color="secondary"
              style={{ width: "35px", height: "18px", margin: "auto" }}
              defaultChecked={row.user_type == "ADMIN"}
              onChange={() => {
                updateUser(
                  row.id,
                  {
                    user_type: row?.user_type === "USER" ? "ADMIN" : "USER",
                  },
                  "user_change"
                )
              }}
            />
          </FormGroup>
        )
      },
    },
    {
      Header: "Selling Free",
      filterable: true,
      accessor: row => {
        return (
          <FormGroup switch>
            <Input
              type="switch"
              id="sellingCheckInput"
              fill="red"
              // className="bg-primary"
              color="secondary cursor-pointer"
              style={{ width: "35px", height: "18px", margin: "auto" }}
              defaultChecked={row?.selling_free}
              onChange={(e) => {
                handleSellingFree(
                  row.id, e.target.checked)
              }}
            />
          </FormGroup>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <div className="mb-4 h4 card-title">Users</div>
                <Card>
                  <CardBody>
                    <TableContainer
                      currentItems={currentItems}
                      setCurrentItems={setCurrentItems}
                      columns={columns}
                      data={usersData}
                      setData={setUsersData}
                      isSortBy={false}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      isFilter={false}
                      customPageSize={10}
                      className="custom-header-css"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsList)
