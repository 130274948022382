import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import TableContainer from "../../../components/Common/TableContainer"


import DefaultLoading from "common/loading"
import { DATE_FORMATS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import RequestModel from "./requestModel"
import { Button } from "reactstrap"
import { toast } from "react-toastify"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"
import { AVATAR } from "common/svg"

const DashboardReferals = props => {

  document.title = "Referrals | HangarDirect"

  const [usersData, setUsersData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modelPopData, setModelPopData] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    fetchReferredUsers()
  }, [])

  const fetchReferredUsers = async () => {
    setLoading(true)
    try {
      const { data = [], error } = await supabase
        .from(TABLES.USER_PROFILE)
        .select("*,refer_by(email)")
        .not("refer_by", "is", "null");

      if (error) {
        throw error
      }
      setUsersData(data);
      setCurrentItems(data);
    } catch (error) {
      console.log("error: ", error.message);
      toast.error(error.message)
    }
    setLoading(false)
  }

  const columns = [
    {
      Header: "#",
      Cell: () => {
        return <input type="checkbox" className="form-check-input" />
      },
    },
    {
      Header: "Image",
      disableFilters: true,
      filterable: true,
      accessor: ({ meta }) => {
        return (
          meta?.img_url ?
            <img
              className="rounded-circle avatar-xs"
              src={getImageUrl(meta?.img_url)}
              alt=""
            />
            : <AVATAR width={32} height={32} custom={"rounded-circle avatar-xs"} />
        )
      },
    },

    {
      Header: "Name",
      accessor: "meta",
      filterable: true,
      accessor: ({ meta }) => {

        return `${meta?.first_name} ${meta?.last_name}`
      },
    },
    {
      Header: "Email",
      filterable: true,
      accessor: row => {
        const copyToClipboard = async (email) => {
          try {
            await navigator.clipboard.writeText(email);
            toast.success('Email copied to clipboard!');
          } catch (err) {
            toast.error('Failed to copy email!');
          }
        };

        return (
          <div
            className="cursor-pointer"

          >
            {row?.email}
            <i onClick={() => copyToClipboard(row?.email)} style={{ marginLeft: "10px" }} className="fa fa-regular xs fa-copy" />
          </div>
        )
      },
    },
    {
      Header: "Invited by",
      filterable: true,
      accessor: row => {
        const copyToClipboard = async (email) => {
          try {
            await navigator.clipboard.writeText(email);
            toast.success('Email copied to clipboard!');
          } catch (err) {
            toast.error('Failed to copy email!');
          }
        };

        return (
          <div
            className="cursor-pointer"

          >
            {row.refer_by.email}
            <i onClick={() => copyToClipboard(row.refer_by.email)} style={{ marginLeft: "10px" }} className="fa fa-regular xs fa-copy" />
          </div>
        )
      },
    },

    // {
    //   Header: "Invited by",
    //   accessor: row => row.refer_by.email,

    // },
    {
      Header: "Join Date",
      filterable: true,
      disableFilters: true,
      accessor: "created_at",

      Cell: cellProps => {
        return moment(cellProps.row.original.created_at).format(DATE_FORMATS.CREATED_AT)
      },
    },
    {
      Header: "View account",
      disableFilters: true,
      accessor: cell => {
        const InvitedByID = cell?.refer_by
        const fetchInvitedUser = async () => {
          const { data, error } = await supabase
            .from(TABLES.USER_PROFILE)
            .select("*")
            .eq("id", InvitedByID)
          if (data?.length) setModelPopData(data[0])
        }
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={e => {
              setModelPopData(cell)
            }}
          >
            View Details
          </Button>
        )
      },
    },
  ]
  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={() => setModelPopData(null)}
      />
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="mb-4 h4 card-title">Referrals</div>

                    <TableContainer
                      columns={columns}
                      data={usersData}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      isFilter={false}
                      customPageSize={10}
                      className="custom-header-css"
                      currentItems={currentItems}
                      setCurrentItems={setCurrentItems}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DashboardReferals)

