import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"
const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const Id = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}
const Date = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}
const Name = cell => {
  return cell.row.original.name ? cell.row.original.name : ""
}
const Email = cell => {
  return cell.row.original.email ? cell.row.original.email : ""
}
const Description = cell => {
  return cell.row.original.message ? cell.row.original.message : ""
}
export { CheckBox, Id, Name, Email, Date, Description }
