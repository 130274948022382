import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import StackedColumnChart from "./StackedColumnChart"
import React, { useEffect, useState } from "react"
import { supabase } from "pages/Utility/supabase"
import MonthlyEarning from "./MonthlyEarning"
import DefaultLoading from "common/loading"
import { Link } from "react-router-dom"
import WelcomeComp from "./WelcomeComp"
import PropTypes from "prop-types"
import {
  getChartsData as onGetChartsData,
  logoutUser,
} from "../../store/actions"
import moment from "moment"
import { STATUS, TABLES } from "pages/Utility/constants"

const CHART_TYPES = {
  DAILY: 0,
  MONTHLY: 1,
  YEARLY: 2,
}
const DUMMY_ARRAY = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
const DAILY_LABELS = Array(30)
  .fill()
  .map((_, i) => moment().subtract(i, "day").format("DD"))
  .reverse()
const MONTHLY_LABELS = DUMMY_ARRAY.map(i =>
  moment().subtract(i, "month").format("MMM")
).reverse()
const YEARLY_LABELS = DUMMY_ARRAY.map(i =>
  moment().subtract(i, "year").format("YYYY")
).reverse()
const Dashboard = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [transactionData, setTransactionData] = useState([])
  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState(CHART_TYPES.MONTHLY)
  const [monthlyIncome, setMonthlyIncome] = useState([])
  const [transactionChartData, setTransactionChartData] = useState([])
  const [labels, setLabels] = useState(MONTHLY_LABELS)
  const [refundAmount, setRefundAmount] = useState(0)

  const { chartsData } = props.DashboardStore
  const sum = transactionData?.reduce(
    (acc, item) => acc + parseFloat(item.total_Amount),
    0
  )
  const reports = [
    {
      title: "Orders",
      iconClass: "bx-copy-alt",
      description: transactionData.length,
    },
    {
      title: "Revenue",
      iconClass: "bx-archive-in",
      description: "$" + sum.toFixed(2),
    },
    {
      title: "Refunded",
      iconClass: "bx-purchase-tag-alt",
      description:
        "$" +
        (refundAmount).toFixed(2),
    },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description:
        "$" +
        (sum / transactionData.length >= 0
          ? sum / transactionData.length
          : 0.0
        ).toFixed(2),
    },
  ]
  useEffect(() => {
    getHangarsTransactionData()
  }, [])
  useEffect(() => {
    const session = localStorage.getItem("session")
    if (!session) {
      localStorage.removeItem("authUser")
      dispatch(logoutUser(props.history))
    }
  }, [])
  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    handleChangePeriodType()
  }, [periodType, monthlyIncome])

  const getHangarsTransactionData = async () => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
        .from(TABLES.TRANSACTIONS)
        .select("*")
        .order("id", { ascending: true })
      let { data: refundData, error: refundDataError } = await supabase
        .from(TABLES.REFUND)
        .select("refund_amount")

      const refund_amount = refundData.reduce((acc, item) => acc + +item.refund_amount, 0)
      setRefundAmount(refund_amount)

      if (error) {
        throw error
      }
      setTransactionData(data)
      const calculatedIncome = data?.reduce((acc, item) => {
        const date = moment(item?.created_at).format("MMM, YYYY")
        const index = acc?.findIndex(i => i.date === date)
        if (index === -1) {
          acc.push({
            created_at: item.created_at,
            date,
            Amount: +item?.total_Amount,
          })
        } else {
          acc[index].Amount += +item?.total_Amount
        }
        return acc
      }, [])

      setMonthlyIncome(calculatedIncome)
    } catch (error) { }
    setLoading(false)
  }

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  function handleChangePeriodType(type = periodType) {
    let chartData = []

    if (type === CHART_TYPES.DAILY)
      chartData = Array(30)
        .fill()
        .map((_, i) => {
          const FORMAT = "YYYY-MM-DD"
          const date = moment().subtract(i, "day").format(FORMAT)
          const found = transactionData.filter(
            i => moment(i.created_at).format(FORMAT) === date
          )
          return found?.length
            ? found
              .reduce((acc, item) => acc + +item.total_Amount, 0)
              .toFixed(2)
            : 0
        })
    else if (type === CHART_TYPES.MONTHLY) {
      const FORMAT = "MMM, YYYY"
      chartData = DUMMY_ARRAY.map(i => {
        const date = moment().subtract(i, "month").format(FORMAT)
        const found = monthlyIncome.find(
          i => moment(i.created_at).format(FORMAT) === date
        )
        return found?.Amount || 0
      })
    } else if (type === CHART_TYPES.YEARLY) {
      const FORMAT = "YYYY"
      chartData = DUMMY_ARRAY.map(i => {
        const date = moment().subtract(i, "year").format(FORMAT)
        const found = monthlyIncome.find(
          i => moment(i.created_at).format(FORMAT) === date
        )
        return found?.Amount || 0
      })
    }
    const labels =
      type === CHART_TYPES.DAILY
        ? DAILY_LABELS
        : type === CHART_TYPES.MONTHLY
          ? MONTHLY_LABELS
          : YEARLY_LABELS
    setLabels(labels)
    setTransactionChartData(chartData.reverse())
  }

  //meta title
  document.title = "Dashboard | HangarDirect"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}
          {/* loading */}
          {loading ? (
            <DefaultLoading />
          ) : (
            <>
              <Row>
                <Col xl="4">
                  <WelcomeComp sum={sum} />
                  <MonthlyEarning income={monthlyIncome} />
                </Col>
                <Col xl="8">
                  <Row>
                    {/* Reports Render */}
                    {reports.map((report, key) => (
                      <Col md="4" key={"_col_" + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="fw-medium">{report.title}</p>
                                <h4 className="mb-0">{report.description}</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx " + report.iconClass + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <Card>
                    <CardBody>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Transaction History</h4>
                        <div className="ms-auto">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={
                                  ({ active: periodType === CHART_TYPES.DAILY },
                                    "nav-link")
                                }
                                onClick={() => {
                                  onChangeChartPeriod(CHART_TYPES.DAILY)
                                }}
                                id="one_month"
                              >
                                Daily
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={
                                  ({
                                    active: periodType === CHART_TYPES.MONTHLY,
                                  },
                                    "nav-link")
                                }
                                onClick={() => {
                                  onChangeChartPeriod(CHART_TYPES.MONTHLY)
                                }}
                                id="one_month"
                              >
                                Month
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="#"
                                className={
                                  ({
                                    active: periodType === CHART_TYPES.YEARLY,
                                  },
                                    "nav-link")
                                }
                                onClick={() => {
                                  onChangeChartPeriod(CHART_TYPES.YEARLY)
                                }}
                                id="one_month"
                              >
                                Year
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <div className="clearfix"></div> */}
                      <StackedColumnChart
                        periodData={periodData}
                        data={transactionChartData}
                        labels={labels}
                        dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Row>
                <Col lg="12">
                  <LatestTransaction transactionData={transactionData} />
                </Col>
              </Row> */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default connect(s => ({ DashboardStore: s.Dashboard }))(Dashboard)
