import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"

const Requests = ({
  columns,
  hangars,
  modelPopData,
  closeModal,
  selection,
  setLoading,
  getHangarsData,
  selectedRowsUserIDs,
  setAlert,
  rowClick,
  currentItems, setCurrentItems,
  onRowClick,
}) => {
  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <div className="mb-4 h4 card-title">Reviews</div>
      <Card>
        <CardBody>
          {/* <div className="mb-4 d-flex justify-content-between ">
            <div
              className="border"
              style={{
                outline: "none",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                color: "#74788D",
                padding: "3px",
                width: "80px",
                borderRadius: "100px",
              }}
            >
              <div>
                <img src="/images/filter.png" alt="" />
              </div>
              <div>Filter</div>
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#00AEEF",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                width: "150px",
                padding: "8px",
                borderRadius: "100px",
              }}
            >
              <div>
                <img style={{ height: "10px" }} src="/images/tick.png" alt="" />
              </div>
              <div style={{ color: "white", fontSize: "10px" }}>
                Mark All as Accepted
              </div>
            </div>
          </div> */}
          {hangars.length ? (
            <TableContainer
              columns={columns}
              data={hangars}
              isSelectDropdown={false}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
              selection={selection}
              setLoading={setLoading}
              getHangarsData={getHangarsData}
              selectedRowsUserIDs={selectedRowsUserIDs}
              setAlert={setAlert}
              onRowClick={onRowClick}
              rowClick={rowClick}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
              sx={{
                p: 5,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "red",
              }}
            >
              No Hangars Requests Found.
            </Box>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)
