import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { supabase } from "pages/Utility/supabase"
import FieldError from "components/Common/FieldError"
import { Spinner } from "common/loading"
import { toast } from "react-toastify"
const FeedbackModel = ({ isOpen, modelData, onclick }) => {
  const [loading, setLoading] = useState(false)
  const [dataState, setDataState] = useState({
    title: "",
    description: "",
    is_for_owner: true,
  })
  const [errors, setErrors] = useState({
    title: "",
    description: "",
  })

  const dataChange = e => {
    if (e.target.value == "tag1") {
      setDataState({ ...dataState, is_for_owner: true })
    }
    if (e.target.value == "tag2") {
      setDataState({ ...dataState, is_for_owner: false })
    }
  }
  const handleValidate = () => {
    if (dataState?.title === "") {
      setErrors({ ...errors, title: "Enter title" })
      return false
    } else if (dataState.description === "") {
      setErrors({ ...errors, description: "Enter Description" })
      return false
    }
    return true
  }
  const handleSubmit = async () => {
    if (handleValidate()) {
      setLoading(true)
      let body = dataState
      const { data, error } = await supabase
        .from("help_desk_types")
        .insert(body)
        .select()
      if (error) {
        setLoading(false)
        toast.error(error.message)
      } else {
        // console.log(data)
        setLoading(false)
        onclick()
      }
    }
  }
  const handleChange = e => {
    const { value, name } = e.target
    setDataState({ ...dataState, [name]: value })
    setErrors({ ...errors, [name]: "" })
  }
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="1"
      toggle={onclick}
    >
      <ModalHeader toggle={onclick}>
        {modelData?.hangar_owner ? "Hangar owner" : "Customer"}
      </ModalHeader>

      <div className="modal-content">
        <ModalBody>
          <div className="row">
            <div className="col-lg-12">
              {/* Use col-lg-12 to make the input full width on large screens */}
              <div className="mb-">
                <label
                  htmlFor="titleinput"
                  className="font-weight-bold mb-0 form-label fs-5"
                >
                  Title<span className="text-info">*</span>
                </label>
                <input
                  style={{
                    height: "48px",
                    borderRadius: "8px",
                  }}
                  // maxLength={50}
                  type="text"
                  className="form-control bg-light"
                  name={"title"}
                  id="titleinput"
                  placeholder="Enter article title"
                  onChange={e => handleChange(e)}
                />
                <FieldError text={errors?.title} />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="mb-">
                <label
                  htmlFor="titleinput"
                  className="font-weight-bold mb-0 form-label fs-5"
                >
                  Description<span className="text-info">*</span>
                </label>
                <input
                  style={{
                    height: "48px",
                    borderRadius: "8px",
                  }}
                  type="text"
                  className="form-control bg-light"
                  name={"description"}
                  id="titleinput"
                  placeholder="Enter description"
                  onChange={e => handleChange(e)}
                />
                <FieldError text={errors?.description} />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => handleSubmit()}
          >
            {loading ? <Spinner hT={"20px"} wT={"20px"} /> : "Add Category"}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

FeedbackModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FeedbackModel
