import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import { supabase } from "pages/Utility/supabase"
import { STATUS, TABLES } from "pages/Utility/constants"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"
import DefaultLoading from "common/loading"

const Requests = ({
  columns,
  hangars,
  modelPopData,
  closeModal,
  selection,
  setLoading,
  getPlanesData,
  selectedRowsUserIDs,
  currentItems, setCurrentItems,
  setAlert,
}) => {
  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <Card>
        <CardBody>
          {/* <ul className="nav nav-pills mb-5 gap-2">
            <li
              className="nav-item cursor-pointer"
              onClick={() => setTabIndex(STATUS.HANGAR_INREVIEW)}
            >
              <div
                className={`nav-link ${tabIndex === STATUS.HANGAR_INREVIEW ? "active" : "bg-light text-black"
                  } `}
              >
                In Review
              </div>
            </li>
            <li
              onClick={() => setTabIndex(STATUS.HANGAR_APPROVED)}
              className="nav-item cursor-pointer"
            >
              <div
                className={`nav-link ${tabIndex === STATUS.HANGAR_APPROVED ? "active" : "bg-light text-black"
                  } `}
              >
                Approved
              </div>
            </li>
            <li
              onClick={() => setTabIndex(STATUS.HANGAR_REJECTED)}
              className="nav-item cursor-pointer  "
            >
              <div
                className={`nav-link ${tabIndex === STATUS.HANGAR_REJECTED ? "active" : "bg-light text-black"
                  } `}
              >
                Rejected
              </div>
            </li>
            <li
              onClick={() => setTabIndex(STATUS.HANGAR_DELETED)}
              className="nav-item cursor-pointer"
            >
              <div
                className={`nav-link  ${tabIndex === STATUS.HANGAR_DELETED ? "active" : "bg-light text-black"
                  } `}
              >
                Deleted
              </div>
            </li>
          </ul> */}
          <div className="mb-4 h4 card-title">Plane Requests</div>
          {hangars.length ? (
            <TableContainer
              columns={columns}
              data={hangars}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
              selection={selection}
              setLoading={setLoading}
              getHangarsData={getPlanesData}
              selectedRowsUserIDs={selectedRowsUserIDs}
              setAlert={setAlert}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
              sx={{
                p: 5,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "red",
              }}
            >
              No Hangars Requests Found.
            </Box>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)
