import React from "react"
import { Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import {
  OrderId,
  Name,
  TailNumber,
  Date,
  Status,
  Insurance,
  Action,
} from "./LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { DATE_FORMATS, STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import moment from "moment"

const PlaneInsurance = () => {
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowsUserIDs, setSelectedRowsUserIDs] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Plane Requests | HangarDirect"

  const handleCheckChange = adiProp => e => {
    if (e.target.checked) {
      setSelectedRows(s => [...s, { id: adiProp.id, userId: adiProp.user_id }])
      setSelectedRowsUserIDs(s => [...s, adiProp.user_id])
    } else {
      setSelectedRows(s => s.filter(row => row.id !== adiProp.id))
      setSelectedRowsUserIDs(s => s.filter(row => row !== adiProp.user_id))
    }
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     let ids = {
      //       id: cellProps.row.original.id,
      //       user_id: cellProps.row.original.user_id,
      //     }
      //     return (
      //       <input
      //         onChange={handleCheckChange(ids)}
      //         type="checkbox"
      //         className="form-check-input"
      //       />
      //     )
      //   },
      // },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "Name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "TailNumber",
        accessor: "TailNumber",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TailNumber {...cellProps} />
        },
      },

      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      // {
      //   Header: "Created date",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <Date {...cellProps} />
      //   },
      // },
      {
        Header: "Created Date",
        filterable: true,
        disableFilters: true,
        accessor: "created_at",

        Cell: cellProps => {
          return moment(cellProps.row.original.created_at).format(DATE_FORMATS.CREATED_AT)
        },
      },
      {
        Header: "Insurance",
        disableFilters: true,
        accessor: row => {
          return <Insurance row={row} refresh={getPlanesData}
            alert={alert}
            setAlert={setAlert}
          />
        },
      },

      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        Cell: cellProps =>
          <Action
            {...cellProps}
            refresh={getPlanesData}
            alert={alert}
            setAlert={setAlert}
            setLoading={setLoading}
          />
      }
    ],
    []
  )
  const getPlanesData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.USER_PLANES)
        .select("*")
        .eq("is_approved", STATUS.PLANE_INSURANCE_PENDING)
        .neq('insurance', 'null')
        .neq('insurance', '')
        .order("id", { ascending: true })
      if (error) {
        throw error
      }
      setHangars(data);
      setCurrentItems(data);
      setLoading(false)
    } catch (error) {
      console.log("error", error.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getPlanesData()
  }, [])


  return (
    <div className="page-content">
      <Container fluid>
        {alert.type && (
          <Alerts
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            clear={() => setAlert(DEFAULT_ALERT_VALUES)}
          />
        )}
        {loading ? <DefaultLoading /> :
          <Row>
            <Col lg="12">
              <Requests
                columns={columns}
                hangars={hangars}
                modelPopData={modelPopData}
                closeModal={() => setModelPopData(null)}
                selection={selectedRows}
                setLoading={setLoading}
                getPlanesData={getPlanesData}
                selectedRowsUserIDs={selectedRowsUserIDs}
                setAlert={setAlert}
                className="!mb-0"
                currentItems={currentItems}
                setCurrentItems={setCurrentItems}
              />
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

export default PlaneInsurance
