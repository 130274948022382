import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import {
  OrderId,
  Title,
  Date,
  UserId,
  StripeId,
  Status,
  Amount,
  Action,
} from "./LatestPayoutsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import ReferralsPayouts from "./ReferralsPayouts"
import { toast } from "react-toastify"

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
const stripe = require("stripe")(KEY)

const HangarPayouts = () => {
  const [loading, setLoading] = useState(false)
  const [payoutsData, setPayoutsData] = useState([])
  const [modelPopData, setModelPopData] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);

  document.title = "Referrals Payouts | HangarDirect"
  const getAccountData = async row => {

    setLoading(true)
    const { stripe_acount, user_id } = row
    const { data, error } = await supabase
      .from(TABLES.USER_PROFILE)
      .select("connect_account_approval")
      .eq("id", user_id)
      .single()
    const { connect_account_approval } = data
    const account = await stripe.accounts.retrieve(stripe_acount)
    account.userID = user_id
    account.connectAccountApproval = connect_account_approval

    setModelPopData(account)
    setLoading(false)
  }
  const handleStripeAccountApproval = async id => {
    setLoading(true)
    setModelPopData(null)
    try {
      const { data: updated, err } = await supabase
        .from(TABLES.USER_PROFILE)
        .update({ connect_account_approval: true })
        .eq("id", id)
      if (err) throw err
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message)
      toast.error(error.message)
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      // {
      //   Header: "Title",
      //   accessor: "Title",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: cellProps => {
      //     return <Title {...cellProps} />
      //   },
      // },

      {
        Header: "User Id",
        accessor: "User Id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <UserId {...cellProps} />
        },
      },
      {
        Header: "Stripe account",
        accessor: "Stripe Acc",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StripeId {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },
      {
        Header: "Created date",
        accessor: "Date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Action
              {...cellProps}
              showPopUp={getAccountData}
              refresh={getReferralPayoutsData}
              setLoad={setLoading}
            />
          )
        },
      },
      {
        Header: "View account",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                getAccountData(cellProps.row.original)
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const getReferralPayoutsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.REFERRAL_PAYOUTS)
        .select("*")
        .order("id", { ascending: true })
        .eq("status", STATUS?.REFERRAL_PAYOUT_PENDING)
      if (error) {
        throw error
      }
      // console.log('dataJoin: ', data);
      setPayoutsData(data);
      setCurrentItems(data);
    } catch (error) { }
    setLoading(false)
  }
  useEffect(() => {
    getReferralPayoutsData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumb title="Hangar-Direct" breadcrumbItem="Requests" /> */}
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <ReferralsPayouts
                  columns={columns}
                  payoutsData={payoutsData}
                  modelPopData={modelPopData}
                  approvalHandler={handleStripeAccountApproval}
                  closeModal={() => setModelPopData(null)}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default HangarPayouts

