import { Col, Container, Row } from "reactstrap"
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import moment from "moment"
import { STATUS, TABLES, } from "pages/Utility/constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import React from "react"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"
import { useState, useEffect } from "react"
import Alerts from "common/Alerts/Alert"
import { useSelector } from "react-redux"
import { cancelPaymentIntentMethod, refundPayment } from "pages/Utility/stripe_functions"
import Detail from "components/Invoice/Detail"
import ClientBillingDetail from "components/Invoice/ClientBillingDetail"
import RequestModelInvoice from "./requestModel"
import { toast } from "react-toastify"
import { getImageUrl } from "pages/Utility/functions"
const stripe = require("stripe")(process.env.REACT_APP_STRIPE_SECRET_KEY);

const CancelDetail = props => {
    document.title = "Reviews details - hangarDirect"
    const router = useRouteMatch()
    const [loading, setLoading] = useState(true)
    const [approval, setApproval] = useState(true)
    const [status, setStatus] = useState([])
    const [refundModel, setRefundModel] = useState(false)
    const [reasonTxt, setReasonTxt] = useState('')
    const DEFAULT_ALERT_VALUES = {
        heading: "",
        message: "",
        type: "",
    }
    const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
    const [cancelData, setCancelData] = useState(null)
    const [transactionData, setTransactionData] = useState(null)

    const {
        match: { params },
    } = props

    //   //meta title
    document.title = "Invoice Details - HangarDirect"
    const history = useHistory()

    const allStatus = useSelector(s => s.Hangar.status)

    useEffect(() => {
        if (params && params.id) {
            getOrderData(params.id)
        }
        fetchStatuses()
    }, [history])

    //Print the Invoice
    const getOrderData = async id => {
        setLoading(true)
        try {
            let { data = [], error } = await supabase
                .from(TABLES.TRANSACTIONS_CANCEL)
                .select("*,transaction_id(*, user_id(*),hangar_owner(*))")
                .eq("id", id)
                .single()

            if (error) {
                throw error
            } else if (data) {
                setTransactionData(data.transaction_id);
                setCancelData(data)
            }
        } catch (error) {
            console.log("error: ", error.message)
        }
        setLoading(false)
    }

    const fetchStatuses = async () => {
        setLoading(true)
        try {
            let { data, error } = await supabase.from(TABLES.STATUS).select("*")

            if (error) {
                throw error
            } else if (data) setStatus(data)
        } catch (error) {
            console.log("error: ", error.message)
        }
        setLoading(false)
    }

    const Heading = ({ text, customClass }) => {
        return (
            <nav aria-label="breadcrumb">
                <ol className={`breadcrumb   ${customClass}`}>
                    <li
                        style={{ fontWeight: "600", color: "#495057" }}
                        className="breadcrumb-item  active"
                        aria-current="page"
                    >
                        {text} #{transactionData?.id}
                    </li>
                </ol>
            </nav>
        )
    }
    const emailSender = async (stpFee) => {

        const diff = moment().diff(moment(transactionData?.created_at), "days")
        let refundAmount = 0

        if (diff <= 1) {
            refundAmount = (+transactionData.total_Amount - +stpFee)
        } else {
            refundAmount = (+total_Amount - +serviceFee - +stpFee)
        }

        let objToSend = {
            name: transactionData?.user_id?.meta?.first_name + ' ' + transactionData?.user_id?.meta?.last_name,
            address: transactionData?.hangarData?.address,
            airport: `${cancelData?.transaction_id?.hangarData?.near_airport?.ICAO} - ${cancelData?.transaction_id?.hangarData?.near_airport?.name}`,
            reservationDates: `${moment(transactionData?.checkIn).format('ll')} - ${moment(transactionData?.checkOut).format('ll')}`,
            tailNumber: transactionData?.tailNumber,
            reservationID: transactionData?.id,
            hangarImage: getImageUrl(transactionData?.hangarData?.images[0]),
            title: transactionData?.hangarData?.title,
            days: transactionData?.days,
            reservationTotal: transactionData?.rentalType === 'monthly' ? transactionData?.hangarData?.price_per_month : transactionData?.hangarData?.price_per_night * +transactionData?.days,
            totalAmount: +transactionData?.total_Amount,
            premiumAmenitiesAmount: +transactionData?.premium_amenities_amount,
            serviceFee: +transactionData?.serviceFee,
            tax: +transactionData?.tax,
            stripeFee: stpFee,
            grand_total: parseFloat(refundAmount),
            diff: diff > 1,
            credits: (+transactionData?.credits || 0)
        }
        let url = (window.location.origin.includes('web-app-admin') || window.location.origin.includes('localhost')) ? 'https://hangardirect-github-io.vercel.app/' : 'https://www.hangardirect.com/'
        fetch(`${url}api/send-email-react`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: transactionData?.email,
                html: "html",
                subject: `Request Cancelled: Reservation #${transactionData?.id}`,
                data: JSON.stringify(objToSend)
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                history.goBack()
            })
            .catch((e) => {
                // console.log('e: ', e);
            });

    };

    const cancelSubscription = async (subscription_id) => {
        try {
            const cancel = await stripe.subscriptions.cancel(
                subscription_id
            );
        } catch (error) {
            console.log('error: ', error);

        }

    }



    const HANGAR_PRICE =
        +transactionData?.total_Amount -
        +transactionData?.serviceFee -
        +transactionData?.tax

    const refundHandler = async e => {

        try {

            e.preventDefault()

            setLoading(true)
            if (transactionData?.orderStatus === String(STATUS.ORDER_REFUNDED))
                return toast.error("Already Refunded")
            transactionData.stripeFee = ((transactionData.total_Amount / 100) * 2.9 + 0.30)

            const capture_payment_response = await cancelPaymentIntentMethod(transactionData.payment_intent)
            setRefundModel(false)
            if (capture_payment_response?.id || capture_payment_response?.already_cancelled) {
                //  capture amount cancel
                // the user cancel the order
                emailSender(0)
            }
            else {
                // case: user accept the payment and then request to cancel the payment
                const response = await refundPayment({ invoiceDetail: transactionData, reasonTxt })
                if (response?.message && !response?.success) {
                    throw response
                }
                emailSender(transactionData.stripeFee)
            }
            getOrderData(params.id)
            if (transactionData?.rentalType === 'monthly' && transactionData?.subscriptionId)
                cancelSubscription(transactionData?.subscriptionId)
            await supabase.from(TABLES.TRANSACTIONS_CANCEL).update({ status: STATUS.CANCEL_ORDER_APPROVED, })
                .eq("id", cancelData.id)
            setLoading(false)

            // console.log("response: ", response)

        } catch (error) {
            setLoading(false)
            toast.error(error.message)
        }
    }

    return (

        <div className="page-content">
            <Container fluid>
                {loading ? (
                    <DefaultLoading />
                ) : (
                    <>
                        <RequestModelInvoice isOpen={refundModel} handleRefund={refundHandler} toggle={() => setRefundModel(!refundModel)} setReasonTxt={setReasonTxt} reasonTxt={reasonTxt} />
                        {!approval && (
                            <Alerts
                                type={alert.type}
                                heading={alert.heading}
                                message={alert.message}
                                clear={() => setAlert(DEFAULT_ALERT_VALUES)}
                            />
                        )}
                        <div className="d-flex justify-content-between ">
                            <Heading
                                text={`RESERVATION CANCEL REQUEST`}
                                customClass="px-0"
                            />
                            <div className="">
                                {/* <Link to="#">
                                        <button
                                            onClick={printInvoice}
                                            style={{ backgroundColor: "#556EE6", color: "white" }}
                                            className="btn"
                                        >
                                            Print preview
                                        </button>
                                    </Link> */}

                                <button
                                    onClick={() => history.goBack()}
                                    style={{
                                        backgroundColor: "#74788D",
                                        color: "white",
                                        marginLeft: "8px",
                                    }}
                                    className="btn"
                                >
                                    Go back
                                </button>
                            </div>
                        </div>

                        <Row>
                            <Col xl="9">
                                <div className="bg-white px-2 rounded">
                                    <div className="container pt-3">
                                        <Heading text="Order Info" customClass="m-0 px-0 pt-0" />
                                        <div className="d-flex flex-column justify-content-between mr-3 py-2">
                                            <table
                                                style={{ backgroundColor: "white !important" }}
                                                className="table-white table "
                                            >
                                                <thead className="">
                                                    <tr className="">
                                                        <th
                                                            scope="col"
                                                            style={{ color: "#737373", fontWeight: "500" }}
                                                        >
                                                            #
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                color: "#737373",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            Hangar name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{ color: "#737373", fontWeight: "500" }}
                                                        >
                                                            Price
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                color: "#737373",
                                                                fontWeight: "500",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            Reserved for
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{ color: "#737373", fontWeight: "500" }}
                                                        >
                                                            Total
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            style={{
                                                                color: "#737373",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            Check-in
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "#737373",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            Check-out
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="">
                                                        <td>{transactionData?.hangarData?.id}</td>
                                                        <td
                                                            style={{
                                                                color: "black",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: "20px",
                                                                    borderRadius: "4px",
                                                                    marginRight: "2px",
                                                                }}
                                                                src={
                                                                    getImageUrl(transactionData?.hangarData?.images[0])
                                                                }
                                                                alt=""
                                                            />
                                                            {transactionData?.hangarData?.title}
                                                        </td>
                                                        <td style={{ color: "black", fontWeight: "500" }}>
                                                            ${transactionData?.hangarData?.price_per_night}
                                                        </td>
                                                        <td style={{ color: "black", fontWeight: "500" }}>
                                                            x{transactionData?.days} days
                                                        </td>
                                                        <td style={{ color: "black", fontWeight: "500" }}>
                                                            ${transactionData?.rentalType === 'daily' ? (+transactionData?.hangarData?.price_per_night * +transactionData?.days) : transactionData?.hangarData?.price_per_month}
                                                        </td>
                                                        <td style={{ color: "black", fontWeight: "500" }}>
                                                            {moment(transactionData?.checkIn).format(
                                                                "DD MMM YYYY"
                                                            )}
                                                        </td>
                                                        <td style={{ color: "black", fontWeight: "500" }}>
                                                            {moment(transactionData?.checkOut).format(
                                                                "DD MMM YYYY"
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <Detail invoiceDetail={transactionData} status={status} />
                            </Col>
                            {/* ------------------------------------------------------ */}
                            <Col xl="3" className="p-0">
                                <ClientBillingDetail
                                    setRefundModel={setRefundModel}
                                    loading={loading}
                                    refundHandler={refundHandler}
                                    invoiceDetail={transactionData}
                                    cancelData={cancelData}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </div>

    )
}
export default CancelDetail
