import React from "react"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"

function CustomerDetail({ profileData }) {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center ">
        {" "}
        <div className="">
          <img
            style={{
              height: "100px",
              width: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={getImageUrl(profileData?.meta?.img_url)}
            className="!object-cover min-h-full"
            alt="Profile Picture"
          />
        </div>
        <div className="flex items-center my-2">
          <div className="d-flex gap-1">
            {/* {nameEdit ? ( */}
            <div style={{ fontWeight: "500" }}>
              {profileData?.meta?.first_name + " " + profileData?.meta?.last_name}
            </div>
            <div>
              <img style={{ height: "15px" }} src="/images/tick-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="border-bottom my-2 mx-3"></div>
      <div className="mb-3 mx-3 select-none">
        <h6 style={{ fontWeight: "600" }} className=" mb-2">
          About
        </h6>
        {profileData && (
          <>
            <div className="mb-3">{profileData?.about}</div>
            <div className="d-flex mb-3  justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <span>
                  <img
                    src="/images/profile.png"
                    style={{ height: "12px" }}
                    alt=""
                  />
                </span>
                <span style={{ fontWeight: "600", paddingTop: "2px" }}>
                  Joined at
                </span>
              </div>
              <div className="ml-1 text-sm text-[#737373]">
                {moment(profileData?.created_at).format("MMM YYYY")}
              </div>
            </div>
            {profileData.location && (
              <div className="d-flex mb-3 align-items-center justify-content-between">
                <div className="d-flex gap-2 align-items-center ">
                  <span>
                    <img
                      src="/images/loca.png"
                      style={{ height: "12px" }}
                      alt=""
                    />
                  </span>
                  <span style={{ fontWeight: "600", paddingTop: "2px" }}>
                    From
                  </span>
                </div>
                <div className="ml-1 text-[#737373] text-sm max-w-[160px] truncate ">
                  <abbr
                    className="decoration-transparent cursor-pointer"
                    title={profileData.location}
                  >
                    {profileData.location}
                  </abbr>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default CustomerDetail

